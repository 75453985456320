import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/common/Loading';

import { Modal } from '@material-ui/core';
import LabelsTable from './components/LabelsTable';
import LabelForm from './components/LabelForm';
import ActionBar, { defaultFilters } from './components/ActionBar';
import SessionContext from '../../../contexts/SessionContext';

const Labels = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [labelOnPlay, setLabelOnPlay] = useState(null);
  let { hasRole } = useContext(SessionContext);

  const onFilterChange = (newFilters) => {
    setFilters({
      label_name: newFilters.label_name,
    });
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showLabelForm = (label) => {
    if (hasRole(['LABELS_WRITE'])) {
      if (label) setLabelOnPlay(JSON.parse(JSON.stringify(label)));
      // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
      else setLabelOnPlay(null);
      setFormVisible(true);
    } else return true;
  };

  const onSaveLabel = () => {
    setFormVisible(false);
    loadData(1);
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadLabels(page, filters)
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <>
      <div id="register-labels-page">
        <ORBNavBar page_title="Cadastro > Selos" />

        <ActionBar
          hasRole={hasRole}
          setFormVisible={setFormVisible}
          total_items={data?.total_items}
          onFilterChange={onFilterChange}
        />
        {!data ? (
          loading ? (
            <Loading
              className="labels-loading-container"
              text="Buscando Selos..."
            />
          ) : (
            <LabelsTable labels={[]} />
          )
        ) : loading ? (
          <Loading
            className="labels-loading-container"
            text="Buscando Selos..."
          />
        ) : (
          <LabelsTable
            hasRole={hasRole}
            labels={data.labels}
            total_items={data.total_items}
            page={data.page}
            pages={data.pages}
            current_page={data.current_page}
            onPageChange={onPageChangeHandler}
            showLabelForm={showLabelForm}
          />
        )}
      </div>
      <Modal
        size="lg"
        open={formVisible}
        onClose={() => {
          setFormVisible(!formVisible);
          setLabelOnPlay(null);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div className="modal-container">
          <LabelForm edit_labels={labelOnPlay} onSaveLabel={onSaveLabel} />
        </div>
      </Modal>
    </>
  );
};

export default Labels;
