import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
  },
}));

export default function PaginationItem({ changePage, data }) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (data) {
      setPage(data.pages);
    }
  },[data]);
  const classes = useStyles();

  return <Pagination count={page} shape="rounded" className={classes.root} onChange={(event, val) => changePage(val)} />;
}
