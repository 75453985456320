import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid } from '@material-ui/core';

export default function DialogWindow(props) {
  return (
    <div style={{ maxWidth: '550px', margin: '20px auto' }}>
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '1rem',
          maxWidth: '400px',
          margin: '0 auto',
        }}
        variant="subtitle1"
        gutterBottom
        component="div"
      >
        Alguns titulares nao foram cadastrados, gostaria de cadastra-los ?
      </Typography>

      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <Button
          style={{ marginRight: '15px' }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            props.setDialogVisible(false);
            props.setVisible(true);
          }}
        >
          {' '}
          Sim{' '}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={() => {
            props.setDialogVisible(false);
          }}
        >
          {' '}
          Cancelar{' '}
        </Button>
      </div>
    </div>
  );
}
