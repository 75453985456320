import React, { useState, useContext } from 'react';
import FilterIcon from '../../../icons/FilterIcon';
import PlusIcon from '../../../icons/PlusIcon';
import DebouncedTextField from '../../../components/common/DebouncedTextField';
import DateMonthPicker from '../../../components/common/DateMonthPicker';
import StatusDropdown from './StatusDropdown';
import SessionContext, { SystemRoles } from '../../../contexts/SessionContext';

export const defaultFilters = {
  reference_date: '',
  name: '',
  status: '',
};

const ActionBar = (props) => {
  const [showFilters, setShowFilters] = useState(false); //shows/hide filter options
  const [filters, setFilters] = useState(defaultFilters);
  const [referenceDate, setReferenceDate] = useState(null);
  let { hasRole } = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onNameChangeHandler = (name) => {
    updateFilters({ ...filters, name: name });
  };

  const onDateChangeHandler = (date) => {
    updateFilters({ ...filters, reference_date: date });
  };

  const onStatusChangeHandler = (status) => {
    updateFilters({ ...filters, status: status });
  };

  const showHideFilters = (show) => {
    // Set the state of showFilters
    setShowFilters(show);

    // If show is true, do not call onChange
    if (show) {
      return;
    }

    // If show is false, check if any filter is true
    if (filters.reference_date || filters.name || filters.status) {
      // At least one filter is true, reset filters to null
      updateFilters({
        reference_date: null,
        name: null,
        status: null,
      });
    }
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {hasRole(['LABELS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              props.setFormVisible(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}

        <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
          <h5>Total de distribuições: {props.total_items}</h5>
        </div>
      </div>

      {showFilters ? (
        <div className="page-filter-bar">
          {/* {hasRole(['']) ? ( */}
          <DebouncedTextField
            label={'Nome'}
            onChange={(name) => {
              onNameChangeHandler(name);
            }}
          />
          <DateMonthPicker
            value={referenceDate}
            useToFilter={true}
            label="Data de referência"
            format="MM/yyyy"
            view={['month', 'year']}
            onChange={(rawDate, date) => {
              setReferenceDate(rawDate);
              onDateChangeHandler(date);
            }}
          />
          <StatusDropdown
            onChange={(status) => {
              onStatusChangeHandler(status);
            }}
          />
          {/* ) : null} */}
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
