import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import ORBNavBar from '../../../components/common/ORBNavBar';
import PlusIcon from '../../../icons/PlusIcon';
import ORBApi from '../../../services/ORBApi';
import PaymentFilesTable from './components/PaymentFilesTable';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import {
  RateEditPanel,
  DeletionConfirmPanel,
  NotFoundTracksPanel,
  MatchParcialTracksPanel,
  UpdateStatusConfirmPanel,
} from './components/Panels';
import '../styles.css';
import ActionBar, { defaultFilters } from '../components/ActionBar';
import SessionContext from '../../../contexts/SessionContext';
import Loading from '../../../components/common/Loading';

export default function Companies() {
  let { hasRole } = useContext(SessionContext);
  const [tableData, setTableData] = useState({ current_page: 1 }); // Files to be shown on table
  const [reportToDelete, setReportToDelete] = useState(null); // Report selected to be deleted on the table
  const [showDeletionConfirm, setShowDeletionConfirm] = useState(false); // show the confirm box to delete a file
  const [reportToEdit, setReportToEdit] = useState(null); // Report to edit the rate configuration
  const [rateFormVisible, setRateFormVisible] = useState(false); // Visibility of rate configuration form
  const [statusVisible, setStatusVisible] = useState(false);
  const [notFoundTracksVisible, setNotFoundTracksVisible] = useState(false); // Visibility of the Not found tracks panel
  const [notFoundTracksReport, setNotFoundTracksReport] = useState(null); // Report to show not found tracks data
  const [matchParcialTracksVisible, setMatchParcialTracksVisible] = useState(false); // Visibility of the parcial match tracks panel
  const [matchParcialTracksReport, setMatchParcialTracksReport] = useState(null); // Report to show parcial match tracks data
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(0);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onFileFormSaveHandler = (success) => {
    if (success) {
      toast.success('Arquivo enviado com sucesso.');
      loadPaymentReports(tableData.current_page);
    } else {
      toast.error('Falha ao enviar arquivo.');
    }
  };

  const onPageChangeHandler = (page) => {
    if (page !== tableData.current_page) loadPaymentReports(page);
  };

  const onRateClickHandler = (report) => {
    setReportToEdit(report.id);
    setRateFormVisible(true);
  };

  const loadPaymentReports = (page) => {
    setLoading(1);
    ORBApi.loadPaymentReports(page, filters)
      .then((data) => {
        setTableData(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(0);
      });
  };

  const onDeleteClickHandler = (report) => {
    setReportToDelete(report);
    setShowDeletionConfirm(true);
  };

  const onUpdateStatusHandler = (report) => {
    setReportToEdit(report.id);
    setStatusVisible(true);
  };

  const onDeletionConfirmHandler = (success) => {
    if (success) {
      toast.success('Arquivo removido com sucesso.');
      loadPaymentReports(tableData.current_page);
    } else {
      toast.error('Falha ao tentar remover arquivo.');
    }
    setShowDeletionConfirm(false);
  };

  const onNotFoundTracksAlertClickHandler = (report) => {
    setNotFoundTracksReport(report);
    setNotFoundTracksVisible(true);
  };

  const onMatchParcialTracksAlertClickHandler = (report) => {
    setMatchParcialTracksReport(report);
    setMatchParcialTracksVisible(true);
  };


  const onDownloadHandler = (success) => {
    if (success) {
      toast.success('Arquivo baixado com sucesso.');
    } else {
      toast.error('Falha ao baixar arquivo.');
    }
    if(notFoundTracksReport){
      setNotFoundTracksVisible(false);
    }else if(matchParcialTracksReport){
      setMatchParcialTracksVisible(false)
    }
  };

  useEffect(() => {
    loadPaymentReports(1);
  }, [filters]);

  return (
    <div id="files-companies-page">
      <ORBNavBar page_title="Arquivos > Conexo" selectedOption="drop_files" />
      {/* Action Bar */}
      <ActionBar
        hasRole={hasRole}
        onFilterChange={onFilterChangeHandler}
        onSave={onFileFormSaveHandler}
        isCompanies={true}
      />

      {loading ? (
        <Loading text="Carregando Arquivos" size="30px" />
      ) : (
        <PaymentFilesTable
          hasRole={hasRole}
          tableData={tableData}
          onPageChange={onPageChangeHandler}
          onRateClick={onRateClickHandler}
          onDeleteClick={onDeleteClickHandler}
          onNotFoundTracksAlertClick={onNotFoundTracksAlertClickHandler}
          onMatchParcialTracksAlertClick={onMatchParcialTracksAlertClickHandler}
          onUpdateStatusClick={onUpdateStatusHandler}
          loadData={loadPaymentReports}
          setLoading={setLoading}
        />
      )}

      {/* Modal dialog for confirm report deletion */}
      <Modal
        open={showDeletionConfirm}
        onClose={() => setShowDeletionConfirm(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container delete">
          <DeletionConfirmPanel
            report={reportToDelete}
            onConfirm={onDeletionConfirmHandler}
          />
        </div>
      </Modal>
      {/* Modal dialog for changing exchange rate */}
      <Modal
        open={rateFormVisible}
        onClose={() => setRateFormVisible(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container rating">
          <RateEditPanel
            report={reportToEdit}
            onSave={() => {
              setRateFormVisible(false);
              loadPaymentReports(tableData.current_page);
            }}
          />
        </div>
      </Modal>

      {/* Not found tracks */}
      <Modal
        open={notFoundTracksVisible}
        onClose={() => {
          setNotFoundTracksVisible(false);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container">
          <NotFoundTracksPanel
            report={notFoundTracksReport}
            onDownload={onDownloadHandler}
          />
        </div>
      </Modal>

        {/* Match Parcial tracks */}
            <Modal
        open={matchParcialTracksVisible}
        onClose={() => {
          setMatchParcialTracksVisible(false);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container">
          <MatchParcialTracksPanel
            report={matchParcialTracksReport}
            onDownload={onDownloadHandler}
          />
        </div>
      </Modal>

      {/* Modal dialog for changing status */}
      <Modal
        open={statusVisible}
        onClose={() => setStatusVisible(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container rating">
          <UpdateStatusConfirmPanel
            report_id={reportToEdit}
            loadData={loadPaymentReports}
            current_page={tableData.current_page}
            setStatusVisible={setStatusVisible}
          />
        </div>
      </Modal>
    </div>
  );
}
