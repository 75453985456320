import React from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  Grid,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect } from 'react';

const TableForm = (props) => {
  return (
    <>
      {props.contributions ? (
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small" className="basic-table table-scroll">
              <TableHead className="basic-table-head">
                <TableRow>
                  {props.split_headers.map((title, key) => {
                    return <TableCell key={key}>{title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.contributions
                  ? props.contributions.map((contrib, index) => {
                      return (
                        <TableRow classname="row" key={index}>
                          <TableCell>
                            {contrib.contributor.data.name &&
                            contrib.contributor.data.artist_name
                              ? `${contrib.contributor.data.name} (${contrib.contributor.data.artist_name})`
                              : contrib.contributor.data.name}
                          </TableCell>

                          <TableCell>{contrib.contributor.share}</TableCell>

                          <TableCell>{contrib.contributor.orb_fee}</TableCell>
                          {contrib.publisher !== undefined ? (
                            <>
                              <TableCell>
                                {contrib.publisher.data.name}
                              </TableCell>

                              <TableCell>{contrib.publisher.share}</TableCell>

                              <TableCell>{contrib.publisher.orb_fee}</TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{''}</TableCell>

                              <TableCell>{''}</TableCell>

                              <TableCell>{''}</TableCell>
                            </>
                          )}
                          <TableCell>
                            {contrib.is_orb_managed ? (
                              <CheckIcon color="success" />
                            ) : null}
                          </TableCell>
                          <TableCell
                            className="clickable"
                            onClick={() => {
                              props.removeSplitItem(index);
                            }}
                          >
                            <DeleteIcon />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
    </>
  );
};

export default TableForm;
