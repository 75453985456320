import React, { useEffect, useState, useContext } from 'react';
import '../styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';

import PaymentFilesTable from './components/PaymentFilesTable';
import ORBApi from '../../../services/ORBApi';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import {
  AddFilePanel,
  NotFoundWorksPanel,
  DeletionConfirmPanel,
  UpdateStatusConfirmPanel,
} from './components/Panels';
import { toast } from 'react-toastify';
import ActionBar, { defaultFilters } from '../components/ActionBar';
import RateEditPanel from './components/RateEditPanel';
import SessionContext from '../../../contexts/SessionContext';
import Loading from '../../../components/common/Loading';

export default function Backoffice() {
  let { hasRole } = useContext(SessionContext);
  const [showAddFileForm, setShowAddFileForm] = useState(false);
  const [showNotFoundPanel, setShowNotFoundPanel] = useState(false);
  const [tableData, setTableData] = useState({
    current_page: 1,
    work_payment_files: [],
  });
  const [reportToDelete, setReportToDelete] = useState(null); // Report selected to be deleted on the table
  const [showDeletionConfirm, setShowDeletionConfirm] = useState(false); // show the confirm box to delete a file
  const [reportToEdit, setReportToEdit] = useState(null);
  const [rateFormVisible, setRateFormVisible] = useState(false); // Visibility of rate configuration form
  const [statusVisible, setStatusVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(0);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onRateClickHandler = (report) => {
    setReportToEdit(report.id);
    setRateFormVisible(true);
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadPaymentWorksReports(page, filters)
      .then((data) => {
        setTableData(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(0);
      });
  };

  const onPageChangeHandler = (page) => {
    if (page !== tableData.current_page) loadData(page);
  };

  const onSaveHandler = (success) => {
    if (success) {
      toast.success('Arquivo enviado com sucesso.');
      loadData(1);
      setShowAddFileForm(false);
    } else {
      toast.error('Falha ao tentar enviar arquivo.');
    }
  };

  const onDeleteClickHandler = (report) => {
    setReportToDelete(report);
    setShowDeletionConfirm(true);
  };

  const onUpdateStatusHandler = (report) => {
    setReportToEdit(report.id);
    setStatusVisible(true);
  };

  const onDeletionConfirmHandler = (success) => {
    if (success) {
      toast.success('Arquivo removido com sucesso.');
      loadData(tableData.current_page);
    } else {
      toast.error('Falha ao tentar remover arquivo.');
    }
    setShowDeletionConfirm(false);
  };
  const onWorksAlertClickHandler = (report) => {
    setSelectedReport(report);
    setShowNotFoundPanel(true);
  };

  const onDownloadHandler = (success) => {
    if (success) {
      toast.success('Arquivo baixado com sucesso.');
      setShowNotFoundPanel(false);
    } else {
      toast.error('Failha ao gerar o arquivo.');
    }
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  return (
    <div id="files-backoffice-page">
      <ORBNavBar page_title="Arquivos > Autoral" selectedOption="drop_files" />
      {/* Action Bar */}

      <ActionBar
        hasRole={hasRole}
        onFilterChange={onFilterChangeHandler}
        onSave={onSaveHandler}
        isBackoffice={true}
      />
      {/* Files table */}
      {loading ? (
        <Loading text="Carregando Arquivos" size="30px" />
      ) : (
        <PaymentFilesTable
          hasRole={hasRole}
          tableData={tableData}
          onPageChange={onPageChangeHandler}
          onWorksAlertClick={onWorksAlertClickHandler}
          onRateClick={onRateClickHandler}
          onDeleteClick={onDeleteClickHandler}
          onUpdateStatusClick={onUpdateStatusHandler}
          setLoading={setLoading}
          loadData={loadData}
        />
      )}

      {/* Modal dialog for showing not found works */}
      <Modal
        open={showNotFoundPanel}
        onClose={() => {
          setShowNotFoundPanel(false);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container">
          <NotFoundWorksPanel
            report={selectedReport}
            onDownload={onDownloadHandler}
          />
        </div>
      </Modal>
      {/* Modal dialog for confirm report deletion */}
      <Modal
        open={showDeletionConfirm}
        onClose={() => setShowDeletionConfirm(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container delete">
          <DeletionConfirmPanel
            report={reportToDelete}
            onConfirm={onDeletionConfirmHandler}
          />
        </div>
      </Modal>
      {/* Modal dialog for changing exchange rate */}
      <Modal
        open={rateFormVisible}
        onClose={() => setRateFormVisible(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container rating">
          <RateEditPanel
            report={reportToEdit}
            onSave={() => {
              setRateFormVisible(false);
              loadData(tableData.current_page);
            }}
          />
        </div>
      </Modal>
      {/* Modal dialog for changing status */}
      <Modal
        open={statusVisible}
        onClose={() => setStatusVisible(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container rating">
          <UpdateStatusConfirmPanel
            report_id={reportToEdit}
            loadData={loadData}
            current_page={tableData.current_page}
            setStatusVisible={setStatusVisible}
          />
        </div>
      </Modal>
    </div>
  );
}
