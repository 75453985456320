import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const StatusDropdown = ({ onChange }) => {
  const [selected, setSelected] = useState('');

  const onChangeHandler = (e) => {
    const selectedValue = e.target.value;
    setSelected(selectedValue);
    onChange(selectedValue);
  };

  return (
    <FormControl
      fullWidth
      className="status-dropdown"
      style={{ width: '100%' }}
      required
    >
      <InputLabel style={{ paddingLeft: '10px' }} shrink={selected !== ''}>
        Status
      </InputLabel>
      <Select
        value={selected}
        onChange={onChangeHandler}
        variant="outlined"
        notched={selected !== ''}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem style={{ padding: '15px' }} value=""></MenuItem>
        <MenuItem value="0">Pending</MenuItem>
        <MenuItem value="1">Distributing</MenuItem>
        <MenuItem value="2">WaitingApproval</MenuItem>
        <MenuItem value="3">Approved</MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
