import React, { useEffect } from "react";
import {useLocation, useHistory} from 'react-router-dom';
import SessionContext, {SystemRoles} from '../contexts/SessionContext';
import config from "../config";

//Handling user login (with success or with an error)
const Login = (props) => {

  let { search } = useLocation();
  let history = useHistory();
  let sessionContext = React.useContext(SessionContext);

  useEffect(() => {
    // try to get the token from query string
    let token = new URLSearchParams(search).get("auth_token");
    if (token) {
      window.localStorage.setItem("auth_token", token);
    } else {
      token = window.localStorage.getItem("auth_token");
    }
    // since has the token, mount the session
    if (token) {
      sessionContext.setupSession()
      .then((initialPage) => {
        if (initialPage) { 
          // check if the user was trying to access other paths on the system to send him/her back
          if (props.location.state && props.location.state.from)
            history.push(props.location.state.from);
          else
            history.push(initialPage);
        }
        else
          window.location.href = config.orb_auth_url;
      })
    } else {
        window.location.href = config.orb_auth_url;
    }
  }, [])

  return (<> </>);
};

export default Login;
