import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../../components/common/Loading';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import ORBApi from '../../../../services/ORBApi';
import Resizer from 'react-image-file-resizer';
import moment from 'moment';
import SessionContext from '../../../../contexts/SessionContext';
import SuggestContributors from '../../../../components/common/SuggestContributors';

const LabelForm = (props) => {
  const emptySplit = {
    name: '',
    contributor_id: '',
  };
  const [label, setLabel] = useState();
  let { hasRole } = useContext(SessionContext);
  const [aliase, setAliase] = useState(null);
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [currentArea, setCurrentArea] = useState('');
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [splitOnPlay, setSplitOnPlay] = useState({ ...emptySplit });

  const aliaseHandler = () => {
    if (aliase !== null) {
      let aliasesArray = label.aliases;
      aliasesArray.push(aliase);
      setLabel({ ...label, aliases: aliasesArray });
      setAliase(null);
    } else {
      return;
    }
  };

  const removeNullPaymentProperty = (label) => {
    if (label.hasOwnProperty('orb_percent_fee')) {
      if (label.orb_percent_fee === null) {
        delete label.orb_percent_fee;
        delete label.orb_percent_on_net_value;
      }
    }
    if (label.hasOwnProperty('orb_flat_fee')) {
      if (label.orb_flat_fee === null) {
        delete label.orb_flat_fee;
      }
    }
    if (label.hasOwnProperty('orb_track_fee')) {
      if (label.orb_track_fee === null) {
        delete label.orb_track_fee;
      }
    }
  };

  const saveLabel = () => {
    if (label.name === '') {
      toast.warn('Preencha o campo nome');
      return;
    }

    if (!label.contributor_id) {
      toast.warn('Preencha o campo títular');
      return;
    }

    if (currentArea === 'priceFixed') {
      if (!label.hasOwnProperty('orb_flat_fee') || isNaN(label.orb_flat_fee)) {
        toast.warn('Preencha o valor de cobrança');
        return;
      }
    } else if (currentArea === 'pricePerTitle') {
      if (
        !label.hasOwnProperty('orb_track_fee') ||
        isNaN(label.orb_track_fee)
      ) {
        toast.warn('Preencha o valor de cobrança');
        return;
      }
    } else if (currentArea === 'priceOnNet') {
      if (
        !label.hasOwnProperty('orb_percent_fee') ||
        isNaN(label.orb_percent_fee)
      ) {
        toast.warn('Preencha o valor de cobrança');
        return;
      }
    }
    removeNullPaymentProperty(label);

    if (props.edit_labels) {
      setIsSaving(true);
      ORBApi.patchLabel(label)
        .then(() => {
          toast.success('Edição Finalizada!');
          props.onSaveLabel();
        })
        .catch(() => {
          toast.error('Falha ao editar Selo.');
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      setIsSaving(true);
      ORBApi.postLabel(label)
        .then(() => {
          toast.success('Selo Salvo!');
          props.onSaveLabel();
        })
        .catch(() => {
          toast.error('Falha ao salvar selo.');
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const postLogo = async (e) => {
    var formData = new FormData();
    formData.append('logo', await onChangeFile(e));
    ORBApi.postLabelLogo(formData).then((response) => {
      setImageData(response);
      setLabel({ ...label, logo: response.id });
    });
  };

  const onChangeFile = async (event) => {
    try {
      const file = event.target.files[0];
      return file;
    } catch (err) {}
  };
  useEffect(() => {
    if (props.edit_labels) {
      setTitle('Editar Selo');
      ORBApi.loadLabelDetails(props.edit_labels.id).then((response) => {
        var areaType = '';
        if (response.orb_percent_fee !== undefined) {
          areaType = 'priceOnNet';
        } else if (response.orb_flat_fee !== undefined) {
          areaType = 'priceFixed';
        } else if (response.orb_track_fee !== undefined) {
          areaType = 'pricePerTitle';
        }
        setCurrentArea(areaType);

        if (response.logo_url !== null) {
          setImageData({ ...imageData, url: response.logo_url });
          delete response.logo_url;
        } else {
          delete response.logo_url;
        }
        setLabel(response);
        setSplitOnPlay({
          contributor_id: response.contributor_id,
          contributor_name: response.contributor_name,
        });
      });
    } else {
      setTitle('Criar Selo');
      setLabel({
        name: '',
        contributor_id: '',
        logo: '',
        aliases: [],
      });
    }
  }, []);

  return (
    <>
      {label ? (
        <>
          <h4>{title}</h4>
          <hr />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome"
                value={label ? label.name : null}
                variant="outlined"
                onChange={(e) => setLabel({ ...label, name: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SuggestContributors
                value={splitOnPlay.contributor_name}
                onChange={(value) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.contributor_id = value.id;
                  newSplitOnPlay.contributor_name = value.name;
                  setSplitOnPlay(newSplitOnPlay);
                  setLabel({ ...label, contributor_id: value.id });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Data"
                defaultValue={moment(label ? label.date : undefined).format(
                  'DD/MM/YYYY',
                )}
                variant="outlined"
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="aliase"
                onBlur={(e) => {
                  e.target.value = '';
                }}
                label="Apelido"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={(e) => setAliase(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={() => {
                  aliaseHandler();
                }}
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              {label.aliases?.length
                ? `Apelidos cadastrados: ${label.aliases} `
                : 'Nenhum apelido cadastrado por enquanto!'}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel align="center" component="legend">
                Tipo de cobrança
              </FormLabel>

              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                onChange={(e) => setCurrentArea(e.target.value)}
                value={label ? currentArea : undefined}
              >
                <FormControlLabel
                  value="priceOnNet"
                  control={<Radio color="secondary" />}
                  label="Percentual"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="pricePerTitle"
                  control={<Radio color="secondary" />}
                  label="Por faixa"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="priceFixed"
                  control={<Radio color="secondary" />}
                  label="Fixo"
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid>

            {currentArea === 'priceOnNet' ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      value={label ? label.orb_percent_fee : null}
                      type="number"
                      label="% ORB"
                      variant="outlined"
                      onChange={(e) =>
                        setLabel({
                          ...label,
                          orb_percent_fee: parseFloat(e.target.value),
                          orb_flat_fee: null,
                          orb_track_fee: null,
                          orb_percent_on_net_value: false,
                        })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      style={{ marginLeft: '1em' }}
                      control={
                        <Checkbox
                          checked={label?.orb_percent_on_net_value}
                          onChange={() => {
                            setLabel({
                              ...label,
                              orb_percent_on_net_value:
                                !label.orb_percent_on_net_value,
                            });
                          }}
                          name="checkedA"
                        />
                      }
                      label="sob o líquido"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {currentArea === 'pricePerTitle' ? (
              <Grid item xs={12}>
                <TextField
                  value={label ? label.orb_track_fee : null}
                  type="number"
                  label="Preço por faixa em R$"
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setLabel({
                      ...label,
                      orb_track_fee: parseFloat(e.target.value),
                      orb_percent_fee: null,
                      orb_flat_fee: null,
                    })
                  }
                  fullWidth
                />
              </Grid>
            ) : null}
            {currentArea === 'priceFixed' ? (
              <Grid item xs={12}>
                <TextField
                  value={label ? label.orb_flat_fee : null}
                  type="number"
                  label="Preço fixo em R$"
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setLabel({
                      ...label,
                      orb_flat_fee: parseFloat(e.target.value),
                      orb_percent_fee: null,
                      orb_track_fee: null,
                    })
                  }
                  fullWidth
                />
              </Grid>
            ) : null}

            {!imageData ? (
              hasRole(['LOGO_WRITE']) ? (
                <>
                  <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    <input
                      type="file"
                      id="real-image"
                      hidden="hidden"
                      onChange={(e) => postLogo(e)}
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<CloudUploadIcon />}
                      onClick={() =>
                        document.getElementById('real-image').click()
                      }
                    >
                      Logo
                    </Button>
                  </Grid>
                </>
              ) : null
            ) : (
              <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ height: '102px', width: '102px' }}
                    src={imageData.url}
                    alt="logo"
                  />
                  <br />
                  {hasRole(['LOGO_WRITE']) ? (
                    <>
                      <input
                        type="file"
                        id="real-image"
                        hidden="hidden"
                        onChange={(e) => postLogo(e)}
                      />
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<CloudUploadIcon />}
                        onClick={() =>
                          document.getElementById('real-image').click()
                        }
                      >
                        Alterar
                      </Button>
                    </>
                  ) : null}
                </div>
              </Grid>
            )}

            <Grid item xs={12} md={3}>
              {!isSaving ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={saveLabel}
                >
                  {props.edit_labels ? 'Editar' : 'Criar'}
                </Button>
              ) : (
                <Loading />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default LabelForm;
