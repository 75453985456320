import React, { useState, useContext, useEffect } from 'react';
import FilterIcon from '../../../icons/FilterIcon';
import DebouncedTextField from '../../../components/common/DebouncedTextField';
import ClientsDropdown from '../../../components/common/ClientsDropdown';
import SessionContext from '../../../contexts/SessionContext';
import SuggestContributors from '../../../components/common/SuggestContributors';

export const defaultFilters = {
  month: '',
  year: '',
  client_id: '',
  contributor_id: '',
  page: 1,
};

const ActionBar = (props) => {
  let { hasRole } = useContext(SessionContext);

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const updateClientFilters = (newFilters) => {
    if(hasRole(["REPORTS_ARTIST_VIEW", "REPORTS_AGENT_VIEW"])){
      props.contributorsFiltersChangeHandler(newFilters)
    }
    
    props.clientsFiltersChangeHandler(newFilters);
  };

  const onClientChangeHandler = (client) => {
    setFilters({ ...filters, client_id: client });
  };

  const onMonthChangeHandler = (month) => {
    setFilters({ ...filters, month: month });
  };
  const onYearChangeHandler = (year) => {
    setFilters({ ...filters, year: year });
  };
  const onContributorChangeHandler = (contributor) => {
    props.onContributorSearchChange(contributor.name);
    setFilters({ ...filters, contributor_id: contributor.id, contributor_name:contributor.name});
  };


  useEffect(() => {
    updateClientFilters(filters)
  }, [filters])

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
      </div>
      {showFilters ? (
        <>
          <div className="page-filter-bar">
            {hasRole(['REPORTS_FULL_VIEW']) ? (
              <ClientsDropdown
                onChange={(value) => {
                  onClientChangeHandler(value);
                }}
                label={props.input_filters.clients}
              />
            ) : null}

            {hasRole([
              'REPORTS_FULL_VIEW',
              'REPORTS_CLIENT_VIEW',
              'REPORTS_ARTIST_VIEW',
              'REPORTS_AGENT_VIEW'
            ]) ? (
              <>
                <DebouncedTextField
                  label={props.input_filters.month}
                  onChange={(value) => {
                    onMonthChangeHandler(value);
                  }}
                />
                <DebouncedTextField
                  label={props.input_filters.year}
                  onChange={(value) => {
                    onYearChangeHandler(value);
                  }}
                />
              </>
            ) : null}

            {hasRole([
              'REPORTS_FULL_VIEW',
              'REPORTS_CLIENT_VIEW',
              'REPORTS_AGENT_VIEW',
            ]) ? (
              <SuggestContributors
                value={props.contributor_name}
                useToFilter={true}
                onChange={(contributor) => {
                  onContributorChangeHandler(contributor);
                }}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ActionBar;
