import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Loading from '../../../../../components/common/Loading';
import ORBApi from '../../../../../services/ORBApi';
import { GraphOptions, GraphColors } from './GraphOptions';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function TracksMonthlyRevenueGraph({
  filters,
  currency,
  language,
}) {
  const [date, setDate] = useState([]);
  const [valueDate, setValueDate] = useState([]);

  const [fileProcessing, setFileProcessing] = useState(0);
  const [requestSource, setRequestSource] = useState(null);
  moment.locale(language);

  const doSearch = () => {
    if (fileProcessing) {
      // if there is a request processing, cancel it
      ORBApi.abortRequest(requestSource);
    }
    setFileProcessing(1);
    // Get the request abort source, to be used to cancel the request if needed
    var cancelRequestSource = ORBApi.getRequestAbortSource();
    setRequestSource(cancelRequestSource);
    ORBApi.loadTrackMonthlyRevenue(
      filters.startDate,
      filters.endDate,
      filters.dateType,
      filters.trackId,
      filters.contributorId,
      filters.label,
      cancelRequestSource,
    )
      .then((data) => {
        setDate(data.months.map((i) => i.date));
        setValueDate(data.months.map((i) => i.value));
        setFileProcessing(0);
      })
      .catch((error) => {
        if (!ORBApi.isCancel(error)) {
          // When cancelling the request, do not remove the "loading"
          setFileProcessing(0);
        }
      });
  };

  useEffect(() => {
    doSearch();
  }, [filters]);

  return (
    <>
      {fileProcessing ? (
        <Loading className="file-loading-container" />
      ) : (
        <Line
          data={{
            labels: date,
            datasets: [
              {
                data: valueDate,
                fill: false,
                borderColor: GraphColors[0],
                backgroundColor: GraphColors[4],
                tension: 0.1,
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return value.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: currency,
                      });
                    },
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return moment(value).format('MMM/YY');
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                // title: function () {
                //   return '';
                // },
                label: function (tooltipItem, data) {
                  return tooltipItem.yLabel.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: currency,
                  });
                },
              },
            },
          }}
        />
      )}
    </>
  );
}
