import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function Loading({ className, text, size }) {
  return (
    <Box>
      <CircularProgress color="inherit" size={size} />
      <h5 className={className}>{text}</h5>
    </Box>
  );
}
