import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PublishersDropdown from '../../../../components/common/PublishersDropdown';
import Loading from '../../../../components/common/Loading';
import TableForm from './TableForm';
import CpfCnpj from '../../../../components/common/CpfCnpj';
import DialogWindow from './DialogWindow';
import SuggestContributors from '../../../../components/common/SuggestContributors';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Button,
  Collapse,
  Modal,
  Backdrop,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ORBApi from '../../../../services/ORBApi';

const WorksForm = (props) => {
  const emptySplit = {
    contributor: {
      data: { id: '', name: '' },
      share: '',
      orb_fee: '',
    },
    publisher: {
      publisher_id: '',
      data: { id: '' },
      share: '',
      orb_fee: '',
    },
    is_orb_managed: 0,
  };

  const [work, setWork] = useState({
    title: '',
    iswc: '',
    orb_code: '',
    contributions: [],
  });

  const [title, setTitle] = useState('');
  const [showSplitForm, setShowSplitForm] = useState(false);
  const [splitOnPlay, setSplitOnPlay] = useState(emptySplit);
  const [isSaving, setIsSaving] = useState(false);
  const [checked, setChecked] = useState(false);

  const removeSplitItem = (itemIndex) => {
    let splits = work.contributions;
    splits.splice(itemIndex, 1);
    setWork({ ...work, contributions: splits });
  };

  const addSplitItem = () => {
    if (!splitOnPlay.contributor.data.id) {
      toast.warn(props.messages.required_holder);
      return;
    }
    if (!splitOnPlay.contributor.share) {
      toast.warn(props.messages.required_share);
      return;
    }

    if (splitOnPlay.contributor.orb_fee === '') {
      delete splitOnPlay.contributor.orb_fee;
    }
    if (splitOnPlay.publisher.orb_fee === '') {
      delete splitOnPlay.publisher.orb_fee;
    }

    if (splitOnPlay.publisher.data.id) {
      if (!splitOnPlay.publisher.share) {
        toast.warn(props.messages.required_share);
        return;
      }
    } else {
      delete splitOnPlay.publisher;
    }

    let splits = work.contributions;
    splits.push(Object.assign({}, splitOnPlay)); // Add a copy of the object
    setWork({ ...work, contributions: splits });
    setSplitOnPlay(emptySplit);
    setChecked(false);
  };

  const saveWork = () => {
    //The % total, must be 99.99% or 100%.
    var splitTotal = 0;
    for (var i in work.contributions) {
      if (work.contributions[i].publisher) {
        splitTotal +=
          work.contributions[i].publisher.share +
          work.contributions[i].contributor.share;
      } else {
        splitTotal += work.contributions[i].contributor.share;
      }
    }
    if (splitTotal < 99.99 || splitTotal > 100) {
      toast.warn(props.messages.verify_total_splits);
      return;
    }

    const isOrbManaged = work.contributions
      .map((i) => i.is_orb_managed)
      .some((i) => i === 1);

    if (!isOrbManaged) {
      toast.warn(props.messages.unmanaged);
      return;
    }

    if (work.orb_code === '' || work.title === '') {
      toast.warn(props.messages.required_inputs);
      return;
    }

    setIsSaving(true);
    if (props.edit_work) {
      ORBApi.putWorks(work)
        .then(() => {
          toast.success(props.messages.finished_edition);
          props.onSaveWork(work);
        })
        .catch((err) => {
          toast.error(props.messages.editing_failed);
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      ORBApi.postWorks(work)
        .then((result) => {
          toast.success(props.messages.saved_work);
          props.onSaveWork(work);
        })
        .catch((err) => {
          toast.error(props.messages.failed_to_save);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    if (props.edit_work) {
      setTitle(props.form_texts.title_edit);
      setWork(props.edit_work);
    } else {
      setTitle(props.form_texts.title);
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label={props.form_texts.work}
            variant="outlined"
            fullWidth
            value={work.title}
            onChange={(e) =>
              setWork({ ...work, title: e.target.value.toUpperCase() })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label={props.form_texts.iswc}
            variant="outlined"
            fullWidth
            value={work.iswc}
            onChange={(e) =>
              setWork({ ...work, iswc: e.target.value.toUpperCase() })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="text"
            label={props.form_texts.orb_code}
            variant="outlined"
            fullWidth
            value={work.orb_code}
            onChange={(e) =>
              setWork({ ...work, orb_code: e.target.value.toUpperCase() })
            }
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex' }}>
          <Typography
            style={{ marginRight: '5px' }}
            variant="h6"
            component="h6"
          >
            Split
          </Typography>
          {!showSplitForm ? (
            <AddCircleIcon
              fontSize="large"
              color="secondary"
              className="clickable"
              onClick={() => setShowSplitForm(!showSplitForm)}
            />
          ) : (
            <RemoveCircleIcon
              fontSize="large"
              color="secondary"
              className="clickable"
              onClick={() => setShowSplitForm(!showSplitForm)}
            />
          )}
        </Grid>

        {/* Form used to add a new split into splits list */}
        <Collapse className="split-form" in={showSplitForm} timeout="auto">
          <Grid
            style={{ padding: '10px' }}
            container
            spacing={2}
            className="modal-split-form"
          >
            <Grid item xs={12} md={4}>
              <SuggestContributors
                clearInput={!splitOnPlay.contributor.data.name ? true : false}
                value={splitOnPlay.contributor.data.name}
                onChange={(value) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.contributor.data.id = value.id;
                  newSplitOnPlay.contributor.data.name = value.name;
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label={props.form_texts.split_percent}
                value={splitOnPlay.contributor.share}
                type="number"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.contributor.share = parseFloat(e.target.value);
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label={props.form_texts.split_orb_fee}
                value={splitOnPlay.contributor.orb_fee}
                type="number"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.contributor.orb_fee = parseFloat(
                    e.target.value,
                  );
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PublishersDropdown
                label={props.form_texts.split_publisher}
                className="publisher-dropdown"
                value={
                  splitOnPlay.publisher.publisher_id
                    ? splitOnPlay.publisher.publisher_id
                    : 0
                }
                onChange={(publisher) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.publisher.data.name =
                    publisher.name.toUpperCase();
                  newSplitOnPlay.publisher.publisher_id = parseInt(
                    publisher.id,
                  );
                  newSplitOnPlay.publisher.data.id = publisher.contributor_id;
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label={props.form_texts.split_percent}
                value={splitOnPlay.publisher.share}
                type="number"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.publisher.share = parseFloat(e.target.value);
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={splitOnPlay.publisher.orb_fee}
                type="number"
                label={props.form_texts.split_orb_fee}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const newSplitOnPlay = { ...splitOnPlay };
                  newSplitOnPlay.publisher.orb_fee = parseFloat(e.target.value);
                  setSplitOnPlay(newSplitOnPlay);
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                      let value;
                      !checked ? (value = 1) : (value = 0);
                      const newSplitOnPlay = { ...splitOnPlay };

                      newSplitOnPlay.is_orb_managed = value;

                      setSplitOnPlay(newSplitOnPlay);
                    }}
                    name="checkedA"
                  />
                }
                label={props.form_texts.split_controlled}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={addSplitItem}
              >
                {props.form_texts.split_button}
              </Button>
            </Grid>
          </Grid>
        </Collapse>

        <TableForm
          split_headers={props.form_texts.split_headers}
          contributions={work.contributions}
          removeSplitItem={removeSplitItem}
        />
      </Grid>
      <Grid xs={12} md={3}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            style={{ marginTop: '15px' }}
            onClick={saveWork}
          >
            {props.edit_work
              ? props.form_texts.edit_button
              : props.form_texts.save_button}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default WorksForm;
