export const GraphColors = [
  '#d0315e',
  '#9c3552',
  '#692336',
  '#633843',
  '#856970',
  '#611e2f',
];
export const GraphGrayColors = ['#696969', '#aaaaaa', '#e3e3e3', '#e6e6e6'];
export const GraphOptions = {
  legend: { display: false },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            });
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return tooltipItem.yLabel.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      },
    },
  },
};
