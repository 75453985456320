import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import SuggestContributors from '../../../../components/common/SuggestContributors';


export default function SplitsTab(
  {splits, 
  form_texts,
  removeSplitItem,
  addSplitItem,
  translateCategory,
  updateSplitOnPlay,
  updateUpc,
  splitOnPlay,
  addNewTab,
  deleteTab,
  splitError,
  upcError
}) {
    const [value, setValue] = useState(0);
    const [showConfirmationPanel, setShowConfirmationPanel] = useState(false)
    const [editingUpc, setEditingUpc] = useState(false);
    const [hasUpc, setHasUpc] = useState(Array(splits.length).fill(""));
    const [checked, setChecked] = useState(false)

    const handleChange = (event, newValue) => {
      setValue(newValue);
      if (!splits[newValue].upc && newValue > 0) {
        setHasUpc((prevHasUpc) => {
          const updatedHasUpc = [...prevHasUpc];
          updatedHasUpc[newValue] = "";
          return updatedHasUpc;
        });
      }
    };
  
    const handleUpcChange = (e) => {
      setHasUpc((prevHasUpc) => {
        const updatedHasUpc = [...prevHasUpc];
        updatedHasUpc[value] = e.target.value;
        return updatedHasUpc;
      });
    };
  
    const handleUpcFocusOut = () => {
      updateUpc(value, hasUpc[value]);
      setEditingUpc(false);
    };
  
    const handleUpcKeyPress = (e) => {
      if (e.key === "Enter") {
        updateUpc(value, hasUpc[value]);
        setEditingUpc(false);
      }
    };
  
    const handleEditUpcClick = () => {
      setEditingUpc(true);
    };
  
    useEffect(() => {
      if (editingUpc) {
        setHasUpc((prevHasUpc) => {
          const updatedHasUpc = [...prevHasUpc];
          updatedHasUpc[value] = splits[value]?.upc || "";
          return updatedHasUpc;
        });
      } else if (!editingUpc) {
        setHasUpc((prevHasUpc) => {
          const updatedHasUpc = [...prevHasUpc];
          updatedHasUpc[value] = "";
          return updatedHasUpc;
        });
      }
    }, [editingUpc, splits, value]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtons
          aria-label="scrollable force tabs example"
        >

        {splits.map((split, index) => (
            <Tab key={index}  label={
              <div style={{display: 'flex', alignItems: 'center',  gap:"20px", color: split.error ? 'red' : 'inherit'}}>
                
                {split.error ? 
                 <Tooltip arrow placement="top" title="Erro nos valores dos splits" >
                    <ErrorOutlineIcon className="errorIcon"/> 
                  </Tooltip>
                  : null}

            
                  {index === 0 ? (
                    editingUpc ? (
                      <input
                        required
                        className="custom-textfield"
                        type="text"
                        value={hasUpc[index] || ''}
                        placeholder="Preencha o UPC"
                        onKeyPress={handleUpcKeyPress}
                        onChange={handleUpcChange}
                        onBlur={handleUpcFocusOut}
                      />
                    ) : !split.upc ? ( 
                      <input
                        required
                        className="custom-textfield"
                        type="text"
                        value={hasUpc[index] || ''}
                        placeholder="Preencha o UPC"
                        onKeyPress={handleUpcKeyPress}
                        onChange={handleUpcChange}
                        onBlur={handleUpcFocusOut}
                      /> 
                    ) : (
                      <Typography style={{ fontSize: '14px' }}>
                        {split.upc}
                      </Typography>
                    )
                  ) : (
                    editingUpc ? (
                      <input
                        required
                        className="custom-textfield"
                        type="text"
                        value={hasUpc[index] || ''}
                        placeholder="Preencha o UPC"
                        onKeyPress={handleUpcKeyPress}
                        onChange={handleUpcChange}
                        onBlur={handleUpcFocusOut}
                      />
                    ) : !split.upc ? ( 
                      <input
                        required
                        className="custom-textfield"
                        type="text"
                        value={hasUpc[index] || ''}
                        placeholder="Preencha o UPC"
                        onKeyPress={handleUpcKeyPress}
                        onChange={handleUpcChange}
                        onBlur={handleUpcFocusOut}
                      /> 
                    ) : (
                      <Typography style={{ fontSize: '14px' }}>
                        {split.upc}
                      </Typography>
                    )
                  )}
                  {index === value? (
                    <div>
                      {split.upc ? (
                        <EditIcon 
                          className="editIcon"
                          fontSize='small'
                          onClick={handleEditUpcClick}                
                        />
                      ) : null}
                
                  {splits.length > 1? (
                    <CloseIcon 
                    fontSize='small'
                      className="closeIcon"
                      onClick={() => {
                        setShowConfirmationPanel(true)        
                      }} 
                    />
                  ) : null}
                </div>
                ) : null
              }  
              </div>
            }
            />
          ))}
          <IconButton onClick={() => {
            addNewTab()
            // if(hasUpc[value])updateUpc(value, hasUpc[value])
            setValue(splits.length)
            // setHasUpc('')
          }}>
            +
          </IconButton>
        </Tabs>
        {upcError && !splits[value].upc ? <Alert style={{ margin: '10px 0 0 0' }} severity="warning">O campo UPC é obrigatório</Alert> : null}  
      </Grid>
      <Grid item xs={12}>

      <Grid container xs={12} spacing={2}>

        <Grid item xs={12} md={4}>
          <SuggestContributors
            clearInput={!splitOnPlay.name ? true : false}
            value={splitOnPlay.contributions[0].name || ''}
            onChange={(value) => updateSplitOnPlay({...splitOnPlay, contributions: [
              {
                ...splitOnPlay.contributions[0],
                name: value.name,
                contributor_id:value.id
              }
            ]})}
            error={!splitOnPlay.contributions[0].name ? splitError : false}
            helperText={!splitOnPlay.contributions[0].name && splitError ? 'Campo obrigatório: por favor, preencha o nome do titular.' : ''}
          />
        </Grid>

        <Grid item xs={12} md={4}>
            <TextField
              value={splitOnPlay.contributions[0].share || ''}
              type="number"
              label={form_texts.split_percent}
              variant="outlined"
              fullWidth
              onChange={(event) => updateSplitOnPlay({...splitOnPlay, contributions: [
                {
                  ...splitOnPlay.contributions[0],
                  share: parseFloat(event.target.value)
                }
              ]})}
              error={!splitOnPlay.contributions[0].share ? splitError : false}
              helperText={!splitOnPlay.contributions[0].share && splitError ? 'Campo obrigatório: por favor, preencha o percentual do titular.' : ''}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
              <FormControl 
              error={!splitOnPlay.contributions[0].category ? splitError : false} 
              variant="outlined" 
              fullWidth>
                <InputLabel>{form_texts.split_category}</InputLabel>
                <Select
                  native
                  label="Categoria"
                  value={splitOnPlay.contributions[0].category || ''}
                  onChange={(event) => updateSplitOnPlay({...splitOnPlay, contributions: [
                    {
                      ...splitOnPlay.contributions[0],
                      category: event.target.value
                    }
                  ]})}
                  
                >
                  <option value="" />
                  {form_texts.split_category_items.map((category) => (
                    <option value={category.value}>{category.name}</option>
                  ))}
                </Select>
                {splitError && !splitOnPlay.contributions[0].category && <FormHelperText>Campo obrigatório.</FormHelperText>}
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      
                      updateSplitOnPlay({...splitOnPlay, contributions: [
                      {...splitOnPlay.contributions[0], is_orb_managed: !checked}
                    ]})
                    setChecked(!checked)
                  }
                  }
                    name="checkedA"
                  />
                }
                label={form_texts.split_controlled}
              />
            </Grid>

        </Grid>
        
        <Button 
        style={{margin:"10px 5px 5px 5px", 
        fontWeight:"bold"}} 
        color="secondary" 
        onClick={() => {
          addSplitItem(value)
          setChecked(false)
          }}>{form_texts.split_button}</Button>

      </Grid>

      <TableForm 
       contributions={splits.length > 0 && splits[value]?.contributions ? splits[value].contributions : []}
        split_headers={form_texts.split_headers}
        removeSplitItem={removeSplitItem}
        translateCategory={translateCategory}
        selectedSplitIndex={value}
      />


      <Modal
        open={showConfirmationPanel}
        onClose={() => setShowConfirmationPanel(!showConfirmationPanel)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div className="modal-container" style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"20px", width:"400px"}}>
        <Typography>Deseja remover o split ?</Typography>

        <div style={{display:"flex", gap:"10px"}}>
          <Button
          variant="contained"
          color="secondary"
          onClick={() => {
          deleteTab(value)
          setShowConfirmationPanel(false)
          setValue(value > 0 ? value - 1 : 0)
          }
          }>
            Sim
          </Button>
          <Button 
          variant="contained"
          onClick={() => {
          setShowConfirmationPanel(false)
          }
          }>
            Cancelar
          </Button>
        </div>
        
        </div>
      </Modal>

    </Grid>
  );


}


function TableForm({contributions, split_headers, removeSplitItem, translateCategory, selectedSplitIndex}) {

  return (
    <>
      {
      contributions ? (
        contributions.length > 0  ? (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small" className="basic-table table-scroll">
                <TableHead className="basic-table-head">
                  <TableRow>
                    {split_headers.map((title, key) => {
                      return <TableCell align="center" key={key}>{title}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    contributions.map((contrib, index) => {
                        return (
                          <TableRow className="row" key={index}>
                            <TableCell align="center">{contrib.name}</TableCell>
  
                            <TableCell align="center">{contrib.share}</TableCell>
  
                            <TableCell align="center">
                              {translateCategory(contrib.category)}
                            </TableCell>
                            <TableCell align="center">
                              <Box display="flex" justifyContent="center">
                                {contrib.is_orb_managed ? (
                                  <CheckIcon color="success" />
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" justifyContent="center" onClick={() => removeSplitItem(selectedSplitIndex, index)}>
                                <DeleteIcon  className='clickable'/>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null
      ) : null
      }
    </>
  );
}
