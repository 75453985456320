import React, { useEffect, useState } from 'react';
import ORBApi from '../../../services/ORBApi';
import { toast } from 'react-toastify';
import { Button, Grid, TextField } from '@material-ui/core';
import DateMonthPicker from '../../../components/common/DateMonthPicker';
import { PaymentFilesTable } from './Tables';
import CurrencysDropdown from '../../../components/common/CurrencyDropdown';
import moment from 'moment';

export function NewDistributionPanel(props) {
  // {onSave, distribution, texts}

  const [name, setName] = useState(
    props.distribution ? props.distribution.name : '',
  );
  const [referenceDate, setReferenceDate] = useState(
    props.distribution
      ? props.distribution.reference_date
      : moment().format('YYYY-MM'),
  );
  const [currency, setCurrency] = useState(
    props.distribution ? props.distribution.currency : '',
  );
  const [pmtFiles, setPmtFiles] = useState({ current_page: 1, reports: [] });
  const [rawReferenceDate, setRawReferenceDate] = useState(
    formatDateStringToGMT(
      props.distribution
        ? props.distribution.reference_date
        : moment(new Date()).format('YYYY-MM-DD'),
    ),
  );

  function formatDateStringToGMT(dateString) {
    const parts = dateString.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);

    const formattedDate = new Date(Date.UTC(year, month));

    return formattedDate.toUTCString();
  }

  const saveDistribution = () => {
    if (!props.distribution) {
      // New distribution

      ORBApi.postDistribution(name, referenceDate, currency)
        .then(() => {
          toast.success(props.messages.created);
          props.onSave();
        })
        .catch(() => {
          toast.error(props.messages.create_failed);
        });
    } else {
      // Just editing a distribution
      // When user do not edit the date, reference date still have the yyyy-mm-dd format
      var refDate = referenceDate;
      if (refDate.length > 7) refDate = refDate.substring(0, 7);
      ORBApi.updateDistribution(props.distribution.id, name, refDate, currency)
        .then(() => {
          toast.success(props.messages.updated);
          props.onSave();
        })
        .catch(() => {
          toast.error(props.messages.update_failed);
        });
    }
  };

  const loadPaymentFiles = (page) => {
    ORBApi.loadFilesToDistribute(page, { status: 4 }) // show only files with status == 4 ( ready )
      .then((data) => {
        setPmtFiles(data);
      })
      .catch(() => {
        toast.error(props.messages.payment_files_load_failed);
      });
  };

  useEffect(() => {
    if (!props.distribution) loadPaymentFiles(1); // only show pmt files when creating
  }, []);

  return (
    <>
      <div className="modal-container new-distribution">
        <h4>
          {props.distribution
            ? props.texts.form_title_edit
            : props.texts.form_title}
        </h4>
        <hr />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4} style={{ marginBottom: '5px' }}>
            <DateMonthPicker
              label={props.texts.form_input_names.reference_date}
              required
              format="MM/yyyy"
              view={['month', 'year']}
              value={rawReferenceDate}
              onChange={(rawDate, date) => {
                setRawReferenceDate(rawDate);
                setReferenceDate(date);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label={props.texts.form_input_names.distribution_name}
              fullWidth
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CurrencysDropdown
              value={currency}
              onChange={(value) => setCurrency(value)}
            />
          </Grid>
          <Grid item xs={12}>
            {!props.distribution ? (
              <>
                <h6>{props.texts.form_table_title}</h6>
                <PaymentFilesTable
                  header_table={props.texts.form_header}
                  data={pmtFiles}
                  onPageChange={loadPaymentFiles}
                />
              </>
            ) : null}
          </Grid>

          <Grid xs={12} md={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={saveDistribution}
            >
              {props.distribution
                ? props.texts.edit_button
                : props.texts.save_button}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
