import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../../components/common/Loading';
import { Grid, TextField, Button } from '@material-ui/core';
import ORBApi from '../../../../services/ORBApi';
import CheckMarksDropdown from '../../../../components/common/CheckMarksDropdown';

const ClientsForm = (props) => {
  const [agent, setAgent] = useState({
    name: '',
    email: '',
    contributors: [],
  });
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [contributorsData, setContributorsData] = useState([]);

  function saveAgent() {
    setIsSaving(true);
    if (props.edit_agents) {
      ORBApi.putAgents(agent)
        .then(() => {
          toast.success('Edição salva com sucesso!');
          props.onSaveAgent();
        })
        .catch(() => {
          toast.error('Falha ao editar dados do Agente.');
        })
        .finally(() => {
          setIsSaving(false);
          setAgent(null);
        });
    } else {
      ORBApi.postAgents(agent)
        .then(() => {
          toast.success('Agente salvo com sucesso!');
          props.onSaveAgent();
        })
        .catch(() => {
          toast.error('Falha ao salvar dados do Agente.');
        })
        .finally(() => {
          setIsSaving(false);
          setAgent(null);
        });
    }
  }

  useEffect(() => {
    if (props.edit_agents) {
      setTitle('Editar Agente');
      setAgent(props.edit_agents);
    } else {
      setTitle('Criar Agente');
    }
    //Load the contributors when the component mount
    ORBApi.loadContributors(1, null, 0).then((response) => {
      setContributorsData(response.contributors);
    });
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            value={agent.name}
            onChange={(e) =>
              setAgent({
                ...agent,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={agent.email}
            onChange={(e) =>
              setAgent({
                ...agent,
                email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CheckMarksDropdown
            data={contributorsData}
            onChange={(contributor) =>
              setAgent({ ...agent, contributors: [...contributor] })
            }
            ids={agent.contributors}
            label="Titulares"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} style={{ marginTop: '15px' }}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={saveAgent}
          >
            {' '}
            Salvar{' '}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default ClientsForm;
