import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatCurrency } from '../../../utils/CurrencyUtils';

import PaginationItem from './PaginationItem';

export default function SimpleTable({ data, headers, currency }) {
  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table
          sx={{ minWidth: 0, marginBottom: '1em' }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              {headers.map((title, key) => {
                return (
                  <TableCell key={key} align="center">
                    {title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== undefined
              ? data.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">
                      <div className="orb"></div>
                    </TableCell>

                    <TableCell key={key} align="center">
                      {row.title}
                    </TableCell>

                    <TableCell key={key} align="center">
                      {row.streams}
                    </TableCell>

                    <TableCell key={key} align="center">
                      {formatCurrency(row.revenue, currency)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {dataLoader ? ( // do not show pagination when there is nothing to load after
        <PaginationItem data={data} changePage={dataLoader} />
      ) : null} */}
    </>
  );
}
