import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Tabs,
  Tab,
  Modal,
  Backdrop,
  Button,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from '@material-ui/lab/Pagination';
import SessionContext from '../../../../contexts/SessionContext';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Loading from '../../../../components/common/Loading';
import CheckIcon from '@material-ui/icons/Check';

/** Table with all the tracks */
export default function TracksTable(props) {
  //props: headers_table, split_headers, tracks, translateCategory, pages, currentPage, onPageChange, onRowClick, deleteTrack, deletingTrack
  

  let { hasRole } = useContext(SessionContext);

  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              <TableCell></TableCell>
              {props.headers_table.map((title, index) => (
                <TableCell key={index} align="center">{title}</TableCell>
              ))}
              {hasRole(['TRACKS_FULL_VIEW', 'TRACKS_WRITE']) &&  <TableCell slign="center"></TableCell> }
             
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tracks?.length > 0
              ? props.tracks.map((track) => {
                  return (
                    <TrackRow
                      split_headers={props.split_headers}
                      key={track.id}
                      track={track}
                      onClick={props.onRowClick}
                      translateCategory={props.translateCategory}
                      deleteTrack={props.deleteTrack}
                      hasRole={hasRole}
                      loading={props.deletingTrack}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.currentPage}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}

/** Tracks table row */
function TrackRow(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [value, setValue] = useState(0)
  const [showConfirmationPanel, setShowConfirmationPanel] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <TableRow className="row">
        <TableCell className="cell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        {props.hasRole(['TRACKS_FULL_VIEW', 'TRACKS_WRITE']) ? (
          <>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.track)}
            >
              {props.track.label}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.track)}
            >
              {props.track.title}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.track)}
            >
              {props.track.artist}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.track)}
            >
              {props.track.isrc}{' '}
            </TableCell>
            <TableCell
              className="cell"
              align="center"
            >
              <DeleteIcon 
                className="clickable deleteIcon"
                fontSize="small"
                onClick={() => {
                  setShowConfirmationPanel(true)
                }}
                />
            
            </TableCell>
          </>
        ) : props.hasRole(['TRACKS_CLIENT_VIEW', 'TRACKS_READ']) ? (
          <>
            <TableCell className="cell" align="center">
              {props.track.label}{' '}
            </TableCell>
            <TableCell className="cell" align="center">
              {props.track.title}{' '}
            </TableCell>
            <TableCell className="cell" align="center">
              {props.track.artist}{' '}
            </TableCell>
            <TableCell className="cell" align="center">
              {props.track.isrc}{' '}
            </TableCell>
          </>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse timeout="auto" in={!collapsed}>
            <Typography variant="h6" gutterBottom component="div">
              {' '}
              Split{' '}
            </Typography>
            <Box margin={1}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
            {props.track.splits.length > 0 ? 
              props.track.splits.map((split, index) => (
                <Tab key={index} label={split.upc ? split.upc :"Padrão"} />
              )) 
              : null
            }
            </Tabs>
            <Table
              className="split-table"
              aria-label="purchases"
            >
              <TableHead>
                <TableRow>
                  {props.split_headers.map((title, key) => (
                    <TableCell key={key} align="center">
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {props.track.splits.length > 0 &&
                props.track.splits[value].contributions.map(contrib => (
                  <TableRow key={contrib.id}>
                    <TableCell className="cell">
                      {props.translateCategory(contrib.category)}
                    </TableCell>
                    <TableCell className="cell">{contrib.name}</TableCell>
                    <TableCell className="cell">{contrib.artist_name}</TableCell>
                    <TableCell className="cell">{contrib.share}</TableCell>
                    <TableCell className='cell'>
                    {contrib.is_orb_managed ? (
                      <CheckIcon color="success" />
                    ) : null}
                    </TableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={showConfirmationPanel}
        onClose={() => 
          setShowConfirmationPanel(false)
        }
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container confirmation-panel">
          <Typography >Tem certeza que deseja deletar a faixa {props.track.title}</Typography>

          {props.loading ? <Loading text="Deletando faixa..." /> : 
            (<div className="buttons-container"> 
              <Button variant="contained" color="secondary" onClick={() => { props.deleteTrack(props.track.id)}}>Deletar</Button>
              <Button variant="contained" color="default" onClick={() => { setShowConfirmationPanel(false)}}>Cancelar</Button>
            </div>
          )}
    
        </div>
      </Modal>
    </>
  );
}
