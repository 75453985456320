import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PaginationItem from './PaginationItem';
import moment from 'moment';

export default function ExecutionsTracksTable({ data, dataLoader, headers }) {
  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table
          sx={{ minWidth: 0, marginBottom: '1em' }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {headers?.map((title, key) => {
                return (
                  <TableCell key={key} align="center">
                    {title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.executions.length > 0
              ? data.executions.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell key={key} align="center">
                      {moment(row.selling_month).format('MM/YYYY')}
                    </TableCell>

                    <TableCell key={key} align="center">
                      {row.track_name}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.artist_name}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.platform}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.streams}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.value_before_fees}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.net_value}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.category}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.share + '%'}
                    </TableCell>
                    <TableCell key={key} align="center">
                      {row.client_revenue}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationItem data={data} changePage={dataLoader} />
    </>
  );
}
