import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import Loading from '../../../../../components/common/Loading';
import ORBApi from '../../../../../services/ORBApi';
import { GraphOptions, GraphColors, GraphGrayColors } from './GraphOptions';

const WorksDSPRevenueGraphs = ({ filters, currency }) => {
  const [dsps, setDsps] = useState([]);
  const [valueDsp, setValueDsp] = useState([]);
  const [fileProcessing, setFileProcessing] = useState(0);
  const [requestSource, setRequestSource] = useState(null);

  const doSearch = () => {
    if (fileProcessing) {
      // if there is a request processing, cancel it
      ORBApi.abortRequest(requestSource);
    }
    setFileProcessing(1);
    // Get the request abort source, to be used to cancel the request if needed
    var cancelRequestSource = ORBApi.getRequestAbortSource();
    setRequestSource(cancelRequestSource);
    ORBApi.loadWorksDspRevenue(
      filters.startDate,
      filters.endDate,
      filters.dateType,
      filters.workId,
      filters.contributorId,
      filters.publisher,
      cancelRequestSource,
    )
      .then((data) => {
        setDsps(data.dsps.map((i) => i.dsp));
        setValueDsp(data.dsps.map((i) => i.value));
        setFileProcessing(0);
      })
      .catch((error) => {
        if (!ORBApi.isCancel(error)) {
          // When cancelling the request, do not remove the "loading"
          setFileProcessing(0);
        }
      });
  };

  useEffect(() => {
    doSearch();
  }, [filters]);

  return (
    <div>
      {fileProcessing ? (
        <Loading className="file-loading-container" />
      ) : (
        <Pie
          options={{
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var dsp = data.labels[tooltipItem.index];
                  var value = data.datasets[0].data[
                    tooltipItem.index
                  ].toLocaleString('pt-br', {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 1,
                  });
                  return dsp + ' ' + value;
                },
              },
            },
          }}
          data={{
            labels: dsps,
            datasets: [
              {
                data: valueDsp,
                backgroundColor: GraphGrayColors,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default WorksDSPRevenueGraphs;
