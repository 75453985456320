import React, { useState } from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 100,
  },
}));

const StatusDropdown = ({ onStatusChange }) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="normal"
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-label">Status</InputLabel>
      <Select labelId="demo-simple-select-label" onChange={onStatusChange}>
        <MenuItem style={{ height: '30px' }} value={null}></MenuItem>
        <MenuItem value={0}>Waiting</MenuItem>
        <MenuItem value={1}>Loading</MenuItem>
        <MenuItem value={2}>Loaded</MenuItem>
        <MenuItem value={3}>Distributed</MenuItem>
        <MenuItem value={4}>Ready</MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
