import React, { useEffect, useState, useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoBranca from '../../assets/logo-branca.png';
import Nav from 'react-bootstrap/Nav';
import { useHistory } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';
import SessionContext, { SystemRoles } from '../../contexts/SessionContext';
import config from '../../config';
import { getTranslatedTexts } from '../../utils/TranslationUtils';

const SimpleNavbar = (props) => {
  const history = useHistory();
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  const pageAccess = (e, page) => {
    history.replace(page);
    e.preventDefault();
  };

  useEffect(() => {
    const translatedTexts = getTranslatedTexts('menu', language_preference);
    setTexts(translatedTexts);

  }, [language_preference]);

  return (
    <Navbar
      /*fixed="top"*/ collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ backgroundColor: '#111' }}
    >
      <Navbar.Brand href="">
        <img
          className="logo-navbar"
          src={logoBranca}
          alt="orb logo"
          height={40}
          width={40}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {hasRole(['TRACKS', 'WORKS', 'CLIENTS', 'CONTRIBUTORS', 'AGENTS']) ? (
            <DropDownMenu
              path="register"
              className={
                props.selectedOption === 'drop_register'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              title={texts ? texts.register : ''}
              list={texts ? texts.register_items : []}
              roles={[
                ['WORKS'],
                ['TRACKS'],
                ['LABELS'],
                ['PUBLISHERS'],
                ['CONTRIBUTORS'],
                ['CLIENTS'],
                ['AGENTS'],
              ]}
              pageAccess={pageAccess}
            />
          ) : null}

          {hasRole(['PAYMENT_FILE_WORKS', 'PAYMENT_FILE_TRACKS']) ? (
            <DropDownMenu
              path="files"
              className={
                props.selectedOption === 'drop_files'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              title={texts ? texts.files : ''}
              list={texts ? texts.files_items : []}
              roles={[['PAYMENT_FILE_WORKS'], ['PAYMENT_FILE_TRACKS']]}
              pageAccess={pageAccess}
            />
          ) : null}

          {hasRole(['DISTRIBUTIONS']) ? (
            <>
              <Nav.Link
                className={
                  props.selectedOption === 'drop_distributions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                onClick={(e) => pageAccess(e, `/distributions`)}
              >
                {texts ? texts.distributions : ''}
              </Nav.Link>
            </>
          ) : null}

          <Nav.Link
            className={
              props.selectedOption === 'drop_reports'
                ? 'nav-link active'
                : 'nav-link'
            }
            onClick={(e) => pageAccess(e, `/reports`)}
          >
            {texts ? texts.reports : ''}
          </Nav.Link>

          <DropDownMenu
            path="dashboard"
            className={
              props.selectedOption === 'drop_dashboard'
                ? 'nav-link active'
                : 'nav-link'
            }
            title="Dashboard"
            list={texts ? texts.dashboards_items : []}
            roles={[['DASHBOARD_WORKS'], ['DASHBOARD_TRACKS']]}
            pageAccess={pageAccess}
          />
        </Nav>
        <Nav style={{marginLeft:"auto"}}>
        </Nav>
      </Navbar.Collapse>
    
    </Navbar>
  );
};

export default SimpleNavbar;
