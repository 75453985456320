import React, { useState, useEffect } from 'react';
import '../../../utils/date';
import FilterIcon from '../../../icons/FilterIcon';
import LabelsDropdown from '../../../components/common/LabelsDropdown';
import DateMonthPicker from '../../../components/common/DateMonthPicker';
import { Button, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import PublishersDropDown from '../../../components/common/PublishersDropdown';
import SuggestContributors from '../../../components/common/SuggestContributors';

export const defaultFilters = {
  label: 0,
  publisher: 0,
  startDate: new Date().addMonths(-12).toMonthFormat(),
  endDate: new Date().addMonths(-1).toMonthFormat(),
  dateType: 'report_date',
};
export default function ActionBar(props) {
  //props: {onFilterChange, showPublishers, showLabels}

  const [showFilters, showHideFilters] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [rawStartDate, setRawStartDate] = useState(
    `${new Date().getMonth() + 1}/01/${new Date().getFullYear() - 1}`,
  );
  const [rawEndDate, setRawEndDate] = useState(
    `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`,
  );

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const onPublisherChangeHandler = (pub, trigger_state = false) => {
    let newFilters = { ...filters, publisher: pub.id };
    updateFilters(newFilters);
    if (trigger_state === true) props.onFilterChange(newFilters);
  };
  const onContributorChangeHandler = (contributor, trigger_state = false) => {
    let newFilters = { ...filters, contributorId: contributor.id };
    updateFilters(newFilters);
    if (trigger_state === true) props.onFilterChange(newFilters);
  };

  const onLabelChangeHandler = (label, trigger_state = false) => {
    let newFilters = { ...filters, label: label.id };
    updateFilters(newFilters);
    if (trigger_state === true) props.onFilterChange(newFilters);
  };
  const onPeriodTypeChange = (e) => {
    updateFilters({ ...filters, dateType: e.target.value });
  };
  const onStartDateChange = (rawDate, date) => {
    setRawStartDate(rawDate);
    updateFilters({ ...filters, startDate: date });
  };
  const onEndDateChange = (rawDate, date) => {
    setRawEndDate(rawDate);
    updateFilters({ ...filters, endDate: date });
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <div className="radio-group-container">
            <RadioGroup
              className="radio-group"
              aria-label="Período"
              onChange={onPeriodTypeChange}
              value={filters.dateType}
            >
              <FormControlLabel
                className="radio-item"
                value="report_date"
                control={<Radio />}
                label={props.texts.distribution_period}
              />
              <FormControlLabel
                className="radio-item"
                value="selling_date"
                control={<Radio />}
                label={props.texts.sales_period}
              />
            </RadioGroup>
          </div>

          <DateMonthPicker
            className="filter-date"
            label={props.texts.start_date}
            format="MM/yyyy"
            view={['month', 'year']}
            onChange={onStartDateChange}
            value={rawStartDate}
          />
          <DateMonthPicker
            className="filter-date"
            label={props.texts.end_date}
            format="MM/yyyy"
            view={['month', 'year']}
            onChange={onEndDateChange}
            value={rawEndDate}
          />
          

          {props.hasRole(['DASHBOARD_TRACKS_FULL_VIEW']) &&
            (props.showLabels ? (
              <LabelsDropdown
                label={props.texts.label}
                onChange={onLabelChangeHandler}
                onLoaded={(label) => {
                  onLabelChangeHandler(label, true);
                }}
                value={filters.label}
                hideEmpty={true}
                margin="normal"
              />
            ) : null)}
          {props.hasRole(['DASHBOARD_WORKS_FULL_VIEW']) &&
            (props.showPublishers ? (
              <PublishersDropDown
                label={props.texts.publisher}
                onChange={onPublisherChangeHandler}
                onLoaded={(pub) => {
                  onPublisherChangeHandler(pub, true);
                }}
                value={filters.publisher}
                hideEmpty={true}
                margin="normal"
              />
            ) : null)}

          {props.hasRole(['DASHBOARD_TRACKS_AGENT_VIEW', 'DASHBOARD_WORKS_AGENT_VIEW', 'DASHBOARD_TRACKS_FULL_VIEW', 'DASHBOARD_WORKS_FULL_VIEW'])?
            <SuggestContributors
            value={props.texts.contributor_name}
            useToFilter={true}
            onChange={(contributor) => {
              onContributorChangeHandler(contributor, true);
            }}
            margin={true}

          />
          : null}
          <div className="btn-container">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.onFilterChange(filters);
              }}
            >
              {props.texts.button}
            </Button>
          </div>
        </div>
      ) : null}
      <hr />
    </>
  );
}
