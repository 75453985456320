import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.css';
import { formatCurrency } from '../../../../../utils/CurrencyUtils';

const CircularProgress = ({ percentageValue, totalValue, currency }) => {
  const [value, setValue] = useState(0);
  const targetValue = (percentageValue / totalValue) * 100;
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (value < targetValue) {
      setTimeout(() => {
        setValue((prevValue) => Math.min(prevValue + 1, targetValue));
      }, 5);
    }
  }, [value, targetValue]);

  return (
    <div className="circular-progress-container">
      <div
        className={`circular-progress-wrapper ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CircularProgressbar
          value={value}
          strokeWidth={10}
          styles={buildStyles({
            trailWidth: 10,
            trailColor: '#eee',
            pathColor: isHovered ? '#e00644' : '#d0315e',
            strokeLinecap: 'rounded',
            pathTransition: 'none',
          })}
          className="circular-progress-bar"
        />
        {isHovered && (
          <div className="tooltip">
            <span>{`${value.toFixed(2)}%`}</span>
          </div>
        )}
      </div>
      <p className="percentage-value">
        {formatCurrency(percentageValue, currency)}
      </p>
    </div>
  );
};

export default CircularProgress;
