import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import WorksMonthlyRevenueGraph from './graphs/WorksMonthlyRevenueGraph';
import WorksDSPRevenueGraph from './graphs/WorksDSPRevenueGraphs';
import WorksRevenueGraph from './graphs/WorksRevenueGraph';
import WorksTerritoryRevenueGraph from './graphs/WorksTerritoryRevenueGraph';
import WorksContributorRevenueGraph from './graphs/WorksContributorRevenueGraph';

export default function GraphsPanel({
  language,
  currency,
  texts,
  filters,
  hasRole,
}) {
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.monthly_distribution}</h4>
            <WorksMonthlyRevenueGraph
              language={language}
              currency={currency}
              filters={filters}
            />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.dsp_distribution}</h4>
            <WorksDSPRevenueGraph currency={currency} filters={filters} />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.work_distribution}</h4>
            <WorksRevenueGraph currency={currency} filters={filters} />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.territory_distribution}</h4>
            <WorksTerritoryRevenueGraph currency={currency} filters={filters} />
          </div>
        </Grid>
        {hasRole([
          'DASHBOARD_WORKS_FULL_VIEW',
          'DASHBOARD_WORKS_AGENT_VIEW',
        ]) ? (
          <Grid item md={6} sm={12}>
            <div className="graph-container">
              <h4>{texts.holder_distribution}</h4>
              <WorksContributorRevenueGraph
                currency={currency}
                filters={filters}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
