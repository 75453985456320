export function formatCurrency(value, currency) {
  let formattedValue = '';
  if (!value) {
    switch (currency) {
      case 'BRL':
        formattedValue = 'R$0,00';
        break;
      case 'USD':
        formattedValue = '$0.00';
        break;
      case 'EUR':
        formattedValue = '€0,00';
        break;
      default:
        formattedValue = '';
        break;
    }
  } else {
    switch (currency) {
      case 'BRL':
        formattedValue = value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
        break;
      case 'USD':
        formattedValue = value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        break;
      case 'EUR':
        formattedValue = value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'EUR',
        });
        break;
      default:
        formattedValue = '';
        break;
    }
  }

  return formattedValue;
}
