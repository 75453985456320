import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../../components/common/Loading';
import { Grid, TextField, Button } from '@material-ui/core';
import ORBApi from '../../../../services/ORBApi';
import CheckMarksDropdown from '../../../../components/common/CheckMarksDropdown';

const ClientsForm = (props) => {
  const [client, setClient] = useState({
    name: '',
    email: '',
    labels: [],
    publishers: [],
  });
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [publishersData, setPublishersData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);

  function saveClient() {
    delete client.labelsNames;
    delete client.publishersNames;

    setIsSaving(true);
    if (props.edit_clients) {
      ORBApi.putClients(client)
        .then(() => {
          toast.success('Edição salva com sucesso!');
          props.onSaveClient();
        })
        .catch(() => {
          toast.error('Falha ao editar dados do cliente.');
        })
        .finally(() => {
          setIsSaving(false);
          setClient(null);
        });
    } else {
      ORBApi.postClients(client)
        .then(() => {
          toast.success('Cliente salvo com sucesso!');
          props.onSaveClient();
        })
        .catch(() => {
          toast.error('Falha ao salvar dados do cliente.');
        })
        .finally(() => {
          setIsSaving(false);
          setClient(null);
        });
    }
  }

  useEffect(() => {
    if (props.edit_clients) {
      setTitle('Editar Cliente');
      const newClientsIds = {
        ...props.edit_clients,
        labels: props.edit_clients.labels.map((item) => item.id),
        publishers: props.edit_clients.publishers.map((item) => item.id),
        labelsNames: props.edit_clients.labels.map((item) => item.name),
        publishersNames: props.edit_clients.publishers.map((item) => item.name),
      };
      setClient(newClientsIds);
    } else {
      setTitle('Criar Cliente');
    }
    //Load the labels when the component mount
    ORBApi.loadLabels(1, 0).then((response) => {
      setLabelsData(response.labels);
    });
    //Load the publishers when the component mount
    ORBApi.loadPublishers(1, 0).then((response) => {
      setPublishersData(response.publishers);
    });
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            value={client.name}
            onChange={(e) =>
              setClient({
                ...client,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={client.email}
            onChange={(e) =>
              setClient({
                ...client,
                email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CheckMarksDropdown
            data={labelsData}
            onChange={(label) => setClient({ ...client, labels: [...label] })}
            ids={client.labels}
            // names={client.labelsNames == undefined ? [] : client.labelsNames}
            label="Selos"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CheckMarksDropdown
            data={publishersData}
            onChange={(publisher) =>
              setClient({ ...client, publishers: [...publisher] })
            }
            ids={client.publishers}
            // names={
            //   client.publishersNames == undefined ? [] : client.publishersNames
            // }
            label="Editoras"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} style={{ marginTop: '15px' }}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={saveClient}
          >
            {' '}
            Salvar{' '}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default ClientsForm;
