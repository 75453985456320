import React, { useEffect, useState } from 'react';
import Loading from '../../../../../components/common/Loading';
import { Bar } from 'react-chartjs-2';
import ORBApi from '../../../../../services/ORBApi';
import { GraphOptions, GraphColors } from './GraphOptions';

const WorksContributorRevenueGraph = ({ filters, currency }) => {
  const [contributor, setContributor] = useState([]);
  const [valueContributor, setValueContributor] = useState([]);
  const [fileProcessing, setFileProcessing] = useState(0);
  const [requestSource, setRequestSource] = useState(null);

  const doSearch = () => {
    if (fileProcessing) {
      // if there is a request processing, cancel it
      ORBApi.abortRequest(requestSource);
    }
    setFileProcessing(1);
    // Get the request abort source, to be used to cancel the request if needed
    var cancelRequestSource = ORBApi.getRequestAbortSource();
    setRequestSource(cancelRequestSource);
    ORBApi.loadWorksContributorsRevenue(
      filters.startDate,
      filters.endDate,
      filters.dateType,
      filters.workId,
      filters.contributorId,
      filters.publisher,
    )
      .then((data) => {
        setContributor(data.contributors.map((i) => i.contributor));
        setValueContributor(data.contributors.map((i) => i.value));
        setFileProcessing(0);
      })
      .catch((error) => {
        if (!ORBApi.isCancel(error)) {
          // When cancelling the request, do not remove the "loading"
          setFileProcessing(0);
        }
      });
  };

  useEffect(() => {
    doSearch();
  }, [filters]);

  return (
    <div>
      {fileProcessing ? (
        <Loading className="file-loading-container" />
      ) : (
        <Bar
          data={{
            labels: contributor,
            datasets: [
              {
                data: valueContributor,
                backgroundColor: GraphColors,
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return value.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: currency,
                      });
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                // title: function () {
                //   return '';
                // },
                label: function (tooltipItem, data) {
                  return tooltipItem.yLabel.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: currency,
                  });
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default WorksContributorRevenueGraph;
