import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';
import ORBApi from '../../../services/ORBApi';
import ExcelIcon from '../../../icons/ExcelIcon';
import MailIcon from '../../../icons/MailIcon';
import PDFLogo from '../../../assets/pdf-logo.png';
import Loading from '../../../components/common/Loading';
import { toast } from 'react-toastify';
import moment from 'moment';
toast.configure();

export function ClientsTable(props) {
  const [loadingExcel, setLoadingExcel] = useState(0);
  const [loadingMail, setLoadingMail] = useState(0);

  function sendReportToClientEmail(report) {
    setLoadingMail(1);
    let newReport = report;
    if (props.hasRole(['REPORTS_FULL_VIEW'])) {
      ORBApi.sendEmailDistribution(newReport)
        .then(() => toast.success(props.messages.report_send))
        .catch(() => toast.error(props.messages.report_send_error))
        .finally(() => {
          props.reports[report.index].state = false;
          setLoadingMail(0);
        });
    } else if (props.hasRole(['REPORTS_CLIENT_VIEW'])) {
      newReport.client_id = undefined;
      ORBApi.sendEmailDistribution(report)
        .then(() => toast.success(props.messages.report_send))
        .catch(() => toast.error(props.messages.report_send_error))
        .finally(() => {
          props.reports[report.index].state = false;
          setLoadingMail(0);
        });
    }
  }

  function downloadClientReport(report, download_type) {
    setLoadingExcel(1);
    let newReport = report;
    if (props.hasRole(['REPORTS_FULL_VIEW'])) {
      ORBApi.downloadClientDistribution(
        newReport.distribution_id,
        newReport.client_id,
      )
        .then((response) => {
          downloadFile(response, download_type);
        })
        .finally(() => {
          props.reports[report.index].state = false;
          setLoadingExcel(0);
        });
    } else if (props.hasRole(['REPORTS_CLIENT_VIEW'])) {
      newReport.client_id = undefined;

      ORBApi.downloadClientDistribution(
        newReport.distribution_id,
        newReport.client_id,
      )
        .then((response) => {
          downloadFile(response, download_type);
        })
        .finally(() => {
          props.reports[report.index].state = false;
          setLoadingExcel(0);
        });
    }
  }

  function downloadFile(response, download_type) {
    const file = new Blob([response.data], {
      type: `application/${download_type}`,
    });

    let fileName =
      response.headers['content-disposition'].split('filename=')[1];
    fileName = fileName.replaceAll('"', '');
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {props.headers_table.map((title, key) => {
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ color: '#F5F5F5' }}
                  >
                    {title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.reports.length > 0
              ? props.reports.map((report, key) => {
                  return (
                    <TableRow key={key} className="row">
                      <TableCell
                        className={`clickable ${
                          report.active ? 'active-row' : ''
                        }`}
                        align="center"
                        onClick={() => {
                          report.index = key;
                          props.onRowClick(report);
                        }}
                      >
                        {moment(report.reference_date).format('MM/YYYY')}
                      </TableCell>
                      <TableCell
                        className={`clickable ${
                          report.active ? 'active-row' : ''
                        }`}
                        align="center"
                        onClick={() => {
                          report.index = key;
                          props.onRowClick(report);
                        }}
                      >
                        {report.client}
                      </TableCell>
                      <TableCell
                        className={`clickable ${
                          report.active ? 'active-row' : ''
                        }`}
                        align="center"
                        onClick={() => {
                          report.index = key;
                          props.onRowClick(report);
                        }}
                      >
                        {props.formatCurrency(report.total, report.currency)}
                      </TableCell>
                      <TableCell
                        className={`clickable ${
                          report.active ? 'active-row' : ''
                        }`}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '5px',
                        }}
                      >
                        {loadingExcel && report.state ? (
                          <Loading size="15px" />
                        ) : (
                          <ExcelIcon
                            fontSize="small"
                            className="clickable action-icon"
                            onClick={() => {
                              report.state = true;
                              report.index = key;
                              downloadClientReport(report, 'excel');
                            }}
                          />
                        )}

                        {loadingMail && report.state ? (
                          <Loading size="15px" />
                        ) : (
                          <MailIcon
                            fontSize="small"
                            className="clickable action-icon"
                            onClick={() => {
                              report.state = true;
                              report.index = key;
                              sendReportToClientEmail(report);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}

export function ContributorsTable(props) {
  const [loadingMail, setLoadingMail] = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(0);
  const [loadingPdf, setLoadingPdf] = useState(0);



  function sendReportToContributorEmail(report) {
    setLoadingMail(1);
    let newReport = report;
    if (props.hasRole(['REPORTS_FULL_VIEW'])) {
      ORBApi.downloadReportPdf(newReport, 'pdf', 1)
        .then(() => toast.success(props.messages.report_send))
        .catch(() => toast.error(props.messages.report_send_error))
        .finally(() => {
          props.contributors[report.index].state = false;
          setLoadingMail(0);
        });
    } else {
      if (props.hasRole(['REPORTS_ARTIST_VIEW'])) {
        newReport.contributor_id = null;
      }
      ORBApi.downloadReportPdf(newReport, 'pdf', 1)
        .then(() => toast.success(props.messages.report_send))
        .catch(() => toast.error(props.messages.report_send_error))
        .finally(() => {
          props.contributors[report.index].state = false;
          setLoadingMail(0);
        });
    }
  }

  function downloadContributorReport(report, download_type) {
    if (download_type == 'pdf') {
      setLoadingPdf(1);
      let newReport = report;
      if (props.hasRole(['REPORTS_FULL_VIEW'])) {
        ORBApi.downloadReportPdf(newReport, download_type)
          .then((data) => {
            downloadFile(data, download_type);
          })
          .finally(() => {
            setLoadingPdf(0);

            props.contributors[report.index].state = false;
          });
      } else {
        if (props.hasRole(['REPORTS_ARTIST_VIEW'])) {
          newReport.contributor_id = null;
        }
        ORBApi.downloadReportPdf(newReport, download_type)
          .then((response) => {
            downloadFile(response, download_type);
          })
          .finally(() => {
            setLoadingPdf(0);

            props.contributors[report.index].state = false;
          });
      }
    } else {
      setLoadingExcel(1);
      let newReport = report;
      if (props.hasRole(['REPORTS_FULL_VIEW'])) {
        ORBApi.downloadReportExcel(newReport, download_type)
          .then((response) => {
            downloadFile(response, download_type);
          })
          .finally(() => {
            setLoadingExcel(0);

            props.contributors[report.index].state = false;
          });
      } else if (
        props.hasRole(['REPORTS_CLIENT_VIEW', 'REPORTS_ARTIST_VIEW', 'REPORTS_AGENT_VIEW'])
      ) {
        ORBApi.downloadReportExcel(newReport, download_type)
          .then((data) => {
            downloadFile(data, download_type);
          })
          .finally(() => {
            setLoadingExcel(0);

            props.contributors[report.index].state = false;
          });
      }
    }
  }

  function downloadFile(response, download_type) {
    const file = new Blob([response.data], {
      type: `application/${download_type}`,
    });

    let fileName =
      response.headers['content-disposition'].split('filename=')[1];
    fileName = fileName.replaceAll('"', '');
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  return (
    <>


      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {props.headers_table.map((title, key) => {
                return (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ color: '#F5F5F5' }}
                  >
                    {title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.contributors.length > 0
              ? props.contributors.map((contributor, key) => {
                  return (
                    <TableRow className="row">
                      <TableCell
                        className="clickable"
                        align="center"
                        onClick={() => props.tableHandler(contributor)}
                      >
                        {moment(contributor.reference_date).format('MM/YYYY')}
                      </TableCell>
                      <TableCell
                        className="clickable"
                        align="center"
                        onClick={() => props.tableHandler(contributor)}
                      >
                        {contributor.contributor}
                      </TableCell>
                      <TableCell
                        className="clickable"
                        align="center"
                        onClick={() => props.tableHandler(contributor)}
                      >
                        {props.formatCurrency(
                          contributor.total,
                          contributor.currency,
                        )}
                      </TableCell>
                      <TableCell
                        className="clickable"
                        align="center"
                        onClick={() => props.tableHandler(contributor)}
                      >
                        {contributor.client}
                      </TableCell>
                      <TableCell
                        className="clickable"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        {loadingExcel && contributor.state ? (
                          <Loading size="15px" />
                        ) : (
                          <ExcelIcon
                            fontSize="small"
                            className="clickable action-icon"
                            onClick={() => {
                              contributor.state = true;
                              contributor.index = key;
                              downloadContributorReport(
                                contributor,
                                'excel',
                                'xls',
                              );
                            }}
                          />
                        )}
                        {loadingPdf && contributor.state ? (
                          <Loading size="15px" />
                        ) : (
                          <img
                            src={PDFLogo}
                            height="25px"
                            alt="PDF logo"
                            className="clickable action-icon"
                            onClick={() => {
                              contributor.state = true;
                              contributor.index = key;
                              downloadContributorReport(
                                contributor,
                                'pdf',
                                'pdf',
                              );
                            }}
                          />
                        )}
                        {loadingMail && contributor.state ? (
                          <Loading size="15px" />
                        ) : (
                          <MailIcon
                            fontSize="small"
                            className="clickable action-icon"
                            onClick={() => {
                              contributor.state = true;
                              contributor.index = key;
                              sendReportToContributorEmail(contributor);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}
