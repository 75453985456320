import React, { useState, useContext } from 'react';
import '../../../../utils/date';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';
import { Modal, TextField, Grid } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import ContributorsForm from './ContributorsForm';
import SessionContext from '../../../../contexts/SessionContext';

export const defaultFilters = {
  term: '',
};

const ActionBar = (props) => {
  const [showFilters, showHideFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  let { hasRole } = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onTermChangeHandler = (term) => {
    updateFilters({ ...filters, term: term });
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {hasRole(['CONTRIBUTORS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              props.setFormVisible(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}

        <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
          <h5>
            {props.total_holders}: {props.total_items}
          </h5>
        </div>
        <div>
          <Modal
            size="lg"
            open={props.formVisible}
            onClose={() => props.setFormVisible(!props.formVisible)}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeogut: 500 }}
          >
            <div className="modal-container">
              <ContributorsForm
                form_texts={props.form_texts}
                setFormVisible={props.setFormVisible}
                onSaveContributor={props.onSaveContributor}
              />
            </div>
          </Modal>
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <DebouncedTextField
            label={props.input_filter}
            onChange={(value) => {
              onTermChangeHandler(value);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
