import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';

import ActionBar, { defaultFilters } from './components/ActionBar';
import Backdrop from '@mui/material/Backdrop';
import WorksTable from './components/WorksTable';
import WorksForm from './components/WorksForm';
import { Modal } from '@material-ui/core';
import Loading from '../../../components/common/Loading';
import DialogWindow from './components/DialogWindow';
import RegisterContributors from './components/RegisterContributors';
import { toast } from 'react-toastify';
import SessionContext, { SystemRoles } from '../../../contexts/SessionContext';
import { getTranslatedTexts } from '../../../utils/TranslationUtils';

const Works = () => {
  const [worksData, setWorksData] = useState();
  const [fileProcessing, setFileProcessing] = useState(0);
  const [loading, setLoading] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [workOnPlay, setWorkOnPlay] = useState(null); // Work  been visible on WorkForm
  const [formVisible, setFormVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [deletingWork, setDeletingWork] = useState(0)
  const [visible, setVisible] = useState(false);
  const [contributorsData, setContributorsData] = useState();
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);
  /** Useful function to send to components in order to show the category names */

  const translateCategory = (cat) => {
    switch (cat) {
      case 'PF':
        return 'Produtor Fonográfico';
      case 'I':
        return 'Intérprete';
      case 'MA':
        return 'Músico acompanhante';
      case 'CV':
        return 'Coro/Voz';
      case 'EMP':
        return 'Empresário';
      case 'A':
        return 'Autor';
      case 'E':
        return 'Editora';
      case 'CA':
        return 'Co Autor';
      case 'CE':
        return 'Co Editora';
      default:
        return cat;
    }
  };

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };
  const onPageChangeHandler = (page) => {
    if (page !== worksData.current_page) loadData(page);
  };

  const getFileToUpload = () => {
    document.getElementById('real-file').click();
  };

  const uploadFile = () => {
    setFileProcessing(1);
    let formData = new FormData();
    let worksFile = document.getElementById('real-file').files[0];
    formData.append('works_file', worksFile);
    ORBApi.postWorksFile(formData, 'multipart/form-data')
      .then(() => toast.success(texts.messages.succes_upload))
      .catch((err) => uploadErrorHandler(err))
      .finally(() => {
        setFileProcessing(0);
        loadData(filters.page);
      });
  };

  const uploadErrorHandler = (err) => {
    if (err.response.status === 400) {
      if (err.response.data.publisher !== undefined) {
        toast.error(texts.messages.not_found);
      } else if (err.response.data.work !== undefined) {
        toast.error(texts.messages.invalid_configuration);
      }
      if (err.response.data.error === 'CONTRIBUTOR_NOT_FOUND') {
        setContributorsData(
          err.response.data.contributors.map((item, index) => {
            return { ...item, id: index };
          }),
        );
        setDialogVisible(true);
      } else {
        toast.error(texts.message.invalid_parameters);
      }
    } else {
      toast.error(texts.message.default);
    }
  };

  
  const deleteWork = (work_id) => {
    setDeletingWork(1)
    ORBApi.deleteWork(work_id)
      .then((data) => {

      })
      .catch(() => {

      })
      .finally(() => { 
        setDeletingWork(0)
        loadData(worksData.current_page)
      })
  }

  const downloadWorks = () => {
    setFileProcessing(1);
    ORBApi.downloadWorks(filters)
      .then((data) => {
        const file = new Blob([data], { type: `application/excel` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `ORB-works.xls`;
        link.click();
      })
      .then(() => toast.success(texts.messages.success_download))
      .catch(() => toast.error('Erro!'))
      .finally(() => setFileProcessing(0));
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadWorks(page, filters)
      .then((data) => {
        setWorksData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveWork = (work) => {
    setFormVisible(false);
    loadData(filters.page);
  };

  const showWorkForm = (work) => {
    if (hasRole(['WORKS_WRITE'])) {
      if (work) setWorkOnPlay(JSON.parse(JSON.stringify(work)));
      // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
      else setWorkOnPlay(null);
      setFormVisible(true);
    } else {
      return true;
    }
  };
  useEffect(() => {
    loadData(1);
  }, [filters]);

  useEffect(() => {
    const translatedTexts = getTranslatedTexts('works', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <>
      <div id="register-works-page">
        <ORBNavBar page_title={texts?.title_works} />

        {fileProcessing ? <Loading className="file-loading-container" /> : null}

        <input
          type="file"
          id="real-file"
          hidden="hidden"
          onChange={uploadFile}
        />

        <ActionBar
          input_filters={texts ? texts.input_filters : {}}
          total_works={texts ? texts.total_works : ''}
          formVisible={formVisible}
          onAddClick={showWorkForm}
          onUploadClick={getFileToUpload}
          onDownloadClick={downloadWorks}
          onSaveWork={onSaveWork}
          worksData={worksData}
          translateCategory={translateCategory}
          total_items={worksData?.total_items}
          onFilterChange={onFilterChangeHandler}
        />
        {!worksData ? (
          loading ? (
            <Loading
              className="works-loading-container"
              text={texts?.loading}
            />
          ) : (
            <WorksTable headers_table={texts ? texts.headers_table : []} />
          )
        ) : loading ? (
          <Loading className="works-loading-container" text={texts?.loading} />
        ) : (
          <WorksTable
            headers_table={texts ? texts.headers_table : []}
            split_headers={texts ? texts.split_headers : []}
            translateCategory={translateCategory}
            works={worksData.works}
            total_items={worksData.total_items}
            pages={worksData.pages}
            currentPage={worksData.current_page}
            onPageChange={onPageChangeHandler}
            showWorkForm={showWorkForm}
            deleteWork={deleteWork}
            deletingWork={deletingWork}
          />
        )}

        <Modal
          size="lg"
          open={formVisible}
          onClose={() => setFormVisible(!formVisible)}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <WorksForm
              messages={texts ? texts.messages : {}}
              form_texts={texts ? texts.form_texts : {}}
              edit_work={workOnPlay}
              worksData={worksData}
              onSaveWork={onSaveWork}
            />
          </div>
        </Modal>
        <Modal
          style={{ maxWidth: '50%', margin: '0 auto' }}
          open={dialogVisible}
          onClose={() => setDialogVisible(!dialogVisible)}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <DialogWindow
              dialog_texts={texts ? texts.dialog_window : {}}
              setDialogVisible={setDialogVisible}
              setVisible={setVisible}
            />
          </div>
        </Modal>
        <Modal
          open={visible}
          onClose={() => setVisible(!visible)}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <RegisterContributors
              contributorsData={contributorsData}
              setContributorsData={setContributorsData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Works;
