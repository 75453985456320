import React from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { TextField } from '@material-ui/core';

export default function DebouncedTextField(props) {
  // props: (label, onChange)

  const debounced = useDebouncedCallback((value) => {
    props.onChange(value);
  }, 500);

  return (
    <TextField
      fullWidth
      variant="outlined"
      label={props.label}
      onChange={(e) => debounced.callback(e.target.value)}
    />
  );
}
