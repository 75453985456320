Date.prototype.toMonthFormat = function() {
	let month = `${this.getMonth() +1}`.padStart(2,'0');
	let year = this.getFullYear();
	return `${year}-${month}`;
}

Date.prototype.addMonths = function(amount) {
	var date = new Date(this.valueOf());
	date.setMonth(date.getMonth()+amount);
	return date;
}
