import React, { useEffect, useState } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/common/Loading';
import { Modal } from '@material-ui/core';
import ActionBar, { defaultFilters } from './components/ActionBar';
import AgentsTable from './components/AgentsTable';
import AgentsForm from './components/AgentsForm';
import DeleteConfirmPanel from './components/DeleteConfirmPanel';

const Agents = () => {
  const [data, setData] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [agentOnPlay, setAgentOnPlay] = useState(null);
  const [loading, setLoading] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentId, setAgentId] = useState(null);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showAgentForm = (agent) => {
    if (agent) setAgentOnPlay(JSON.parse(JSON.stringify(agent)));
    else setAgentOnPlay(null);
    setFormVisible(true);
  };

  const onSaveAgent = () => {
    onClose();
    loadData(1);
  };

  const onDeleteAgent = (id, onClose) => {
    console.log(id);
    console.log(onClose);
    if (onClose) {
      setShowDeleteModal(false);
    } else {
      setShowDeleteModal(true);
      setAgentId(id);
    }
  };

  const onClose = () => {
    setFormVisible(false);
    setAgentOnPlay(null);
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadAgents(page, filters, 8)
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <>
      <div id="register-agents-page">
        <ORBNavBar page_title="Cadastro > Agentes" />
        <ActionBar
          formVisible={formVisible}
          setFormVisible={setFormVisible}
          onFilterChange={onFilterChangeHandler}
          total_items={data?.total_items}
          onSaveAgent={onSaveAgent}
        />

        {!data ? (
          loading ? (
            <Loading
              className="agents-loading-container"
              text="Buscando Agentes..."
            />
          ) : (
            <AgentsTable agents={[]} />
          )
        ) : loading ? (
          <Loading
            className="agents-loading-container"
            text="Buscando Agentes..."
          />
        ) : (
          <>
            <AgentsTable
              agents={data.agents}
              total_items={data.total_items}
              page={data.page}
              pages={data.pages}
              current_page={data.current_page}
              onPageChange={onPageChangeHandler}
              onDeleteAgent={onDeleteAgent}
              showAgentForm={showAgentForm}
            />
          </>
        )}
        <Modal
          size="lg"
          open={formVisible}
          onClose={onClose}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="modal-container">
            <AgentsForm edit_agents={agentOnPlay} onSaveAgent={onSaveAgent} />
          </div>
        </Modal>
        <Modal
          size="lg"
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="modal-container">
            <DeleteConfirmPanel
              id={agentId}
              onDelete={onDeleteAgent}
              onLoad={loadData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Agents;
