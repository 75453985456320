import React, { useEffect, useState, useContext } from 'react';
import ORBNavBar from '../../components/common/ORBNavBar';
import Loading from '../../components/common/Loading';
import ActionBar, { defaultFilters } from './components/ActionBar';
import { toast } from 'react-toastify';
import SuggestContributors from '../../components/common/SuggestContributors';
import { useHistory } from 'react-router';
import config from '../../config';
import ORBApi from '../../services/ORBApi';
import { ContributorsTable, ClientsTable } from './components/ReportsTable';
import SessionContext, { SystemRoles } from '../../contexts/SessionContext';
import { getTranslatedTexts } from '../../utils/TranslationUtils';
import './style.css';
import { formatCurrency } from '../../utils/CurrencyUtils';
toast.configure();

const Reports = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingContributors, setLoadingContributors] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [contributorsFilters, setContributorsFilters] = useState({contributor_name:'', contributor_id:'', month:'' , year: ''});
  const [selectedDistribution, setSelectedDistribution] = useState({})
  const [contributorSearchName, setContributorSearchName] = useState('')
  const [clientsData, setClientsData] = useState({
    current_page: 0,
    pages: 0,
    reports: [],
    total_items: 0,
  });
  const [contributorsData, setContributorsData] = useState({
    current_page: 0,
    pages: 0,
    distributions: [],
    total_items: 0,
  });
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  const clientsFiltersChangeHandler = (newFilters) => {
    setFilters(newFilters);
  };

  const contributorsFiltersChangeHandler = (newFilters) => {
    setContributorsFilters(newFilters);
  };

  const onClientsPageChangeHandler = (page) => {
    if (page !== clientsData.current_page) {

    setFilters({...filters, page:page})    
    }
  };

  const onContributorsPageChangeHandler = (page) => {
    if (page !== contributorsData.current_page) loadContributors(page);
  };


  const loadReports = () => {
    setLoading(true);
    ORBApi.loadReportsDistribution({...filters})
      .then((response) => {
        let data = response;
        data.reports.map((item) => {
          item.state = false;
          item.active = false;
        });
        setClientsData(data);
        setSelectedDistribution({})
        setContributorsData({
          current_page: 0,
          pages: 0,
          distributions: [],
          total_items: 0,
        })
      })
      .catch(() => toast.error('Erro!'))
      .finally(() => setLoading(false));
  };

  const loadContributors = (page) => {
    setLoadingContributors(true);
    if (selectedDistribution.client_id !== null) {
      ORBApi.loadContributorsDistribution(page, {
        ...selectedDistribution,
        ...contributorsFilters
      })
        .then((response) => {
          let data = response;
          data.distributions.map((item) => {
            item.state = false;
          });
          setContributorsData(data);

        })
        .finally(() => {
          setLoadingContributors(false);
        });
    } else {
      ORBApi.loadIndependentContributorsDistribution(page, {
        ...selectedDistribution,
        contributor_id: contributorsFilters.contributor_id,
      })
        .then((response) => {
          let data = response;
          data.distributions.map((item) => {
            item.state = false;
          });
          setContributorsData(data);
        })
        .finally(() => {
          setLoadingContributors(false);
        });
    }
  };

  const onClientsTableClickHandler = (row) => {
    // if the clicked row is an active row, remove selection and clean contributor table
    if (clientsData.reports[row.index].active) {
      clientsData.reports[row.index].active = false;
      setContributorsData({
        current_page: 0,
        pages: 0,
        total_items: 0,
        distributions: [],
      });
      setSelectedDistribution({});
      loadReports(clientsData.current_page);
      setContributorsFilters({})
    } else {
      // remove the selection from any other row, but the clicked
      clientsData.reports.map((item) => {
        if (item.index !== row.index) item.active = false;
        else item.active = true;
      });
      setContributorsFilters({contributor_name:contributorSearchName, contributor_id:filters.contributor_id})
      setSelectedDistribution({
        distribution_id: row.distribution_id,
        client_id: row.client_id,
      });
    }
  };

  const getReportGraphs = async ({
    distribution_id,
    client_id,
    contributor_id,
  }) => {
    window.open(
      `${config.site_base_url}/graphs?distribution_id=${distribution_id}&client_id=${client_id}&contributor_id=${contributor_id}`,
      '_blank',
    );
  };

  // only load distributions for clients and orb team
  useEffect(() => {
    if (hasRole(['REPORTS_FULL_VIEW', 'REPORTS_CLIENT_VIEW'])) {

      loadReports()

    }
  }, [filters]);


  useEffect(() => {
    // Only load the contributors table if the user is a contributor of if there is a distribution selected

    if (
      hasRole(['REPORTS_ARTIST_VIEW', 'REPORTS_AGENT_VIEW']) ||
      selectedDistribution.distribution_id
    ) {
      loadContributors();
    }
  }, [selectedDistribution, contributorsFilters ]);



  useEffect(() => {
    const translatedTexts = getTranslatedTexts('reports', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);


  return (
    <div>
      <ORBNavBar
        page_title={texts ? texts.title : ''}
        selectedOption="drop_reports"
      />
      <ActionBar
        contributor_name={contributorSearchName}
        input_filters={texts ? texts.input_filters : ''}
        clientsFiltersChangeHandler={clientsFiltersChangeHandler}
        contributorsFiltersChangeHandler={contributorsFiltersChangeHandler}
        onContributorSearchChange={setContributorSearchName}
      />

      {hasRole(['REPORTS_FULL_VIEW', 'REPORTS_CLIENT_VIEW']) ? (
        loading ? (
          <Loading text="Carregando Relatórios" size="25px" />
        ) : clientsData.reports.length > 0 ? (
          <ClientsTable
            formatCurrency={formatCurrency}
            hasRole={hasRole}
            headers_table={texts ? texts.clients_table : []}
            messages={texts ? texts.messages : {}}
            reports={clientsData.reports}
            currency={clientsData.currency}
            total_items={clientsData.total_items}
            page={clientsData.page}
            pages={clientsData.pages}
            current_page={clientsData.current_page}
            onPageChange={onClientsPageChangeHandler}
            onRowClick={onClientsTableClickHandler}
          />
        ) : (
          <ClientsTable
            headers_table={texts ? texts.clients_table : []}
            reports={[]}
          />
        )
      ) : null}


{/* CONTRIBUTORS TABLE  */}
      {
      contributorsData.distributions.length  > 0 || selectedDistribution.distribution_id? (
        hasRole(['REPORTS_FULL_VIEW', 'REPORTS_CLIENT_VIEW']) && selectedDistribution.distribution_id? 
        (
          
          <div className="page-filter-bar">
            <SuggestContributors
            value={contributorsFilters?.contributor_name}
            useToFilter={true}
            onChange={(contributor) => {
            setContributorsFilters({...contributorsFilters, contributor_id: contributor.id, contributor_name: contributor.name})
            }}
          />
          </div>
        ) : null) 
        : null    
      }

      {contributorsData.distributions.length > 0 ? (
        !loadingContributors ? (
          <ContributorsTable
            contributor_name={contributorsFilters.contributor_name}
            onContributorChangeHandler={contributorsFiltersChangeHandler}
            hasRole={hasRole}
            formatCurrency={formatCurrency}
            headers_table={texts ? texts.contributors_table : []}
            messages={texts ? texts.messages : {}}
            contributors={contributorsData.distributions}
            currency={contributorsData.currency}
            total_items={contributorsData.total_items}
            pages={contributorsData.pages}
            tableHandler={getReportGraphs}
            current_page={contributorsData.current_page}
            onPageChange={onContributorsPageChangeHandler}
            onRowClick={onClientsTableClickHandler}
          />
        ) : (
          <Loading text="Carregando Relatórios" size="25px" />
        )
      ) :selectedDistribution.distribution_id ? (
        <ContributorsTable
          contributors={contributorsData.distributions}
          headers_table={texts ? texts.contributors_table : []}
        />
      ) : hasRole(["REPORTS_ARTIST_VIEW", "REPORTS_AGENT_VIEW"]) ?  
        <ContributorsTable
        contributors={contributorsData.distributions}
        headers_table={texts ? texts.contributors_table : []}
      /> : null}
    </div>
  );
};

export default Reports;
