import React, { useState } from 'react';
import ORBApi from '../services/ORBApi';

class SystemRoles {
  static ARTIST = 'ORB::ARTIST';
  static LABEL = 'ORB::LABEL';
  static PUBLISHER = 'ORB::PUBLISHER';
  static ADMIN = 'ORB::TEAM';
  static CLIENT = 'ORB::CLIENT';
}

const SessionContext = React.createContext();

const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState(null);
  // Setup the client session
  const setupSession = async () => {
    return ORBApi.getSession()
      .then((data) => {
        setSessionData(data);
        let initialPage = _checkRole(data['roles'], ['DASHBOARD_WORKS'])
          ? '/dashboard/Copyright'
          : '/dashboard/Neighboring_Rights';
        return initialPage;
      })
      .catch(() => {
        return null;
      });
  };

  // Verifies if the user has any of the informed roles
  // param: roles : Array of roles
  const hasRole = (roles) => {
    return _checkRole(sessionData['roles'], roles);
  };

  const _checkRole = (roleSet, checkingRole) => {
    if (checkingRole.length === 0) return true;
    for (let i = 0; i < checkingRole.length; i++) {
      let found = roleSet.find((item) => item === checkingRole[i]);
      if (found) return true;
    }
    return false;
  };

  return (
    <SessionContext.Provider
      value={{
        session_data: sessionData,
        setupSession,
        hasRole,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
export { SessionProvider, SystemRoles };
