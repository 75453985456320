import React, { useEffect, useState } from 'react';
import ORBApi from '../../services/ORBApi';
import { Select, InputLabel, FormControl, FormHelperText } from '@material-ui/core';


export default function LabelsDropDown({
  value,
  onChange,
  className,
  hideEmpty,
  label,
  margin,
  onLoaded,
  error
}) {
  const [labels, setLabels] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    //Load the labels when the component mount
    ORBApi.loadLabels(1, null, 0).then((response) => {
      setLabels(response.labels);
      if (onLoaded && response.labels.length > 0) onLoaded(response.labels[0]);
    });
  }, []);

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    onChange({ id: e.target.value, name: e.target.selectedOptions[0].text });
  };
  return (
    <FormControl
      fullWidth
      error={error}
      margin={margin}
      variant="outlined"
      className={className}
    >
      <InputLabel shrink={selected > 0}>{label}</InputLabel>
      <Select
        native
        onChange={onChangeHandler}
        value={selected}
        notched={selected > 0}
      >
        {!hideEmpty ? <option value="" /> : null}
        {labels.map((label) => {
          return (
            <option key={label.id} value={label.id}>
              {label.name}
            </option>
          );
        })}
        ;
      </Select>
      {error && !value && <FormHelperText>Campo obrigatório</FormHelperText>}
    </FormControl>
  );
}
