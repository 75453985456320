import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import './style.css';

const TopPlatforms = ({ top_platforms, title }) => {
  const MAX_COLORS = 5;
  const colors = [
    '#d0315e',
    '#F66E7F',
    '#FAB293',
    '#A04377',
    '#FBEAE4',
    '#FAB293',
  ];

  const sortedPlatforms = top_platforms.sort((a, b) => b.share - a.share);
  const displayedPlatforms = sortedPlatforms.slice(0, MAX_COLORS);

  // Função para aumentar a intensidade da cor (efeito de hover)
  function intensifyColor(color, amount) {
    const colorNum = parseInt(color.slice(1), 16);
    const r = Math.min(((colorNum >> 16) & 0xff) + amount, 255);
    const g = Math.min(((colorNum >> 8) & 0xff) + amount, 255);
    const b = Math.min((colorNum & 0xff) + amount, 255);
    const newColorNum = (b | (g << 8) | (r << 16)) >>> 0;
    return '#' + newColorNum.toString(16).padStart(6, '0');
  }
  const data = {
    labels: displayedPlatforms.map((item) => item.platform),
    datasets: [
      {
        data: displayedPlatforms.map((item) => item.share),
        backgroundColor: colors.slice(0, displayedPlatforms.length),
        hoverBorderColor: colors
          .slice(0, displayedPlatforms.length)
          .map((color) => intensifyColor(color, 20)),
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const percentage = dataset.data[tooltipItem.index].toFixed(1);
          return `${data.labels[tooltipItem.index]}: ${percentage}%`;
        },
      },
    },
  };

  return (
    <>
      <h5 className="title" align="left">
        {title}
      </h5>
      <Box m={2} />

      <Pie data={data} options={options} />
    </>
  );
};

export default TopPlatforms;
