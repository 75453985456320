import React, { useState, useEffect } from 'react';
import ExecutionsTracksTable from './ExecutionsTracksTable';
import ExecutionsWorksTable from './ExecutionsWorksTable';
import AlbumIcon from '@material-ui/icons/Album';
import MenuBookIcon from '@material-ui/icons/MenuBook';

function TabNavigation({
  work,
  track,
  loadWorkExecutions,
  loadTrackExecutions,
  currency,
  texts,
}) {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    setActiveTab(track.executions.length > 0 ? 'conexo' : 'autoral');
  }, [track, work]);

  return (
    <>
      <div className="tab-container">
        <div className="tab-buttons">
          {track.executions.length > 0 ? (
            <button
              className={`tab-button ${
                activeTab === 'conexo' ? 'tab-button-active' : ''
              }`}
              onClick={() => setActiveTab('conexo')}
            >
              <AlbumIcon />
              {texts.tab_buttons.conexo}
            </button>
          ) : null}
          {work.executions.length > 0 ? (
            <button
              className={`tab-button ${
                activeTab === 'autoral' ? 'tab-button-active' : ''
              }`}
              onClick={() => setActiveTab('autoral')}
            >
              <MenuBookIcon />
              {texts.tab_buttons.autoral}
            </button>
          ) : null}
        </div>
        <div>
          {activeTab === 'autoral' ? (
            <ExecutionsWorksTable
              headers={texts ? texts.headers_table_autoral : []}
              data={work}
              dataLoader={loadWorkExecutions}
              currency={currency}
            />
          ) : (
            <ExecutionsTracksTable
              headers={texts ? texts.headers_table_conexo : []}
              data={track}
              dataLoader={loadTrackExecutions}
              currency={currency}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TabNavigation;
