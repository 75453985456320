import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBApi from '../../services/ORBApi';
import { getTranslatedTexts } from '../../utils/TranslationUtils';
import ORBNavBar from '../../components/common/ORBNavBar';
import { DistributionsTable } from './components/Tables';
import { NewDistributionPanel } from './components/Panels';
import ActionBar, { defaultFilters } from './components/ActionBar';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../components/common/Loading';
import SessionContext from '../../contexts/SessionContext';

export default function DistributionsTracks() {
  const [data, setData] = useState({
    pages: 0,
    current_page: 0,
    total_items: 0,
    distributions: [],
  });
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [distributionOnPlay, setDistributionOnPlay] = useState(null);
  const {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);

  const [texts, setTexts] = useState(null);

  const onFilterChange = (newFilters) => {
    setFilters({
      name: newFilters.name,
      status: newFilters.status,
      reference_date: newFilters.reference_date,
    });
  };

  const loadDistributions = (page) => {
    setLoading(1);
    ORBApi.loadDistributions(page, filters)
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(0));
  };

  const onDistributionsTableClick = (distribution) => {
    setDistributionOnPlay(distribution);
    setShowAddForm(true);
  };

  const onModalClose = () => {
    setShowAddForm(false);
    setDistributionOnPlay(null);
  };

  const onSaveHandler = () => {
    onModalClose();
    loadDistributions(data.current_page);
  };

  useEffect(() => {
    loadDistributions(1);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    const translatedTexts = getTranslatedTexts(
      'distributions',
      language_preference,
    );
    setTexts(translatedTexts);
    setLoading(false);
  }, [language_preference]);

  return (
    <>
      <ORBNavBar
        page_title={texts?.title || ''}
        selectedOption="drop_distributions"
      />
      <ActionBar
        setFormVisible={setShowAddForm}
        total_items={data?.total_items}
        onFilterChange={onFilterChange}
      />

      {loading ? (
        <Loading
          className="labels-loading-container"
          text="Buscando Distribuições..."
        />
      ) : (
        <DistributionsTable
          hasRole={hasRole}
          data={data}
          messages={texts ? texts.messages : {}}
          headers_table={texts ? texts.headers_table : []}
          approve_distribution={texts ? texts.approve_distribution : {}}
          onPageChange={loadDistributions}
          onClick={onDistributionsTableClick}
        />
      )}

      {/* New distribution modal form */}
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        open={showAddForm}
        onClose={onModalClose}
      >
        <NewDistributionPanel
          messages={texts ? texts.messages : {}}
          texts={texts}
          onSave={onSaveHandler}
          distribution={distributionOnPlay}
        />
      </Modal>
    </>
  );
}
