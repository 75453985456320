import React, { useState, useContext, useEffect } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ActionBar, { defaultFilters } from '../components/ActionBar';
import GraphsPanel from './components/GraphsPanel';
import SessionContext, { SystemRoles } from '../../../contexts/SessionContext';
import { getTranslatedTexts } from '../../../utils/TranslationUtils';
import Loading from '../../../components/common/Loading';

export default function TracksDashboard() {
  const [filters, setFilters] = useState(defaultFilters);
  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);
  const [currency, setCurrency] = useState(null);

  function getCurrencyCode(language) {
    if (language === 'PT') {
      return 'BRL';
    } else if (language === 'EN') {
      return 'USD';
    } else {
      return '';
    }
  }

  useEffect(() => {
    const translatedTexts = getTranslatedTexts(
      'dashboards',
      language_preference,
    );
    setTexts(translatedTexts);
    setCurrency(getCurrencyCode(language_preference));
  }, [language_preference]);

  return (
    <div id="dashboard-tracks-page">
      <ORBNavBar
        page_title={texts ? texts.title_conexo : ''}
        selectedOption="drop_dashboard"
      />

      {hasRole(['DASHBOARD_TRACKS_READ']) ? (
        <>
          <ActionBar
            hasRole={hasRole}
            texts={texts ? texts.inputs : {}}
            onFilterChange={onFilterChangeHandler}
            showLabels={true}
          />
          {currency ? (
            <GraphsPanel
              hasRole={hasRole}
              language={language_preference}
              currency={currency ? currency : ''}
              texts={texts ? texts : {}}
              filters={filters}
            />
          ) : (
            <Loading text={texts?.loading} />
          )}
        </>
      ) : null}
    </div>
  );
}
