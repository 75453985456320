import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@material-ui/lab/Pagination';

const AgentsTable = (props) => {
  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {['Nome', 'Email', ''].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.agents.length > 0
              ? props.agents.map((agent) => {
                  return (
                    <TableRow className="row" key={agent.id}>
                      <TableCell
                        className=" clickable"
                        align="center"
                        onClick={() => props.showAgentForm(agent)}
                      >
                        {agent.name}
                      </TableCell>

                      <TableCell
                        className=" clickable"
                        align="center"
                        onClick={() => props.showAgentForm(agent)}
                      >
                        {agent.email}{' '}
                      </TableCell>

                      <TableCell>
                        <Tooltip title="Remover">
                          <DeleteIcon
                            className="clickable"
                            onClick={() => props.onDeleteAgent(agent.id)}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default AgentsTable;
