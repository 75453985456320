import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import './style.css';

const CurrencysDropdown = ({ value, onChange }) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onChangeHandler = (e) => {
    const selectedValue = e.target.value;
    setSelected(selectedValue);
    onChange(selectedValue);
  };

  return (
    <FormControl
      fullWidth
      className="currencys-dropdown"
      style={{ width: '100%' }}
      required
    >
      <InputLabel style={{ paddingLeft: '10px' }} shrink={selected !== ''}>
        Currency
      </InputLabel>
      <Select
        value={selected}
        onChange={onChangeHandler}
        variant="outlined"
        notched={selected !== ''}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem value=""> </MenuItem>
        <MenuItem value="USD">USD</MenuItem>
        <MenuItem value="EUR">EUR</MenuItem>
        <MenuItem value="BRL">BRL</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CurrencysDropdown;
