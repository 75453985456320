import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Loading from '../../../components/common/Loading';
import { toast } from 'react-toastify';
import { Modal } from '@material-ui/core';
import ActionBar, { defaultFilters } from './components/ActionBar';
import TracksTable from './components/TracksTable';
import TrackForm from './components/TrackForm';
import Backdrop from '@mui/material/Backdrop';
import { getTranslatedTexts } from '../../../utils/TranslationUtils';
import DialogWindow from './components/DialogWindow';
import RegisterContributors from './components/RegisterContributors';
import SessionContext, { SystemRoles } from '../../../contexts/SessionContext';

export default function Phonograms() {
  const [filters, setFilters] = useState(defaultFilters);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(0);
  const [fileProcessing, setFileProcessing] = useState(0);
  const [deletingTrack, setDeletingTrack] = useState(0)
  const [trackFormVisible, setTrackFormVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [trackOnPlay, setTrackOnPlay] = useState(null); // track  been visible on TrackForm
  const [contributorsData, setContributorsData] = useState();

  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  /** Useful function to send to components in order to show the category names */
  const translateCategory = (cat) => {
    switch (cat) {
      case 'PF':
        return 'Produtor Fonográfico';
      case 'I':
        return 'Intérprete';
      case 'MA':
        return 'Músico acompanhante';
      case 'CV':
        return 'Coro/Voz';
      case 'EMP':
        return 'Empresário';
      case 'A':
        return 'Autor';
      case 'E':
        return 'Editora';
      case 'CA':
        return 'Co Autor';
      case 'CE':
        return 'Co Editora';
      default:
        return cat;
    }
  };

  const onFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, page: page });

  };

  const doSearch = () => {
    // setData({ ...data, tracks: [] });
    setLoading(1);
    ORBApi.loadTracks(
      filters.page,
      filters.label_id,
      filters.search_term,
      filters.contributor_id,
    )
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const showTrackForm = (track) => {
    if (hasRole(['TRACKS_WRITE'])) {
      if (track) setTrackOnPlay(JSON.parse(JSON.stringify(track)));
      // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
      else setTrackOnPlay(null);
      setTrackFormVisible(true);
    } else {
      return true;
    }
  };

  const onSaveTrack = (track) => {
    setTrackFormVisible(false);
    doSearch(
      filters.page,
      filters.label_id,
      filters.search_term,
      filters.contributor,
    );
  };

  const getFileToUpload = () => {
    document.getElementById('real-file').click();
  };
  const uploadFile = () => {
    setFileProcessing(1);
    let formData = new FormData();
    let tracksFile = document.getElementById('real-file').files[0];
    formData.append('tracks_file', tracksFile);
    ORBApi.postTracks(formData, 'multipart/form-data')
      .then(() => toast.success(texts.messages.success_upload))
      .catch((err) => uploadErrorHandler(err))
      .finally(() => {
        setFileProcessing(0);
      });
  };
  const uploadErrorHandler = (err) => {
    let errorMsg = texts.messages.default;
    if (err.response) {
      switch (err.response.data.error) {
        case 'SHEET_NOT_FOUND':
          errorMsg = texts.messages.sheet_not_found;
          break;
        case 'LABEL_NOT_FOUND':
          errorMsg = `${err.response.data.label} ${texts.messages.label_not_found}`;
          break;
        case 'INVALID_SPLIT':
          if (err.response.data.document !== undefined)
            errorMsg = `${texts.messages.invalid_split} ${err.response.data.document}.`;
          else
            errorMsg = `${texts.messages.invalid_configuration}, ${err.response.data.isrc}`;
          break;
        case 'CONTRIBUTOR_NOT_FOUND':
          setContributorsData(
            err.response.data.contributors.map((item, index) => {
              return { ...item, id: index };
            }),
          );
          setDialogVisible(true);
          errorMsg = texts.messages.contributor_not_found;
          break;
        default:
          errorMsg = texts.messages.default;
      }
    }
    toast.error(errorMsg);
  };

  const deleteTrack = (track_id) => {
    setDeletingTrack(1)
    ORBApi.deleteTrack(track_id)
      .then((data) => {

      })
      .catch(() => {

      })
      .finally(() => { 
        setDeletingTrack(0)
        doSearch(data.current_page)
      })
  }

  const downloadTracks = () => {
    setFileProcessing(1);
    ORBApi.downloadTracks(filters)
      .then((data) => {
        const file = new Blob([data], { type: `application/excel` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `ORB-tracks.xls`;
        link.click();
      })
      .then(() => toast.success(texts.messages.downloaded_file))
      .catch(() => toast.error(texts.messages.error))
      .finally(() => setFileProcessing(0));
  };

  useEffect(() => {
    doSearch(1);
  }, [filters]);


  useEffect(() => {
    const translatedTexts = getTranslatedTexts('tracks', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <div id="register-phonograms-page">
      <ORBNavBar
        page_title={texts?.title_tracks}
        selectedOption="drop_register"
      />
      {fileProcessing ? <Loading className="file-loading-container" /> : null}
      <input type="file" id="real-file" hidden="hidden" onChange={uploadFile} />

      <ActionBar
        input_filters={texts ? texts.input_filters : {}}
        total_tracks={texts ? texts.total_tracks : ''}
        total_items={data?.total_items}
        onFilterChange={onFilterChange}
        onAddClick={showTrackForm}
        onUploadClick={getFileToUpload}
        onDownloadClick={downloadTracks}
      />

      {!data ? (
        loading ? (
          <Loading
            className="tracks-loading-container"
            text={texts ? texts.loading : ''}
          />
        ) : (
          <TracksTable headers_table={texts ? texts.headers_table : []} />
        )
      ) : loading ? (
        <Loading
          className="tracks-loading-container"
          text={texts ? texts.loading : ''}
        />
      ) : (
        <TracksTable
          headers_table={texts ? texts.headers_table : []}
          split_headers={texts ? texts.split_headers : []}
          tracks={data.tracks}
          translateCategory={translateCategory}
          pages={data.pages}
          currentPage={data.current_page}
          onPageChange={onPageChange}
          onRowClick={showTrackForm}
          deleteTrack={deleteTrack}
          deletingTrack={deletingTrack}
        />
      )}

      <Modal
        open={trackFormVisible}
        onClose={() => setTrackFormVisible(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container">
          <TrackForm
            messages={texts ? texts.messages : {}}
            form_texts={texts ? texts.form_texts : {}}
            edit_track={trackOnPlay}
            translateCategory={translateCategory}
            onSave={onSaveTrack}
          />
        </div>
      </Modal>

      <Modal
        style={{ maxWidth: '50%', margin: '0 auto' }}
        open={dialogVisible}
        onClose={() => setDialogVisible(!dialogVisible)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div className="modal-container">
          <DialogWindow
            setDialogVisible={setDialogVisible}
            setVisible={setVisible}
          />
        </div>
      </Modal>

      <Modal
        open={visible}
        onClose={() => setVisible(!visible)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div className="modal-container">
          <RegisterContributors
            contributorsData={contributorsData}
            setContributorsData={setContributorsData}
            setVisible={setVisible}
          />
        </div>
      </Modal>
    </div>
  );
}
