import React from 'react';
import CambioTable from './CambioTable';
import logo from '../../../assets/logo.png';
import {  Grid, Typography } from '@material-ui/core';
export default function Header({ summary, header_texts }) {
  return (
    <div className="headers-container">
      <Grid container>
        <Grid
          item
          md={3}
          sm={12}
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <img
            src={logo}
            height={summary.logo ? '80em' : '100em'}
            alt="logo ORB"
          />
          {summary.logo ? (
            <img height="100em" src={summary.logo} alt="logo ORB" />
          ) : null}
        </Grid>

        <Grid item md={9} sm={12}>
          <Typography align="right" variant="h5">
            {header_texts.title}
          </Typography>
          <div className="horizontal-divider"></div>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography align="left" variant="subtitle1">
                {header_texts.client}:
                {summary.client === 'N/A'
                  ? summary.contributor
                  : summary.client}
              </Typography>
              <Typography align="left" variant="subtitle1">
                {header_texts.holder}: {summary.contributor}
              </Typography>
              <Typography align="left" variant="subtitle1">
                {header_texts.artist_name}: {summary.artist_name}
              </Typography>
              <Typography align="left" variant="subtitle1">
                {header_texts.doc}: {summary.document}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {summary.currencies.length > 0 ? (
                <CambioTable
                  data={summary.currencies}
                  headers={header_texts.cambio_table_headers}
                />
              ) : (
                null
              )}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={8}>
              <Typography
                align="left"
                variant="h6"
                style={{ marginTop: '3em' }}
              >
                {header_texts.period} : {summary.reference_date}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography align="right" variant="h3">
                {summary.distribution_currency}
              </Typography>
              <Typography align="right" variant="h3">
                {summary.currency_sign}
                {summary.client_revenue.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Grid>
            <Grid item sm={12}>
            <Typography align="right" style={{fontSize:"11px", color: "red"}}>
                Valor sujeito à retenção obrigatória de imposto nas hipóteses previstas em lei
              </Typography>
            </Grid>
         
          </Grid>
        </Grid>

        <div className="horizontal-divider"></div>
      </Grid>
    </div>
  );
}
