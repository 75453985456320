import React, { useState, useContext } from 'react';
import '../../../../utils/date';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';
import excelLogo from '../../../../assets/excel-logo.png';
import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import PublishersDropDown from '../../../../components/common/PublishersDropdown';
import SuggestContributors from '../../../../components/common/SuggestContributors';
import SessionContext from '../../../../contexts/SessionContext';

export const defaultFilters = {
  publisher_id: '',
  search_term: '',
  contributor_id: '',
};

const ActionBar = (props) => {
  const [showFilters, setShowFilters] = useState(false); //shows/hide filter options
  const [filters, setFilters] = useState(defaultFilters);
  let sessionContext = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onTermChangeHandler = (search_term) => {
    updateFilters({ ...filters, search_term: search_term });
  };
  const onPublishersChangeHandler = (publisher) => {
    updateFilters({ ...filters, publisher_id: publisher });
  };

  const onContributorChangeHandler = (contributor_id) => {
    updateFilters({ ...filters, contributor_id: contributor_id });
  };

  const showHideFilters = (show) => {
    setShowFilters(show);
    if (!show) {
      // When there is no value in the filter properties, it is not necessary to clear the values
      if (
        !filters.publisher_id &&
        !filters.search_term &&
        !filters.contributor_id
      )
        return;

      // reset the filters when closing filters panel (ActionBar)
      updateFilters({
        publisher_id: null,
        search_term: null,
        contributor_id: null,
      });
    }
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {sessionContext.hasRole(['WORKS_WRITE']) ? (
          <>
            <div
              className="page-actionbar-item action-btn"
              onClick={() => {
                props.onAddClick();
              }}
            >
              <PlusIcon />
            </div>
          </>
        ) : null}

        {sessionContext.hasRole(['WORKS_FULL_VIEW', 'WORKS_CLIENT_VIEW']) ? (
          <>
            <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
              <h5>
                {props.total_works}: {props.total_items}
              </h5>
              <div
                className="action-btn"
                onClick={() => {
                  props.onDownloadClick();
                }}
              >
                {' '}
                <img
                  src={excelLogo}
                  style={{ height: '25px', marginLeft: '9px' }}
                  alt="Download de fonogramas"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          {sessionContext.hasRole(['WORKS_FULL_VIEW']) ? (
            <PublishersDropDown
              label={props.input_filters.publisher}
              onChange={(publisher) => {
                onPublishersChangeHandler(publisher.id);
              }}
            />
          ) : null}

          {sessionContext.hasRole(['WORKS_FULL_VIEW', 'WORKS_CLIENT_VIEW']) ? (
            <SuggestContributors
              useToFilter={true}
              onChange={(contributor) => {
                onContributorChangeHandler(contributor.id);
              }}
            />
          ) : null}

          {sessionContext.hasRole(['WORKS_FULL_VIEW', 'WORKS_CLIENT_VIEW']) ? (
            <DebouncedTextField
              label={props.input_filters.generic}
              onChange={(value) => {
                onTermChangeHandler(value);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
