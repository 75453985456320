import React, { useState, useEffect } from 'react';
import ORBApi from '../../../services/ORBApi';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ExcelIcon from '../../../icons/ExcelIcon';
import MailIcon from '../../../icons/MailIcon';
import Pagination from '@material-ui/lab/Pagination';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import { pickersPopperClasses } from '@mui/x-date-pickers/internals';
import { formatCurrency } from '../../../utils/CurrencyUtils';
import moment from 'moment';

export function DistributionsTable(props) {
  //{data, onPageChange, onClick}

  return (
    <>
      <TableContainer className="table-container">
        <Table size="small" className="basic-table">
          <TableHead className="basic-table-head">
            <TableRow>
              {props.headers_table.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.distributions.length > 0 &&
            props.hasRole(['DISTRIBUTIONS_WRITE', 'DISTRIBUTIONS_READ'])
              ? props.data.distributions.map((distribution, index) => {
                  return (
                    <DistributionTableRow
                      hasRole={props.hasRole}
                      messages={props.messages}
                      distribution={distribution}
                      approve_distribution={props.approve_distribution}
                      onApproval={() => {
                        props.onPageChange(props.data.current_page);
                      }}
                      onClick={props.onClick}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.data.pages}
        page={props.data.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}

function DistributionTableRow({
  distribution,
  onApproval,
  onClick,
  messages,
  texts,
  approve_distribution,
  hasRole,
}) {
  //{distribution, onApproval}

  const [approving, setApproving] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [sending, setSending] = useState(false);
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [showReproveConfirm, setShowReproveConfirm] = useState(false);

  const onApproveClickHandler = () => {
    setShowApproveConfirm(true);
  };

  const onReproveClickHandler = () => {
    setShowReproveConfirm(true);
  };

  const onExcelClickHandler = () => {
    setDownloading(true);
    ORBApi.downloadDistribution(distribution.id)
      .then((response) => {
        downloadFile(response);
      })
      .catch(() => toast.error(messages.failed_to_download))
      .finally(() => {
        setDownloading(false);
      });
  };

  const onMailClickHandler = () => {
    setSending(true);
    ORBApi.downloadDistribution(distribution.id, 1)
      .then(() => {
        toast.success(messages.distribution_sent);
      })
      .catch(() => {
        toast.error(messages.distribution_send_error);
      })
      .finally(() => {
        setSending(false);
      });
  };

  function downloadFile(response) {
    const file = new Blob([response.data], {
      type: `application/excel`,
      endings: 'native',
    });
    let fileName =
      response.headers['content-disposition'].split('filename=')[1];
    fileName = fileName.replaceAll('"', '');
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  const closeConfirmBox = () => {
    setShowApproveConfirm(false);
    setShowReproveConfirm(false);
  };
  const doDistributionApproval = (approve) => {
    closeConfirmBox();
    setApproving(true);
    if (approve) {
      ORBApi.approveDistribution(distribution.id)
        .then(() => {
          onApproval();
          toast.success(messages.approved_distribution);
        })
        .catch(() => {
          toast.error(messages.failed_approval_message);
        })
        .finally(() => {
          setApproving(false);
        });
    } else {
      ORBApi.reproveDistribution(distribution.id)
        .then(() => {
          onApproval();
          toast.success(messages.rejected_distribution);
        })
        .catch(() => {
          toast.error(texts.messages.rejection_failed);
        })
        .finally(() => {
          setApproving(false);
        });
    }
  };

  return (
    <TableRow className="row" key={distribution.id}>
      <TableCell align="center" style={{ width: '15%' }}>
        {moment(distribution.reference_date).format('MM/YYYY')}
      </TableCell>
      <TableCell
        align="center"
        style={{ width: '35%' }}
        className={hasRole(['DISTRIBUTIONS_WRITE']) ? 'clickable' : ''}
        onClick={() => {
          if (hasRole(['DISTRIBUTIONS_WRITE'])) onClick(distribution);
        }}
      >
        {distribution.name}
      </TableCell>
      <TableCell style={{ width: '10%' }}>
        {distribution.amount
          ? formatCurrency(distribution.amount, distribution.currency)
          : formatCurrency(0, distribution.currency)}
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        {distribution.status}
      </TableCell>
      {hasRole(['DISTRIBUTIONS_WRITE']) ? (
        <TableCell align="center" style={{ width: '20%' }}>
          {distribution.status === 'WaitingApproval' ? (
            approving ? (
              <Loading size="25px" />
            ) : (
              <>
                <CheckCircleIcon
                  color="secondary"
                  className="clickable"
                  onClick={onApproveClickHandler}
                />
                <CancelIcon
                  color="secondary"
                  className="clickable"
                  onClick={onReproveClickHandler}
                />
              </>
            )
          ) : null}
        </TableCell>
      ) : (
        <TableCell></TableCell>
      )}

      <TableCell align="center" style={{ width: '15%' }}>
        {distribution.status === 'Approved' ||
        distribution.status === 'WaitingApproval' ? (
          downloading ? (
            <Loading size="15px" />
          ) : (
            <ExcelIcon
              className="clickable action-icon"
              onClick={onExcelClickHandler}
            />
          )
        ) : null}
        {distribution.status === 'Approved' ||
        distribution.status === 'WaitingApproval' ? (
          sending ? (
            <Loading size="15px" />
          ) : (
            <MailIcon
              className="clickable action-icon"
              onClick={onMailClickHandler}
            />
          )
        ) : null}
      </TableCell>
      {/** Confirm modal for approving / reproving distributions */}
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        open={showApproveConfirm || showReproveConfirm}
        onClose={closeConfirmBox}
      >
        <div className="modal-container confirm">
          <h6 style={{ textAlign: 'center' }}>
            {showApproveConfirm
              ? approve_distribution.confirm_message
              : approve_distribution.refuse_message}
          </h6>
          <div style={{ textAlign: 'center' }}>{distribution.name}</div>
          <div className="approval-btn-container">
            <Button
              className="btn-approve"
              variant="contained"
              color="secondary"
              onClick={() => doDistributionApproval(showApproveConfirm)}
            >
              {approve_distribution.confirm}
            </Button>
            <Button
              className="btn-reprove"
              variant="contained"
              color="secondary"
              onClick={closeConfirmBox}
            >
              {approve_distribution.cancel}
            </Button>
          </div>
        </div>
      </Modal>
    </TableRow>
  );
}

export function PaymentFilesTable(props) {
  return (
    <>
      <TableContainer>
        <Table size="small" className="basic-table">
          <TableHead className="basic-table-head">
            <TableRow>
              {props.header_table.map((title) => (
                <TableCell>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.reports.map((pmtFile, index) => {
              return (
                <TableRow key={pmtFile.id}>
                  <TableCell>{pmtFile.date}</TableCell>
                  <TableCell>{pmtFile.origin}</TableCell>
                  <TableCell>{pmtFile.type}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.data.pages}
        page={props.data.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}
