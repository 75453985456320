import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from '@material-ui/core';
import '../../utils/date';

export default function DateMonthPicker({
  className,
  onChange,
  label,
  required,
  error,
  format,
  view,
  value,
  useToFilter,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onChangeHandler = (date) => {
    if (isNaN(date)) return; // When user is typing, we get some invalid dates
    if (isOpen) return; // When user is picking a date from interface should not use this handler

    if (date) onChange(date, formatDate(date));
    else onChange(null, null);
  };

  const formatDate = (date) => {
    let newDate = moment(date).format('YYYY-MM');
    return newDate;
  };
  const onYearChangeHandler = (date) => {
    setIsOpen(false);
    onChange(date, formatDate(date));
  };

  useEffect(() => {
    // If useToFilter is true, set the value to empty
    if (useToFilter) {
      onChange(null, '');
    } else if (!value) {
      // If there is no value and it's not for filtering, set the default value
      let today = new Date();
      onChange(today, formatDate(today));
    }
  }, []);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin={useToFilter ? '' : 'normal'}
      className={className}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          label={label}
          format={format}
          views={view}
          required={required}
          error={error}
          value={value}
          InputAdornmentProps={{ position: 'start' }}
          onChange={onChangeHandler}
          onYearChange={onYearChangeHandler}
          onOpen={() => {
            setIsOpen(true);
          }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}
