import React, { useState, useContext } from 'react';
import '../../../../utils/date';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';

import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import SessionContext, {
  SystemRoles,
} from '../../../../contexts/SessionContext';

export const defaultFilters = {
  publisher_name: '',
};

const ActionBar = (props) => {
  const [showFilters, setShowFilters] = useState(false); //shows/hide filter options
  const [filters, setFilters] = useState(defaultFilters);
  let sessionContext = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onNameChangeHandler = (name) => {
    updateFilters({ ...filters, publisher_name: name });
  };

  const showHideFilters = (show) => {
    setShowFilters(show);

    if (!show) {
      // When there is no value in the filter properties, it is not necessary to clear the values
      if (!filters.publisher_name) return;
      // reset the filters when closing filters panel
      updateFilters({
        publisher_name: null,
      });
    }
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {props.hasRole('PUBLISHERS_WRITE')}
        <div
          className="page-actionbar-item action-btn"
          onClick={() => {
            props.setFormVisible(true);
          }}
        >
          <PlusIcon />
        </div>
        <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
          <h5>Total de Editoras: {props.total_items}</h5>
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          {sessionContext.hasRole(['LABELS_WRITE']) ? (
            <DebouncedTextField
              label={'Nome'}
              onChange={(name) => {
                onNameChangeHandler(name);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
