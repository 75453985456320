import React, { useEffect, useState } from 'react';
import ORBApi from '../../../../services/ORBApi';
import { Grid, Button, TextField } from '@material-ui/core';
import Loading from '../../../../components/common/Loading';
import { toast } from 'react-toastify';

export default function RateEditPanel({ report, onSave }) {
  const [currencies, setCurrencies] = useState([]);
  const [currenciesToUpdate, setCurrenciesToUpdate] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    ORBApi.loadPaymentWorkReportCurrencies(report).then((result) => {
      setCurrencies(result);
    });
  }, [report]);

  const onTextChangeHandler = (value, currency) => {
    let original = currenciesToUpdate;
    original[currency] = parseFloat(value);
    setCurrenciesToUpdate(original);
  };

  const updateCurrencies = () => {
    setIsProcessing(true);

    if (Object.keys(currenciesToUpdate).length === 0) {
      toast.warn('Preencha um valor');
      setIsProcessing(false);
      return;
    }

    var total = Object.keys(currenciesToUpdate).length;
    var i = 0;

    for (var currency in currenciesToUpdate) {
      i++;

      if (
        currenciesToUpdate[currency] === undefined ||
        currenciesToUpdate[currency] === null ||
        currenciesToUpdate[currency] === '' ||
        isNaN(currenciesToUpdate[currency])
      ) {
        toast.warn('Preencha um valor');
        setIsProcessing(false);
        return;
      }
      ORBApi.updatePaymentWorkReportCurrency(
        report,
        currency,
        currenciesToUpdate[currency],
      ).finally(() => {
        if (i === total) {
          setIsProcessing(false);
          onSave();
        }
      });
    }
  };

  return (
    <>
      <h6 className="title">Taxas de câmbio para edição</h6>
      <Grid container spacing={2} alignItems="center">
        {currencies.map((currency, key) => {
          return (
            <Grid item md={6} key={currency.currency}>
              <TextField
                onChange={(e) =>
                  onTextChangeHandler(e.target.value, currency.currency)
                }
                label={currency.currency}
                defaultValue={currency.exchange_rate}
                variant="outlined"
                type="number"
              />
            </Grid>
          );
        })}

        {isProcessing ? (
          <Loading size="25px" />
        ) : (
          <Grid item md={4} key="btn">
            <Button
              onClick={updateCurrencies}
              variant="contained"
              color="secondary"
              size="large"
            >
              {' '}
              Salvar{' '}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
