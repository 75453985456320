import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LabelsDropdown from '../../../../components/common/LabelsDropdown';
import Loading from '../../../../components/common/Loading';

import {
  Typography,
  Grid,
  TextField,
  Button,
  Paper
} from '@material-ui/core';
import SplitsTab from './SplitsTab';
import ORBApi from '../../../../services/ORBApi';

/** Track form to be used to create or edit a track */
export default function TrackForm({
  edit_track,
  onSave,
  translateCategory,
  form_texts,
  messages,
}) {
  const emptySplit = {
    upc:'',
    contributions: [
      {
        name: '',
        contributor_id: '',
        share: '',
        category: '',
        is_orb_managed: false,
      }
    ] 
  };
  const [track, setTrack] = useState({
    label: '',
    title: '',
    isrc: '',
    artist: '',
    splits: [{ upc: 'Padrão', contributions: [] }],
  });
  const [title, setTitle] = useState('');
  const [showSplitForm, setShowSplitForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [splitOnPlay, setSplitOnPlay] = useState({ ...emptySplit });
  const [emptySplitInput, setEmptySplitInput] = useState(false)
  const [emptyTrackInput, setEmptyTrackInput] = useState(false)
  const [emptyUpcInput, setEmptyUpcInput] = useState(false)

  const updateSplitOnPlay = (newSplitOnPlay) => {
    setSplitOnPlay(newSplitOnPlay)
  }

  const updateUpc = (selectedIndex, upc) =>{
    const newTrack = {...track};
    newTrack.splits[selectedIndex].upc = upc;
    setTrack(newTrack)
  }

  const removeSplitItem = (selectedIndex, itemIndex) => {
    setTrack(prevTrack => {
      const updatedSplits = [...prevTrack.splits];
      const updatedContributions = [...updatedSplits[selectedIndex].contributions];
      updatedContributions.splice(itemIndex, 1);
      updatedSplits[selectedIndex].contributions = updatedContributions;
      return { ...prevTrack, splits: updatedSplits };
    });
  };


  const addSplitItem = (selectedIndex) => {

    if(selectedIndex > 0){
      if(!track.splits[selectedIndex].upc){
        setEmptyUpcInput(true)
      }else setEmptyUpcInput(false)
    }

    const contribution = splitOnPlay.contributions[0]
    if (!contribution.name || !contribution.category || !contribution.share) {

      setEmptySplitInput(true)

    } else {

      let updatedSplits = [...track.splits]; 
      const updatedSplit = { ...updatedSplits[selectedIndex] }; 
      updatedSplit.contributions.push(...splitOnPlay.contributions); 
      updatedSplits[selectedIndex] = updatedSplit; 
      setTrack({ ...track, splits: updatedSplits });
  
      setSplitOnPlay({ ...emptySplit });

      setEmptySplitInput(false)
    }
  };
  

  const addNewTab = () => {
    const updatedSplits = [...track.splits];
    updatedSplits.push({ upc:'', contributions: [] });
    setTrack({ ...track, splits: updatedSplits });
    setSplitOnPlay({...emptySplit})
  }


  const deleteTab = (index) => {
  const updatedSplits = [...track.splits];
  updatedSplits.splice(index, 1);
  setTrack({ ...track, splits: updatedSplits });
  setSplitOnPlay({...emptySplit})
  };



  const addErrorToTrackSplits = (errorSplitIndexes) => {
    const updatedSplits = [...track.splits];
    errorSplitIndexes.forEach((index) => {
      if (updatedSplits[index]) {
        updatedSplits[index].error = true;
      }
    });
    setTrack((prevTrack) => ({
      ...prevTrack,
      splits: updatedSplits,
    }));

  };

  const validateTrack = () => {

    if(!track.artist || !track.isrc || !track.title || !track.label ){
      setEmptyTrackInput(true)
       return true
    }
      var splitTotal = [];
      for (var i in track.splits){
        if(track.splits[i].upc === "Padrão"){
          track.splits[i].upc = ""
        }
        var splitShare = 0
        for(var x in track.splits[i].contributions){
          splitShare+= track.splits[i].contributions[x].share
          if (
            track.splits[i].contributions[x].document === null ||
            track.splits[i].contributions[x].document === ''
          ) {
            // should not send to the API null values on document
            delete track.splits[i].contributions[x].document; 
          }
        }
        splitTotal.push(splitShare)
      }
      var errorSplitIndexes = []
      for(var i in splitTotal){
        //The % total, must be 99.99% or 100%.
        if (splitTotal[i] < 99.99 || splitTotal[i] > 100) {
          errorSplitIndexes.push(i)
          }
      }

      if(errorSplitIndexes.length > 0){
        addErrorToTrackSplits(errorSplitIndexes)
        return true
      }
  
  }

  const saveTrack = () => {

    if(validateTrack()) return
    

    setIsSaving(true);
    ORBApi.postTracks([track], 'application/json')
      .then(() => {
        edit_track
          ? toast.success(messages.saved_edit)
          : toast.success(messages.saved_track);

        onSave(track);
      })
      .catch((error) => {
        edit_track
          ? toast.success(messages.failed_to_edit)
          : toast.success(messages.failed_to_save);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  
  const configEditTrackData = (edit_track) => {
    if (edit_track.splits[0].upc === "") {
      edit_track.splits[0].upc = "Padrão";
    }
    setTrack(edit_track)
  }

  useEffect(() => {
    if (edit_track) {
      configEditTrackData(edit_track)
      setTitle(form_texts.title_edit);
    } else {
      setTitle(form_texts.title);

    }
  }, []);

  useEffect(() => {
console.log(track)
  }, [track]);


  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <LabelsDropdown
            label={form_texts.label}
            className="label-dropdown"
            onChange={(label) =>
              setTrack({ ...track, label_id: label.id, label: label.name })
            }
            value={track.label_id}
            error={!track.label ? emptyTrackInput : false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label={form_texts.track}
            variant="outlined"
            fullWidth
            value={track.title}
            onChange={(e) => setTrack({ ...track, title: e.target.value })}
            error={!track.title ? emptyTrackInput : false}
            helperText={!track.title && emptyTrackInput ? 'Campo obrigatório.' : ''}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label={form_texts.isrc}
            variant="outlined"
            fullWidth
            value={track.isrc}
            onChange={(e) => setTrack({ ...track, isrc: e.target.value })}
            error={!track.isrc ? emptyTrackInput : false}
            helperText={!track.isrc && emptyTrackInput ? 'Campo obrigatório.' : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label={form_texts.artist}
            variant="outlined"
            fullWidth
            value={track.artist}
            onChange={(e) => setTrack({ ...track, artist: e.target.value })}
            error={!track.artist ? emptyTrackInput : false}
            helperText={!track.artist && emptyTrackInput ? 'Campo obrigatório.' : ''}
          />
        </Grid>


        <Grid xs={12} style={{margin:"10px"}}>
            <hr/>
            <Paper style={{ padding: "10px 15px"}}>
            <Grid item xs={12} style={{ display: 'flex' }}>

            <Typography
              variant="h6"
              component="h6"
            >
              Splits
            </Typography>
            </Grid>        
            <SplitsTab 
            splits={track.splits.length > 0 ? track.splits : []} 
            form_texts={form_texts}
            removeSplitItem={removeSplitItem}
            addSplitItem={addSplitItem}
            addNewTab={addNewTab}
            translateCategory={translateCategory}
            updateSplitOnPlay={updateSplitOnPlay}
            splitOnPlay={splitOnPlay}
            deleteTab={deleteTab}
            updateUpc={updateUpc}
            splitError={emptySplitInput}
            upcError={emptyUpcInput}
            />
            </Paper>
            
        </Grid> 
      </Grid>

      <Grid xs={12} md={3}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            style={{ marginTop: '15px' }}
            onClick={saveTrack}
          >
            {edit_track ? form_texts.edit_button : form_texts.save_button}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
}
