import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './styles/main-styles.css';
import Navigation from './routers/Navigation';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
