import React, { useEffect, useState } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/common/Loading';
import { Modal } from '@material-ui/core';
import ActionBar, { defaultFilters } from './components/ActionBar';
import ClientsTable from './components/ClientsTable';
import ClientsForm from './components/ClientsForm';

const Clients = () => {
  const [data, setData] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [clientOnPlay, setClientOnPlay] = useState(null);
  const [loading, setLoading] = useState(0);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showClientForm = (client) => {
    if (client) setClientOnPlay(JSON.parse(JSON.stringify(client)));
    else setClientOnPlay(null);
    setFormVisible(true);
  };

  const onSaveClient = () => {
    onClose();
    loadData(1);
  };

  const onClose = () => {
    setFormVisible(false);
    setClientOnPlay(null);
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadClients(page, filters, 8)
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <>
      <div>
        <ORBNavBar page_title="Cadastro > Clientes" />
        <ActionBar
          formVisible={formVisible}
          setFormVisible={setFormVisible}
          onFilterChange={onFilterChangeHandler}
          total_items={data?.total_items}
          onSaveClient={onSaveClient}
        />

        {!data ? (
          loading ? (
            <Loading text="Buscando Clientes..." />
          ) : (
            <ClientsTable clients={[]} />
          )
        ) : loading ? (
          <Loading text="Buscando Clientes..." />
        ) : (
          <>
            <ClientsTable
              clients={data.clients}
              total_items={data.total_items}
              page={data.page}
              pages={data.pages}
              current_page={data.current_page}
              onPageChange={onPageChangeHandler}
              showClientForm={showClientForm}
            />
          </>
        )}
        <Modal
          size="lg"
          open={formVisible}
          onClose={onClose}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="modal-container">
            <ClientsForm
              edit_clients={clientOnPlay}
              onSaveClient={onSaveClient}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Clients;
