import React, { useState, useContext } from 'react';
import '../../../../utils/date';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import ClientsForm from './ClientsForm';
import LabelsDropDown from '../../../../components/common/LabelsDropdown';
import PublishersDropdown from '../../../../components/common/PublishersDropdown';
import SessionContext from '../../../../contexts/SessionContext';

export const defaultFilters = {
  name: '',
};

const ActionBar = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  let { hasRole } = useContext(SessionContext);
  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const showHideFilters = (show) => {
    setShowFilters(show);
    if (!show) {
      updateFilters({
        name: null,
      });
    }
  };

  const onClientChangeHandler = (client) => {
    updateFilters({ ...filters, client: client });
  };
  const onLabelChangeHandler = (label_id) => {
    updateFilters({ ...filters, label_id: label_id });
  };
  const onPublisherChangeHandler = (publisher_id) => {
    updateFilters({ ...filters, publisher_id: publisher_id });
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>

        {hasRole(['CLIENTS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              props.setFormVisible(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}

        <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
          <h5>Total de Clientes: {props.total_items}</h5>
        </div>
        <div>
          <Modal
            size="lg"
            open={props.formVisible}
            onClose={() => props.setFormVisible(!props.formVisible)}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeogut: 500 }}
          >
            <div className="modal-container">
              <ClientsForm
                setFormVisible={props.setFormVisible}
                onSaveClient={props.onSaveClient}
              />
            </div>
          </Modal>
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <DebouncedTextField
            label="Cliente"
            onChange={(client) => {
              onClientChangeHandler(client);
            }}
          />

          <LabelsDropDown
            label={'Selos'}
            className="filter-labels"
            onChange={(label) => {
              onLabelChangeHandler(label.id);
            }}
          />
          <PublishersDropdown
            label={'Editoras'}
            onChange={(publisher) => {
              onPublisherChangeHandler(publisher.id);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
