import React from 'react';
import SimpleNavbar from '../SimpleNavbar';
import { Typography } from '@material-ui/core';

export default function ORBNavBar(props) {
  // props {page_title, selectedOption}

  return (
    <>
      <SimpleNavbar selectedOption={props.selectedOption} />
      <Typography
        style={{
          width: '95%',
          margin: '5px auto 10px auto',
          color: '#111',
        }}
        variant="h6"
        align="left"
        color="secondary"
      >
        {props.page_title}
      </Typography>
    </>
  );
}
