import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../../components/common/Loading';
import CpfCnpj from '../../../../components/common/CpfCnpj';
import { Grid, TextField, Button } from '@material-ui/core';
import ORBApi from '../../../../services/ORBApi';

const ContributorsForm = (props) => {
  const [contributor, setContributor] = useState({
    name: '',
    artist_name: '',
    document: '',
  });
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const saveErrorHandler = (ex) => {
    if (ex.response && ex.response.data['error'] === 'INVALID_DOCUMENT') {
      toast.error(props.messages.invalid_document);
    } else {
      toast.error(props.messages.failed_to_save);
    }
    setIsSaving(false);
  };

  const saveSuccessHandler = (created_contributor) => {
    toast.success(props.messages.saved_holder);
    props.onSaveContributor(created_contributor);
    return true;
  };

  const saveContributor = () => {
    if (contributor.name === '') {
      toast.warn(props.messages.required_inputs);
      return;
    }

    setIsSaving(true);
    if (props.edit_contributors) {
      ORBApi.putContributors(contributor)
        .then((data) => {
          saveSuccessHandler(data);
        })
        .catch((ex) => {
          saveErrorHandler(ex);
        });
    } else {
      ORBApi.postContributors(contributor)
        .then((data) => {
          saveSuccessHandler(data);
        })
        .catch((ex) => {
          saveErrorHandler(ex);
        });
    }
  };

  useEffect(() => {
    if (props.edit_contributors) {
      setTitle(props.form_texts.title_edit);
      if (props.edit_contributors.artist_name === null) {
        props.edit_contributors.artist_name = '';
      }
      if (props.edit_contributors.document === null) {
        props.edit_contributors.document = '';
      }
      setContributor(props.edit_contributors);
    } else {
      setTitle(props.form_texts.title);
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label={props.form_texts.input_name}
            variant="outlined"
            fullWidth
            value={contributor.name}
            onChange={(e) =>
              setContributor({
                ...contributor,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label={props.form_texts.input_artist_name}
            variant="outlined"
            fullWidth
            value={contributor.artist_name}
            onChange={(e) =>
              setContributor({
                ...contributor,
                artist_name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CpfCnpj
            label={props.form_texts.input_doc}
            value={contributor.document}
            onChange={(e) =>
              setContributor({
                ...contributor,
                document: e.target.value.replace(/\D/g, ''),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} style={{ marginTop: '15px' }}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={saveContributor}
          >
            {props.edit_contributors
              ? props.form_texts.edit_button
              : props.form_texts.save_button}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default ContributorsForm;
