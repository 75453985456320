import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CambioTable = ({ data, headers }) => {
  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <Table className="cambio-table">
        <TableHead>
          <TableRow>
            {headers.map((header, key) => (
              <TableCell className="header-cell" id="key" align="center">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0
            ? data.map((item) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell className="cell" align="center">
                    {item.source}
                  </TableCell>

                  <TableCell className="cell" align="center">
                    {item.currency}
                  </TableCell>

                  <TableCell className="cell" align="center">
                    {item.exchange_rate}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CambioTable;
