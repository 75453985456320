import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import ORBApi from '../../../../services/ORBApi';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import CpfCnpj from '../../../../components/common/CpfCnpj';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SessionContext from '../../../../contexts/SessionContext';
toast.configure();

const PublishersForm = (props) => {
  const emptyBody = {
    name: '',
    document: '',
    orb_percent_fee_on_publisher: '',
    orb_percent_fee_on_author: '',
  };
  const [publisher, setPublisher] = useState(emptyBody);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState();
  const [title, setTitle] = useState();
  const [saving, setIsSaving] = useState(false);
  let { hasRole } = useContext(SessionContext);

  const validateDocument = (value) => {
    if (value.length === 11 || value.length === 14 || value.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const savePublisher = () => {
    setLoading(true);
    if (imageData) {
      publisher.logo = imageData.id;
    }
    if (!publisher.name) {
      toast.warn("O campo 'nome' é obrigatório");
      setLoading(false);
      return;
    } else if (publisher.orb_percent_fee_on_publisher === '') {
      toast.warn("O campo '% sob editora' é obrigatório");
      return;
    } else if (publisher.orb_percent_fee_on_author === '') {
      toast.warn("O campo '% sob autor' é obrigatório");
      return;
    } else if (!validateDocument(publisher.document)) {
      toast.warn('Digite um documento válido');
      setLoading(false);
      return;
    }
    if (props.edit_publisher) {
      setIsSaving(true);
      ORBApi.putPublisher(publisher)
        .then(() => {
          toast.success('Edição Finalizada!');
          props.onSavePublisher();
        })
        .catch(() => {
          toast.error('Falha ao editar Selo.');
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      setIsSaving(true);
      ORBApi.postPublisher(publisher)
        .then(() => {
          toast.success('Selo Salvo!');
          props.onSavePublisher();
        })
        .catch(() => {
          toast.error('Falha ao salvar selo.');
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };
  const postLogo = async (e) => {
    var formData = new FormData();
    formData.append('logo', await onChangeFile(e));
    ORBApi.postLabelLogo(formData).then((response) => setImageData(response));
  };

  const onChangeFile = async (event) => {
    try {
      const file = event.target.files[0];
      const image = file;
      return image;
    } catch (err) {}
  };

  useEffect(() => {
    if (props.edit_publisher) {
      setTitle('Editar Editora');
      setPublisher({
        ...publisher,
        name: props.edit_publisher.name,
        document: props.edit_publisher.document,
        orb_percent_fee_on_publisher:
          props.edit_publisher.orb_percent_fee_on_publisher,
        orb_percent_fee_on_author:
          props.edit_publisher.orb_percent_fee_on_author,
        id: props.edit_publisher.id,
      });
      if (props.edit_publisher.logo_url !== null) {
        setImageData({ ...imageData, url: props.edit_publisher.logo_url });
        delete props.edit_publisher.logo_url;
      } else {
        delete props.edit_publisher.logo_url;
      }
    } else {
      setTitle('Criar Editora');
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label="Nome"
            value={publisher ? publisher.name : null}
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setPublisher({ ...publisher, name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CpfCnpj
            value={publisher ? publisher.document : null}
            onChange={(e) =>
              setPublisher({
                ...publisher,
                document: e.target.value.replace(/\D/g, ''),
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="% Orb sob a editora"
            value={publisher ? publisher.orb_percent_fee_on_publisher : null}
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setPublisher({
                ...publisher,
                orb_percent_fee_on_publisher: parseFloat(e.target.value),
              })
            }
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="% Orb sob o autor"
            value={publisher ? publisher.orb_percent_fee_on_author : null}
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setPublisher({
                ...publisher,
                orb_percent_fee_on_author: parseFloat(e.target.value),
              })
            }
            type="number"
          />
        </Grid>
        {!imageData ? (
          hasRole(['LOGO_WRITE']) ? (
            <>
              <Grid item xs={12}>
                <input
                  type="file"
                  id="real-image"
                  hidden="hidden"
                  onChange={(e) => postLogo(e)}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => document.getElementById('real-image').click()}
                >
                  Logo
                </Button>
              </Grid>
            </>
          ) : null
        ) : (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                style={{ height: '102px', width: '102px' }}
                src={imageData.url}
                alt="logo"
              />
              <br />
              {hasRole(['LOGO_WRITE']) ? (
                <>
                  <input
                    type="file"
                    id="real-image"
                    hidden="hidden"
                    onChange={(e) => postLogo(e)}
                  />
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() =>
                      document.getElementById('real-image').click()
                    }
                  >
                    Alterar
                  </Button>
                </>
              ) : null}
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={savePublisher}
          >
            {props.edit_publisher ? 'Editar' : 'Criar'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PublishersForm;
