import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/common/Loading';
import { Modal } from '@material-ui/core';
import PublishersTable from './components/PublishersTable';
import PublisherForm from './components/PublisherForm';
import ActionBar, { defaultFilters } from './components/ActionBar';
import SessionContext from '../../../contexts/SessionContext';

const Publishers = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [publisherOnPlay, setPublisherOnPlay] = useState(null);
  let sessionContext = React.useContext(SessionContext);
  let { hasRole } = useContext(SessionContext);

  const onFilterChange = (newFilters) => {
    setFilters({
      publisher_name: newFilters.publisher_name,
    });
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showPublisherForm = (publisher) => {
    if (publisher) setPublisherOnPlay(JSON.parse(JSON.stringify(publisher)));
    // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    else setPublisherOnPlay(null);
    setFormVisible(true);
  };

  const onSavePublisher = () => {
    setFormVisible(false);
    loadData(1);
  };

  const loadData = (page) => {
    setLoading(1);
    ORBApi.loadPublishers(page, filters)
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <>
      <div id="register-publishers-page">
        <ORBNavBar page_title="Cadastro > Editoras" />

        <ActionBar
          hasRole={hasRole}
          setFormVisible={setFormVisible}
          total_items={data?.total_items}
          onFilterChange={onFilterChange}
          onSavePublsiher={onSavePublisher}
        />
        {!data ? (
          loading ? (
            <Loading
              className="publishers-loading-container"
              text="Buscando Editoras..."
            />
          ) : (
            <PublishersTable publishers={[]} />
          )
        ) : loading ? (
          <Loading
            className="publishers-loading-container"
            text="Buscando Editoras..."
          />
        ) : (
          <>
            <PublishersTable
              hasRole={hasRole}
              publishers={data.publishers}
              total_items={data.total_items}
              page={data.page}
              pages={data.pages}
              current_page={data.current_page}
              onPageChange={onPageChangeHandler}
              showPublisherForm={showPublisherForm}
            />
          </>
        )}
      </div>
      <Modal
        size="lg"
        open={formVisible}
        onClose={() => {
          setFormVisible(!formVisible);
          setPublisherOnPlay(null);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div className="modal-container">
          <PublisherForm
            edit_publisher={publisherOnPlay}
            onSavePublisher={onSavePublisher}
          />
        </div>
      </Modal>
    </>
  );
};

export default Publishers;
