import React from 'react';
import TracksMonthlyRevenueGraph from './graphs/TracksMonthlyRevenueGraph';
import TracksDSPRevenueGraph from './graphs/TracksDSPRevenueGraph';
import TracksRevenueGraph from './graphs/TracksRevenueGraph';
import TracksTerritoryRevenueGraph from './graphs/TracksTerritoryRevenueGraph';
import TracksContributorRevenueGraph from './graphs/TracksContributorRevenueGraph';
import { Grid, Box } from '@material-ui/core';

export default function GraphsPanel({
  language,
  filters,
  texts,
  currency,
  hasRole,
}) {
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.monthly_distribution}</h4>
            <TracksMonthlyRevenueGraph
              language={language}
              currency={currency}
              filters={filters}
            />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.dsp_distribution}</h4>
            <TracksDSPRevenueGraph currency={currency} filters={filters} />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.track_distribution}</h4>
            <TracksRevenueGraph currency={currency} filters={filters} />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className="graph-container">
            <h4>{texts.territory_distribution}</h4>
            <TracksTerritoryRevenueGraph
              currency={currency}
              filters={filters}
            />
          </div>
        </Grid>
        {hasRole([
          'DASHBOARD_TRACKS_FULL_VIEW',
          'DASHBOARD_TRACKS_AGENT_VIEW',
        ]) ? (
          <Grid item md={6} sm={12}>
            <div className="graph-container">
              <h4>{texts.holder_distribution}</h4>
              <TracksContributorRevenueGraph
                currency={currency}
                filters={filters}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
