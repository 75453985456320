import React, { useEffect, useState, useContext } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

const LabelsTable = (props) => {
  const paymentTypeHandler = (label) => {
    if (
      label.orb_percent_fee !== undefined &&
      label.orb_percent_on_net_value === true
    ) {
      return `${label.orb_percent_fee}% sob o líquido`;
    } else if (
      label.orb_percent_fee !== undefined &&
      label.orb_percent_on_net_value === false
    ) {
      return `${label.orb_percent_fee}% sob o bruto`;
    } else if (label.orb_flat_fee !== undefined) {
      return `R$ ${label.orb_flat_fee} fixo`;
    } else if (label.orb_track_fee !== undefined) {
      return `R$ ${label.orb_track_fee} por faixa`;
    } else {
      return `Não identificado`;
    }
  };

  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {['Data de criação', 'Selo', 'Cobrança'].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.labels.length > 0
              ? props.labels.map((label) => {
                  return (
                    <TableRow className="row">
                      {props.hasRole(['LABELS_WRITE']) ? (
                        <>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showLabelForm(label)}
                          >
                            {moment(label.date).format('MM/YYYY')}{' '}
                          </TableCell>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showLabelForm(label)}
                          >
                            {label.name}{' '}
                          </TableCell>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showLabelForm(label)}
                          >
                            {paymentTypeHandler(label)}{' '}
                          </TableCell>
                        </>
                      ) : props.hasRole(['LABELS_READ']) ? (
                        <>
                          <TableCell align="center" className="">
                            {label.date}{' '}
                          </TableCell>
                          <TableCell align="center" className="">
                            {label.name}{' '}
                          </TableCell>
                          <TableCell align="center" className="">
                            {paymentTypeHandler(label)}{' '}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default LabelsTable;
