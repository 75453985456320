import React, { useEffect, useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
  // define a origem de ancoragem do menu em relação ao componente pai.
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginTop: 8, // adiciona um espaço entre o Select e o menu de seleção
    },
  },
};

export default function CheckMarksDropdown({
  data,
  ids,
  onChange,
  margin,
  label,
  names,
}) {
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  function updateSelectedItemsIds(selectedItemId) {
    if (selectedIds.includes(selectedItemId)) {
      const newSelectedIds = selectedIds.filter((id) => id !== selectedItemId);
      setSelectedIds(newSelectedIds);
      return newSelectedIds;
    } else {
      const newSelectedIds = [...selectedIds, selectedItemId];
      return newSelectedIds;
    }
  }

  function updateSelectedNames(selectedItemsName) {
    const newSelected = selectedItemsName.filter(
      (item, index) => selectedItemsName.indexOf(item) === index,
    );
    setSelectedNames(newSelected);
  }

  const onChangeHandler = (e) => {
    const selectedItemsName = e.target.value;
    const selectedItemId = Number(e.currentTarget.getAttribute('id'));
    updateSelectedNames(selectedItemsName);
    const selectedItemsIds = updateSelectedItemsIds(selectedItemId);

    onChange(selectedItemsIds);
  };

  // useEffect(() => {
  //   if (ids.length > 0) setSelectedIds(ids);
  // }, [ids]);
  // useEffect(() => {
  //   if (names.length > 0) setSelectedNames(names);
  // }, [names]);

  useEffect(() => {
    if (ids.length > 0 && data.length > 0) {
      const selectedNames = data
        .filter((item) => ids.includes(item.id))
        .map((item) => item.name);
      setSelectedNames(selectedNames);
      setSelectedIds(ids);
    }
  }, [ids, data]);

  return (
    <div>
      <FormControl margin={margin} fullWidth>
        <InputLabel
          id="demo-mutiple-checkbox-label"
          style={{ marginLeft: '15px' }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          label={label}
          value={selectedNames}
          onChange={onChangeHandler}
          renderValue={(selected) => selected.join(',')}
          input={<OutlinedInput label={label} />}
          MenuProps={menuProps}
        >
          {data.map((item) => (
            <MenuItem key={item.id} value={item.name} id={item.id}>
              <Checkbox checked={selectedNames.indexOf(item.name) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
