import React, { useEffect, useState } from 'react';
import ORBApi from '../../../../services/ORBApi';
import * as moment from 'moment';
import {
  Tooltip,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableRow,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import Loading from '../../../../components/common/Loading';
import excelLogo from '../../../../assets/excel-logo.png';
import { toast } from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import BackupRoundedIcon from '@material-ui/icons/BackupRounded';
import DateMonthPicker from '../../../../components/common/DateMonthPicker';

export function AddFilePanelCompanies({ onSave }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [lastFile, setLastFile] = useState(null);
  const [reportData, setReportData] = useState({
    month: '',
    currency: '',
    report_source: '',
    comma_as_decimal_sep: '',
    show_exchange_rate: false,
  });
  const [checked, setChecked] = useState(false);
  const [validateForm, setValidateForm] = useState(false); // when true shows the error on fields
  const [processing, setProcessing] = useState(false); // used to show loading

  const isFormValid = () => {
    setValidateForm(true);
    if (
      !selectedFile ||
      reportData.month === '' ||
      reportData.currency === '' ||
      reportData.report_source === '' ||
      reportData.comma_as_decimal_sep === ''
    )
      return false;

    return true;
  };

  const uploadForm = () => {
    if (!isFormValid()) return;
    setProcessing(true);
    //Mount the posting body
    var formData = new FormData();
    formData.append('report_file', selectedFile);
    formData.append('month', moment(reportData.month).format('YYYY-MM'));
    formData.append('report_source', reportData.report_source);
    formData.append('currency', reportData.currency);
    formData.append('comma_as_decimal_sep', reportData.comma_as_decimal_sep);
    formData.append(
      'show_exchange_rate',
      reportData.show_exchange_rate === true ? 0 : 1,
    );

    if (lastFile && reportData.report_source == 8) {
      formData.append('previous_report_file', lastFile);
    }
    ORBApi.postPaymentReports(formData)
      .then(() => {
        onSave(true);
      })
      .catch(() => {
        onSave(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      <h4 className="title"> Carregar arquivo agregadora</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} xs={12}>
          <DateMonthPicker
            label="Mês Referência"
            error={validateForm && !reportData.month}
            format="MM/yyyy"
            view={['month', 'year']}
            required
            initialDate={new Date()}
            onChange={(month) => {
              setReportData({ ...reportData, month: month });
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            className="form-select"
            required
            error={validateForm && !reportData.currency ? true : null}
          >
            <InputLabel className="select-label">Moeda</InputLabel>
            <Select
              native
              onChange={(e) =>
                setReportData({ ...reportData, currency: e.target.value })
              }
              variant="outlined"
            >
              <option value="" />
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="BRL">BRL</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            className="form-select"
            required
            error={validateForm && !reportData.report_source ? true : null}
          >
            <InputLabel className="select-label"> Fonte </InputLabel>
            <Select
              native
              onChange={(e) => {
                setReportData({ ...reportData, report_source: e.target.value });
              }}
              variant="outlined"
            >
              <option aria-label="None" value="" />
              <option value={1}>Believe</option>
              <option value={2}>TheOrchard</option>
              <option value={3}>OneRPM</option>f{' '}
              <option value={4}>Ingrooves</option>
              <option value={5}>Altafonte</option>
              <option value={6}>FUGA</option>
              <option value={7}>Ingrooves USA</option>
              <option value={8}>Universal</option>
              <option value={9}>SomLivre</option>
              <option value={10}>Symphonic</option>
              <option value={11}>CmdShift</option>
              <option value={12}>Universal2</option>
              <option value={13}>Believe2</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            className="form-select"
            required
            error={
              validateForm && !reportData.comma_as_decimal_sep ? true : null
            }
          >
            <InputLabel className="select-label"> Formato </InputLabel>
            <Select
              native
              onChange={(e) => {
                setReportData({
                  ...reportData,
                  comma_as_decimal_sep: e.target.value,
                });
              }}
              variant="outlined"
            >
              <option aria-label="None" value="" />
              <option value={0}>Americano (".")</option>
              <option value={1}>Brasileiro (",")</option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            id="real-file"
            type="file"
            hidden="hidden"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <BackupRoundedIcon
            className="clickable upload-icon"
            fontSize="large"
            color="secondary"
            onClick={() => {
              document.getElementById('real-file').click();
            }}
          />
          <span
            className={
              !selectedFile && validateForm
                ? 'error'
                : '' + 'panel-options-texts'
            }
          >
            {selectedFile
              ? selectedFile.name
              : 'Selecione um arquivo de pagamentos...'}
          </span>
        </Grid>

        {reportData.report_source == 8 ? (
          <>
            <Grid item xs={12} md={6}>
              <input
                id="last-file"
                type="file"
                hidden="hidden"
                onChange={(e) => setLastFile(e.target.files[0])}
              />
              <BackupRoundedIcon
                className="clickable upload-icon"
                fontSize="large"
                color="secondary"
                onClick={() => {
                  document.getElementById('last-file').click();
                }}
              />
              <span>
                {' '}
                {lastFile
                  ? lastFile.name
                  : 'Selecione o arquivo predecessor...'}{' '}
              </span>
            </Grid>
          </>
        ) : null}

        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setReportData({
                    ...reportData,
                    show_exchange_rate: !checked,
                  });
                  setChecked(!checked);
                }}
              />
            }
            label={
              <Typography variant="body1" style={{ fontSize: '.8rem' }}>
                Esconder taxas de câmbio
              </Typography>
            }
          />
        </Grid>
        <Grid xs={12}></Grid>
        <Grid item xs={12} md={3}>
          <div className="action-button-container">
            {processing ? (
              <div className="loading-container">
                <Loading />
              </div>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={uploadForm}
                size="large"
                fullWidth
              >
                {' '}
                Enviar{' '}
              </Button>
            )}
          </div>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    </>
  );
}

export function DeletionConfirmPanel({ report, onConfirm }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const doDeleteFile = () => {
    setIsProcessing(true);
    ORBApi.deletePaymentReports(report.id)
      .then(() => {
        onConfirm(true);
      })
      .catch(() => {
        onConfirm(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <>
      <h5>Deseja realmente remover este arquivo?</h5>
      <div className="deletion-subtitle">
        ({report.month} {report.source})
      </div>
      {isProcessing ? (
        <div className="loading-container">
          <Loading />{' '}
        </div>
      ) : (
        <div className="action-button-container">
          <Button variant="contained" color="secondary" onClick={doDeleteFile}>
            Confirmar
          </Button>
        </div>
      )}
    </>
  );
}

export function UpdateStatusConfirmPanel({
  report_id,
  loadData,
  current_page,
  setStatusVisible,
}) {
  const [loading, setLoading] = useState(0);

  const updateStatus = () => {
    let body = { status: 4 };
    setLoading(1);
    ORBApi.updatePaymentDetails(report_id, body)
      .then(() => {
        toast.success('Status atualizado !');
        loadData(current_page);
      })
      .catch((error) => {
        toast.error('Erro ao atualizar status.');
      })
      .finally(() => {
        setLoading(0);
        setStatusVisible(false);
      });
  };

  return (
    <>
      <h5>Deseja atualizar o status do arquivo?</h5>
      <div className="action-button-container">
        {loading ? (
          <Loading size="24px" />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => updateStatus()}
          >
            Confirmar
          </Button>
        )}
      </div>
    </>
  );
}

export function RateEditPanel({ report, onSave }) {
  const [currencies, setCurrencies] = useState([]);
  const [currenciesToUpdate, setCurrenciesToUpdate] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    ORBApi.loadPaymentReportCurrencies(report).then(setCurrencies);
  }, [report]);

  const onTextChangeHandler = (value, currency) => {
    let original = currenciesToUpdate;
    original[currency] = parseFloat(value);
    setCurrenciesToUpdate(original);
  };

  const updateCurrencies = () => {
    setIsProcessing(true);
    if (Object.keys(currenciesToUpdate).length === 0) {
      toast.warn('Preencha um valor');
      setIsProcessing(false);
      return;
    }

    var total = Object.keys(currenciesToUpdate).length;
    var i = 0;
    for (var currency in currenciesToUpdate) {
      i++;

      if (
        currenciesToUpdate[currency] === undefined ||
        currenciesToUpdate[currency] === null ||
        currenciesToUpdate[currency] === '' ||
        isNaN(currenciesToUpdate[currency])
      ) {
        toast.warn('Preencha um valor');
        setIsProcessing(false);
        return;
      }

      ORBApi.updatePaymentReportCurrency(
        report,
        currency,
        currenciesToUpdate[currency],
      ).finally(() => {
        if (i === total) {
          setIsProcessing(false);
          onSave();
        }
      });
    }
  };

  return (
    <>
      <h6 className="title">Taxas de câmbio para edição</h6>
      <Grid container spacing={2} alignItems="center">
        {currencies.map((currency, key) => {
          return (
            <Grid item md={6} key={currency.currency}>
              <TextField
                onChange={(e) =>
                  onTextChangeHandler(e.target.value, currency.currency)
                }
                label={currency.currency}
                defaultValue={currency.exchange_rate}
                variant="outlined"
                type="number"
              />
            </Grid>
          );
        })}
        {isProcessing ? (
          <Loading size="25px" />
        ) : (
          <Grid item md={4} key="btn">
            <Button
              onClick={updateCurrencies}
              variant="contained"
              color="secondary"
              size="large"
            >
              {' '}
              Salvar{' '}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export function NotFoundTracksPanel({ report, onDownload }) {
  const [tracksData, setTracksData] = useState({
    tracks: [],
    pages: 0,
    current_page: 0,
    total_items: 0,
  });
  const [downloading, setDownloading] = useState(false);
  const [rebuilding, setRebuilding] = useState(false);

  const downloadExcel = () => {
    setDownloading(true);
    ORBApi.downloadPaymentReportNotFoundTracks(report.id)
      .then((data) => {
        const file = new Blob([data], { type: `application/excel` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `ORB-not-found-tracks.xls`;
        link.click();
      })
      .then(() => {
        onDownload(true);
      })
      .catch(() => {
        onDownload(false);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const onPageChangeHandler = (page) => {
    loadReports(page);
  };

  const rebuildNotFoundTracks = () => {
    setRebuilding(true);
    ORBApi.rebuildPaymentReportNotFoundTracks(report.id)
      .then(() => {
        loadReports(1);
      })
      .finally(() => {
        setRebuilding(false);
      });
  };
  const loadReports = (page) => {
    ORBApi.loadPaymentReportNotFoundTracks(report.id, page).then((data) => {
      setTracksData(data);
    });
  };
  useEffect(() => {
    loadReports(1);
  }, []);

  return (
    <>
      <div className="update-logo">
        {rebuilding ? (
          <Loading size="25px" className="" />
        ) : (
          <Tooltip title="Atualiza lista de faixas não encontradas.">
            <UpdateRoundedIcon
              className="clickable"
              fontSize="large"
              color="secondary"
              onClick={rebuildNotFoundTracks}
            />
          </Tooltip>
        )}
      </div>
      <div className="excel-logo">
        {downloading ? (
          <Loading size="25px" className="download-loading" />
        ) : (
          <Tooltip title="Baixa a lista de faixas não encontradas em Excel.">
            <img
              src={excelLogo}
              className="clickable"
              onClick={downloadExcel}
            />
          </Tooltip>
        )}
      </div>
      <TableContainer className="base-table">
        <Table>
          <TableHead className="header">
            <TableRow>
              <TableCell>ISRC</TableCell>
              <TableCell>UPC</TableCell>
              <TableCell>Faixa</TableCell>
              <TableCell>Artista</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tracksData
              ? tracksData.tracks.map((track) => {
                  return (
                    <TableRow key={track.isrc}>
                      <TableCell>{track.isrc}</TableCell>
                      <TableCell>{track.upc}</TableCell>
                      <TableCell>{track.title}</TableCell>
                      <TableCell>{track.artist}</TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={tracksData.pages}
        page={tracksData.current_page}
        onChange={(e, value) => {
          onPageChangeHandler(value);
        }}
      />
    </>
  );
}


export function MatchParcialTracksPanel({ report, onDownload }) {
  const [matchesData, setMatchesData] = useState({
    matches: [],
    pages: 0,
    current_page: 0,
    total_items: 0,
  });
  const [downloading, setDownloading] = useState(false);
  const [rebuilding, setRebuilding] = useState(false);

  const downloadExcel = () => {
    setDownloading(true);
    ORBApi.downloadPaymentParcialMatchesTracks(report.id)
      .then((data) => {
        const file = new Blob([data], { type: `application/excel` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `ORB-parcial-matches-tracks.xls`;
        link.click();
      })
      .then(() => {
        onDownload(true);
      })
      .catch(() => {
        onDownload(false);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const onPageChangeHandler = (page) => {
    loadReports(page);
  };

  const rebuildParcialMatchesTracks = () => {
    setRebuilding(true);
    ORBApi.rebuildPaymentReportParcialMatchesTracks(report.id)
      .then(() => {
        loadReports(1);
      })
      .finally(() => {
        setRebuilding(false);
      });
  };
  const loadReports = (page) => {
    ORBApi.loadPaymentReportParcialMatchesTracks(report.id, page).then((data) => {
      setMatchesData(data);
    });
  };
  useEffect(() => {
    loadReports(1);
  }, []);



  return (
    <>
      <div className="update-logo">
        {rebuilding ? (
          <Loading size="25px" className="" />
        ) : (
          <Tooltip title="Atualiza lista de faixas com match parcial.">
            <UpdateRoundedIcon
              className="clickable"
              fontSize="large"
              color="secondary"
              onClick={rebuildParcialMatchesTracks}
            />
          </Tooltip>
        )}
      </div>
      <div className="excel-logo">
        {downloading ? (
          <Loading size="25px" className="download-loading" />
        ) : (
          <Tooltip title="Baixa a lista de faixas com match parcial em Excel.">
            <img
              src={excelLogo}
              className="clickable"
              onClick={downloadExcel}
            />
          </Tooltip>
        )}
      </div>
      <TableContainer className="base-table">
        <Table>
          <TableHead className="header">
            <TableRow>
              <TableCell>UPC</TableCell>
              <TableCell>ISRC</TableCell>
              <TableCell>Faixa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchesData.matches
              ? matchesData.matches.map((track) => {
                  return (
                    <TableRow key={track.isrc}>
                      <TableCell>{track.upc}</TableCell>
                      <TableCell>{track.isrc}</TableCell>
                      <TableCell>{track.title}</TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={matchesData.pages}
        page={matchesData.current_page}
        onChange={(e, value) => {
          onPageChangeHandler(value);
        }}
      />
    </>
  );
}

