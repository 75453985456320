import React, { useState, useContext, useEffect } from 'react';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';
import UpIcon from '../../../../icons/UpIcon';
import excelLogo from '../../../../assets/excel-logo.png';
import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import LabelsDropdown from '../../../../components/common/LabelsDropdown';
import SuggestContributors from '../../../../components/common/SuggestContributors';
import SessionContext, {
  SystemRoles,
} from '../../../../contexts/SessionContext';

export const defaultFilters = {
  label_id: '',
  search_term: '',
  contributor_id: '',
};

/** Action bar with all the action controls of the page */
export default function ActionBar(props) {
  // props: total_tracks, onFilterChange(filters), onAddClick, onUploadClick, onDownloadClick, input_filters, total_items

  const [showFilters, setShowFilters] = useState(false); //shows/hide filter options
  const [filters, setFilters] = useState(defaultFilters);
  let sessionContext = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onLabelChangeHandler = (label_id) => {
    updateFilters({ ...filters, label_id: label_id });
  };
  const onTermChangeHandler = (search_term) => {
    updateFilters({ ...filters, search_term: search_term });
  };

  const onContributorChangeHandler = (contributor_id) => {
    updateFilters({ ...filters, contributor_id: contributor_id });
  };

  const showHideFilters = (show) => {
    setShowFilters(show);
    if (!show) {
      // When there is no value in the filter properties, it is not necessary to clear the values
      if (!filters.label_id && !filters.searchTerm && !filters.contributor_id)
        return;
      // reset the filters when closing filters panel
      setFilters(defaultFilters);
      props.onFilterChange({
        label_id: null,
        search_term: null,
        contributor_id: null,
      });
    }
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {sessionContext.hasRole(['TRACKS_WRITE']) ? ( // Only admins can change the tracks catalog
          <>
            <div
              className="page-actionbar-item action-btn"
              onClick={() => {
                props.onAddClick();
              }}
            >
              <PlusIcon />
            </div>
            <div
              className="page-actionbar-item action-btn"
              onClick={() => {
                props.onUploadClick();
              }}
            >
              <UpIcon />
            </div>
          </>
        ) : null}
        {sessionContext.hasRole(['TRACKS_WRITE', 'TRACKS_CLIENT_VIEW']) ? (
          <div
            className="page-actionbar-item"
            style={{ position: 'absolute', right: '1em' }}
          >
            <h5>
              {props.total_tracks}: {props.total_items}
            </h5>
            <div
              className="action-btn"
              onClick={() => {
                props.onDownloadClick();
              }}
            >
              {' '}
              <img
                src={excelLogo}
                style={{ height: '25px', margin:"0 20px 0 10px" }}
                alt="Download de fonogramas"
              />
            </div>
          </div>
        ) : null}
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          {sessionContext.hasRole(['TRACKS_FULL_VIEW']) ? (
            <LabelsDropdown
              label={props.input_filters.label}
              className="filter-labels"
              onChange={(label) => {
                onLabelChangeHandler(label.id);
              }}
            />
          ) : null}

          {sessionContext.hasRole([
            'TRACKS_FULL_VIEW',
            'TRACKS_CLIENT_VIEW',
          ]) ? (
            <SuggestContributors
              useToFilter={true}
              onChange={(contributor) => {
                onContributorChangeHandler(contributor.id);
              }}
            />
          ) : null}

          {sessionContext.hasRole([
            'TRACKS_FULL_VIEW',
            'TRACKS_CLIENT_VIEW',
          ]) ? (
            <DebouncedTextField
              label={props.input_filters.generic}
              onChange={(term) => {
                onTermChangeHandler(term);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}
