import React, { useContext, useState } from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Modal,
  Backdrop,
  Button
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from '@material-ui/lab/Pagination';
import SessionContext from '../../../../contexts/SessionContext';
import Loading from '../../../../components/common/Loading';

const WorksTable = (props) => {

  let { hasRole } = useContext(SessionContext);

  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              <tableCell></tableCell>
              {props.headers_table.map((title, index) => (
                <TableCell key={index} align="center">
                  {title}
                </TableCell>
              ))}
              {hasRole(['WORKS_FULL_VIEW', 'WORKS_WRITE']) &&  <TableCell slign="center"></TableCell> }
            </TableRow>
          </TableHead>

          <TableBody>
            {props.works?.length > 0
              ? props.works.map((work, index) => {
                  return (
                    <WorkRow
                      index={index}
                      split_headers={props.split_headers}
                      clickable={props.clickable}
                      onRowClick={props.showWorkForm}
                      work={work}
                      translateCategory={props.translateCategory}
                      deleteWork={props.deleteWork}
                      hasRole={hasRole}
                      loading={props.deletingWork}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.currentPage}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default WorksTable;

function WorkRow(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [showConfirmationPanel, setShowConfirmationPanel] = useState(false)
  return (
    <>
      <TableRow className="row">
        <TableCell key={props.index} className="cell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        {props.hasRole(['WORKS_FULL_VIEW', 'WORKS_WRITE']) ? (
          <>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onRowClick(props.work)}
            >
              {props.work.orb_code}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onRowClick(props.work)}
            >
              {props.work.title}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onRowClick(props.work)}
            >
              {props.work.orb_control}
            </TableCell>
            <TableCell
              className="cell"
              align="center"
            >
              <DeleteIcon 
                className="clickable deleteIcon"
                fontSize="small"
                onClick={() => {
                  setShowConfirmationPanel(true)
                }}
                />
            
            </TableCell>
          </>
        ) : props.hasRole(['WORKS_READ', 'WORKS_CLIENT_VIEW']) ? (
          <>
            <TableCell align="center" className="cell">
              {props.work.orb_code}{' '}
            </TableCell>
            <TableCell align="center" className="cell">
              {props.work.title}{' '}
            </TableCell>
            <TableCell align="center" className="cell ">
              {props.work.orb_control}
            </TableCell>
          </>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse timeout="auto" in={!collapsed} unmountOnExit>
            <Typography variant="h6" gutterBottom component="div">
              {' '}
              Split{' '}
            </Typography>
            <Box margin={1}>
              <Table
                classname="split-table"
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    {props.split_headers.map((title, key) => (
                      <TableCell align="center" key={key}>
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.work.contributions?.map((contrib) => {
                    return (
                      <TableRow key={contrib.id}>
                        <TableCell className="cell" align="center">
                          {contrib.contributor.data.name}

                          {contrib.contributor.data.artist_name
                            ? ` (${contrib.contributor.data.artist_name})`
                            : null}
                        </TableCell>
                        <TableCell className="cell" align="center">
                          {contrib.contributor.share}
                        </TableCell>
                        <TableCell className="cell" align="center">
                          {contrib.contributor.orb_fee}
                        </TableCell>

                        {contrib.publisher !== undefined ? (
                          <>
                            <TableCell className="cell" align="center">
                              {contrib.publisher.data.name}
                            </TableCell>

                            <TableCell className="cell" align="center">
                              {contrib.publisher.share}
                            </TableCell>
                            <TableCell className="cell" align="center">
                              {contrib.publisher.orb_fee}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell className="cell" align="center">
                              {''}
                            </TableCell>
                            <TableCell className="cell" align="center">
                              {''}
                            </TableCell>
                            <TableCell className="cell" align="center">
                              {''}
                            </TableCell>
                          </>
                        )}

                        <TableCell className="cell" align="center">
                          {contrib.is_orb_managed ? (
                            <CheckIcon color="success" />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={showConfirmationPanel}
        onClose={() => 
          setShowConfirmationPanel(false)
        }
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container confirmation-panel">
          <Typography >Tem certeza que deseja deletar a obra {props.work.title}</Typography>

          {props.loading ? <Loading text="Deletando faixa..." /> : 
            (<div className="buttons-container"> 
              <Button 
              variant="contained" 
              color="secondary" 
              onClick={() => { 
                props.deleteWork(props.work.id)
                }}>
                  Deletar
                </Button>
              <Button 
              variant="contained" 
              color="default" 
              onClick={() => { 
                setShowConfirmationPanel(false)
                }}>
                  Cancelar
                </Button>
            </div>
          )}
    
        </div>
      </Modal>
    </>
  );
}
