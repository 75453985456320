import React, { useContext } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import SessionContext from '../../../../contexts/SessionContext';

const PublishersTable = (props) => {
  let { hasRole } = useContext(SessionContext);
  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {[
                'Nome',
                'Documento',
                '% Orb sob a editora',
                '% Orb sob o autor',
              ].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.publishers?.length > 0
              ? props.publishers.map((publisher) => {
                  return (
                    <TableRow key={publisher.id} className="row">
                      {hasRole(['PUBLISHERS_WRITE']) ? (
                        <>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showPublisherForm(publisher)}
                          >
                            {publisher.name}
                          </TableCell>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showPublisherForm(publisher)}
                          >
                            {publisher.document === 'None'
                              ? ''
                              : publisher.document}
                          </TableCell>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showPublisherForm(publisher)}
                          >
                            {publisher.orb_percent_fee_on_publisher}
                          </TableCell>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showPublisherForm(publisher)}
                          >
                            {publisher.orb_percent_fee_on_author}
                          </TableCell>
                        </>
                      ) : hasRole(['PUBLISHERS_READ']) ? (
                        <>
                          <TableCell align="center" className="">
                            {publisher.date}{' '}
                          </TableCell>
                          <TableCell align="center" className="">
                            {publisher.name}{' '}
                          </TableCell>
                          <TableCell align="center" className="">
                            {}{' '}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default PublishersTable;
