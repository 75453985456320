import React, { useEffect, useState } from 'react';
import ORBApi from '../../services/ORBApi';
import { Select, InputLabel, FormControl } from '@material-ui/core';

export default function PublishersDropDown({
  value,
  onChange,
  className,
  hideEmpty,
  label,
  margin,
  onLoaded,
}) {
  const [publishers, setPublishers] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    //Load the publishers when the component mount
    ORBApi.loadPublishers(1, null, 0).then((response) => {
      setPublishers(response.publishers);
      if (onLoaded && response.publishers.length > 0)
        onLoaded(response.publishers[0]);
    });
  }, []);

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    onChange({
      id: e.target.value,
      contributor_id:
        e.target.selectedOptions[0].getAttribute('contributor_id'),
      name: e.target.selectedOptions[0].text,
    });
  };
  return (
    <FormControl
      fullWidth
      margin={margin}
      variant="outlined"
      className={className}
    >
      <InputLabel shrink={selected > 0}>{label}</InputLabel>
      <Select
        native
        label="Editora"
        onChange={onChangeHandler}
        value={selected}
        notched={selected > 0}
      >
        {!hideEmpty ? <option value="" /> : null}
        {publishers.map((pub) => {
          return (
            <option
              key={pub.id}
              value={pub.id}
              contributor_id={pub.contributor_id}
            >
              {pub.name}
            </option>
          );
        })}
        ;
      </Select>
    </FormControl>
  );
}
