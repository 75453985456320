import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';
import moment from 'moment';

const SellingMonths = ({ selling_months, title }) => {
  const data = {
    labels: selling_months.map((month) => month.selling_month),
    datasets: [
      {
        data: selling_months.map((month) => month.share),
        backgroundColor: '#d0315e',
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true, // Define o início do eixo x como zero
            callback: function (value, index, values) {
              return value + '%';
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Define o início do eixo y como zero
            callback: function (value, index, values) {
              const [year, month] = value.split('-');
              return moment(`${year}-${month}-01`).format('MM/YYYY');
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          const value = data.labels[tooltipItem[0].index];
          const [year, month] = value.split('-');
          return moment(`${year}-${month}-01`).format('MM/YYYY');
        },
        label: function (tooltipItem, data) {
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value + '%';
        },
      },
    },
  };

  return (
    <>
      <h5 className="title" align="left">
        {title}
      </h5>
      <Box m={2} />
      <HorizontalBar data={data} options={options} />
    </>
  );
};

export default SellingMonths;
