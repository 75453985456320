import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import Header from './components/Header';
import SimpleTable from './components/SimpleTable';
import './style.css';
import ORBApi from '../../services/ORBApi';
import CircularProgress from './components/graphs/CircularProgress';
import SellingMonths from './components/graphs/SellingMonths';
import TopPlatforms from './components/graphs/TopPlatforms';
import qs from 'qs';
import Loading from '../../components/common/Loading';
import TabNavigation from './components/TabNavigation';
import SessionContext from '../../contexts/SessionContext';
import { getTranslatedTexts } from '../../utils/TranslationUtils';

export default function ReportGraph({ location }) {
  const [distribution, setDistribution] = useState(null);
  const [trackExecutions, setTrackExecutions] = useState({
    pages: 0,
    current_page: 1,
    total_items: 0,
    executions: [],
  });
  const [workExecutions, setWorkExecutions] = useState({
    pages: 0,
    current_page: 1,
    total_items: 0,
    executions: [],
  });
  const [queries, setQueries] = useState(
    qs.parse(location.search, { ignoreQueryPrefix: true }),
  );
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);
  const history = useHistory();

  function loadWorkExecutions(page) {
    let newQueries = queries;

    if (hasRole(['REPORTS_ARTIST_VIEW'])) {
      newQueries.contributor_id = null;
    } else if (hasRole(['REPORTS_CLIENT_VIEW'])) {
      newQueries.client_id = null;
    }
    ORBApi.getWorkExecutions(
      newQueries.distribution_id,
      newQueries.client_id,
      newQueries.contributor_id,
      page,
    )
      .then((response) => {
        setWorkExecutions(response);
      })
      .catch((err) => {
        console.log('Failed loading work executions.');
      });
  }
  function loadTrackExecutions(page) {
    let newQueries = queries;

    if (hasRole(['REPORTS_ARTIST_VIEW'])) {
      newQueries.contributor_id = null;
    } else if (hasRole(['REPORTS_CLIENT_VIEW'])) {
      newQueries.client_id = null;
    }

    ORBApi.getTrackExecutions(
      newQueries.distribution_id,
      newQueries.client_id,
      newQueries.contributor_id,
      page,
    )
      .then((response) => {
        setTrackExecutions(response);
      })
      .catch((err) => {
        console.log('Failed loading track executions.');
      });
  }

  useEffect(() => {
    let newQueries = queries;
    if (hasRole(['REPORTS_ARTIST_VIEW'])) {
      newQueries.contributor_id = null;
    } else if (hasRole(['REPORTS_CLIENT_VIEW'])) {
      newQueries.client_id = null;
    }

    ORBApi.getDistribution(newQueries)
      .then((data) => {
        setDistribution(data);
        // Only load the executions when the user has data to be seen
        if (data.track_report) loadTrackExecutions(1);
        if (data.work_report) loadWorkExecutions(1);
      })
      .catch((err) => {
        console.log('Failed Loading distribution data.');
      });
  }, []);

  useEffect(() => {
    const translatedTexts = getTranslatedTexts(
      'reports_graph',
      language_preference,
    );
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <div className="container">
      {distribution !== null ? (
        <Paper
          style={{ height: '100%', padding: '0 20px 0 20px' }}
          elevation={3}
        >
          <Grid sm={12}>
            <Header
              header_texts={texts ? texts.header : {}}
              summary={distribution.main_info}
            />
          </Grid>
          <Grid container spacing={5}>
            <Grid item sm={12}>
              <h5 className="title" align="left">
                DISTRIBUIÇÃO POR TIPO DE RECEITA
              </h5>
            </Grid>
            <Grid
              container
              sm={12}
              md={
                distribution.track_report == undefined ||
                distribution.work_report == undefined
                  ? 6
                  : 12
              }
              item
              spacing={5}
            >
              {distribution.track_report !== undefined ? (
                <Grid
                  item
                  sm={12}
                  md={distribution.work_report == undefined ? 12 : 6}
                >
                  <h5 className="title">{texts.header.title_conexo}</h5>
                  <CircularProgress
                    totalValue={distribution.main_info.client_revenue}
                    percentageValue={distribution.track_report.client_revenue}
                    currency={distribution.main_info.distribution_currency}
                  />
                </Grid>
              ) : null}

              {distribution.work_report !== undefined ? (
                <Grid
                  item
                  sm={12}
                  md={distribution.track_report == undefined ? 12 : 6}
                >
                  <h5 className="title">{texts.header.title_autoral}</h5>
                  <CircularProgress
                    totalValue={distribution.main_info.client_revenue}
                    percentageValue={distribution.work_report.client_revenue}
                    currency={distribution.main_info.distribution_currency}
                  />
                </Grid>
              ) : null}
            </Grid>

            {distribution.track_report !== undefined ? (
              <Grid item sm={12} md={6}>
                <h5 className="title" align="left">
                  {texts ? texts.top_tracks : ''}
                </h5>
                <Box m={2} />
                <SimpleTable
                  headers={texts ? texts.top_tracks_headers : []}
                  data={distribution.track_report.top_tracks}
                  currency={distribution.main_info.distribution_currency}
                />
              </Grid>
            ) : null}

            {distribution.work_report !== undefined ? (
              <Grid item sm={12} md={6}>
                <h5 className="title" align="left">
                  {texts ? texts.top_works : ''}
                </h5>
                <Box m={2} />
                <SimpleTable
                  headers={texts ? texts.top_works_headers : []}
                  data={distribution.work_report.top_works}
                  currency={distribution.main_info.distribution_currency}
                />
              </Grid>
            ) : null}

            <Grid item sm={12} md={6}>
              <TopPlatforms
                title={texts ? texts.platforms : ''}
                top_platforms={distribution.top_platforms}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SellingMonths
                title={texts ? texts.selling_months : ''}
                selling_months={distribution.selling_months}
              />
            </Grid>

            <Box m={4} />

            <Grid item xs={12}>
              <div className="horizontal-divider"></div>
              <TabNavigation
                currency={distribution.main_info.distribution_currency}
                texts={texts ? texts : {}}
                track={trackExecutions}
                work={workExecutions}
                loadTrackExecutions={loadTrackExecutions}
                loadWorkExecutions={loadWorkExecutions}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                ORBMUSIC.COM.BR • RESHAPING THE MUSIC INDUSTRY
              </Typography>
            </Grid>
            <Box m={2} />
          </Grid>
        </Paper>
      ) : (
        <>
          <div className="centralized-loading">
            <Loading text="Carregando Relatórios" />
          </div>
        </>
      )}
    </div>
  );
}
