import React, { useState, useContext } from 'react';
import '../../../../utils/date';
import FilterIcon from '../../../../icons/FilterIcon';
import PlusIcon from '../../../../icons/PlusIcon';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import DebouncedTextField from '../../../../components/common/DebouncedTextField';
import AgentsForm from './AgentsForm';
import SessionContext from '../../../../contexts/SessionContext';

export const defaultFilters = {
  agent: '',
};

const ActionBar = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  let { hasRole } = useContext(SessionContext);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const showHideFilters = (show) => {
    setShowFilters(show);
    if (!show) {
      if (filters.agent)
        updateFilters({
          agent: null,
        });
    }
  };

  const onAgentChangeHandler = (agent) => {
    updateFilters({ ...filters, agent: agent });
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>

        {hasRole(['AGENTS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              props.setFormVisible(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}

        <div className="page-actionbar-item" style={{ marginLeft: 'auto' }}>
          <h5>Total de Agentes: {props.total_items}</h5>
        </div>
        <div>
          <Modal
            size="lg"
            open={props.formVisible}
            onClose={() => props.setFormVisible(!props.formVisible)}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeogut: 500 }}
          >
            <div className="modal-container">
              <AgentsForm
                setFormVisible={props.setFormVisible}
                onSaveAgent={props.onSaveAgent}
              />
            </div>
          </Modal>
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <DebouncedTextField
            label="Agente"
            onChange={(agent) => {
              onAgentChangeHandler(agent);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
