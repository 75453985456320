import React, { useContext } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SessionContext from '../../../../contexts/SessionContext';

const ClientsTable = (props) => {
  let { hasRole } = useContext(SessionContext);
  return (
    <>
      <TableContainer className="table-container" component={Paper}>
        <Table
          size="small"
          aria-label="collapsible table"
          className="basic-table"
        >
          <TableHead className="basic-table-head">
            <TableRow>
              {['Nome', 'Email'].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.clients.length > 0
              ? props.clients.map((client) => {
                  return (
                    <TableRow className="row" key={client.id}>
                      {hasRole(['CLIENTS_WRITE']) ? (
                        <>
                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showClientForm(client)}
                          >
                            {client.name}
                          </TableCell>

                          <TableCell
                            className=" clickable"
                            align="center"
                            onClick={() => props.showClientForm(client)}
                          >
                            {client.email}{' '}
                          </TableCell>
                        </>
                      ) : hasRole(['CLIENTS_READ']) ? (
                        <>
                          <TableCell align="center">{client.name} </TableCell>
                          <TableCell align="center">{client.email} </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default ClientsTable;
