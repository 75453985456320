import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import {
  Button,
  TextField,
  Grid,
  Tooltip,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DateMonthPicker from '../../../../components/common/DateMonthPicker';
import ORBApi from '../../../../services/ORBApi';
import Loading from '../../../../components/common/Loading';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import excelLogo from '../../../../assets/excel-logo.png';
import '../../../../utils/date';

export function AddFilePanelBackoffice(props) {
  /** {onSave} */

  const [selectedFile, setSelectedFile] = useState(null); // File to be uploded
  const [shouldValidateForm, setShouldValidateForm] = useState(false); // when true shows the error on fields
  const [processing, setProcessing] = useState(false); // used to show loading
  const [checked, setChecked] = useState(false);
  const [reportData, setReportData] = useState({
    month: '',
    name: '',
    currency: '',
    report_source: '',
    comma_as_decimal_sep: '',
    show_exchange_rate: false,
  }); // data on the form

  const validateForm = () => {
    setShouldValidateForm(true);
    if (
      selectedFile === '' ||
      reportData.month === '' ||
      reportData.name === '' ||
      reportData.currency === '' ||
      reportData.report_source === '' ||
      reportData.comma_as_decimal_sep === ''
    )
      return false;

    return true;
  };

  const uploadForm = () => {
    if (!validateForm()) return;
    setProcessing(true);
    // Mount the form data object
    let form = new FormData();
    form.append('report_file', selectedFile);
    form.append('month', moment(reportData.month).format('YYYY-MM'));
    form.append('name', reportData.name);
    form.append('report_source', reportData.report_source);
    form.append('currency', reportData.currency);
    form.append('comma_as_decimal_sep', reportData.comma_as_decimal_sep);
    form.append(
      'show_exchange_rate',
      reportData.show_exchange_rate === true ? 0 : 1,
    );

    ORBApi.postWorkPaymentReports(form)
      .then(() => {
        props.onSave(true);
      })
      .catch(() => {
        props.onSave(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      <h4 className="title"> Carregar arquivo autoral</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} xs={12}>
          <TextField
            style={{ width: '100%' }}
            variant="outlined"
            required
            label="Nome"
            error={shouldValidateForm && !reportData.name ? true : null}
            onChange={(e) => {
              setReportData({ ...reportData, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <DateMonthPicker
            label="Mês Referência"
            error={shouldValidateForm && !reportData.month}
            format="MM/yyyy"
            view={['month', 'year']}
            required
            initialDate={new Date()}
            onChange={(month) => {
              setReportData({ ...reportData, month: month });
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            style={{ width: '100%' }}
            className="form-select"
            required
            error={shouldValidateForm && !reportData.currency ? true : null}
          >
            <InputLabel className="select-label">Moeda</InputLabel>
            <Select
              native
              onChange={(e) =>
                setReportData({ ...reportData, currency: e.target.value })
              }
              variant="outlined"
            >
              <option value="" />
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="BRL">BRL</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            style={{ width: '100%' }}
            required
            error={
              shouldValidateForm && !reportData.report_source ? true : null
            }
          >
            <InputLabel className="select-label"> Fonte </InputLabel>
            <Select
              native
              onChange={(e) => {
                setReportData({ ...reportData, report_source: e.target.value });
              }}
              variant="outlined"
            >
              <option aria-label="None" value="" />
              <option value={1}>Youtube</option>
              <option value={2}>Backoffice</option>
              <option value={3}>Deezer BMAT</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl
            style={{ width: '100%' }}
            required
            error={
              shouldValidateForm && !reportData.comma_as_decimal_sep
                ? true
                : null
            }
          >
            <InputLabel className="select-label"> Formato </InputLabel>
            <Select
              native
              onChange={(e) => {
                setReportData({
                  ...reportData,
                  comma_as_decimal_sep: e.target.value,
                });
              }}
              variant="outlined"
            >
              <option aria-label="None" value="" />
              <option value={0}>Americano (".")</option>
              <option value={1}>Brasileiro (",")</option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={4} xs={12}>
          <div className="form-item-container" style={{ width: '100%' }}>
            <input
              id="real-file"
              type="file"
              hidden="hidden"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />

            <CloudUploadIcon
              className="clickable file-btn"
              color="secondary"
              onClick={() => {
                document.getElementById('real-file').click();
              }}
            />
            <span
              className={
                !selectedFile && shouldValidateForm
                  ? 'error'
                  : '' + 'panel-options-texts'
              }
            >
              {selectedFile ? selectedFile.name : 'Selecionar arquivo...'}
            </span>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setReportData({
                    ...reportData,
                    show_exchange_rate: !checked,
                  });
                  setChecked(!checked);
                }}
              />
            }
            label={
              <Typography variant="body1" style={{ fontSize: '.750rem' }}>
                Esconder taxas de câmbio
              </Typography>
            }
          />
        </Grid>
        <Grid xs={12}></Grid>
        <Grid item xs={12} md={3}>
          <div className="form-item-container">
            {processing ? (
              <Loading />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={uploadForm}
                size="large"
                fullWidth
              >
                Enviar
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export function DeletionConfirmPanel({ report, onConfirm }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const doDeleteFile = () => {
    setIsProcessing(true);
    ORBApi.deletePaymentWorkReports(report.id)
      .then(() => {
        onConfirm(true);
      })
      .catch(() => {
        onConfirm(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <>
      <h5>Deseja realmente remover este arquivo?</h5>
      <div className="deletion-subtitle">
        ({report.month} {report.source})
      </div>
      {isProcessing ? (
        <div className="loading-container">
          <Loading />{' '}
        </div>
      ) : (
        <div className="action-button-container">
          <Button variant="contained" color="secondary" onClick={doDeleteFile}>
            Confirmar
          </Button>
        </div>
      )}
    </>
  );
}

export function UpdateStatusConfirmPanel({
  report_id,
  loadData,
  current_page,
  setStatusVisible,
}) {
  const [loading, setLoading] = useState(0);

  const updateStatus = () => {
    let body = { status: 4 };
    setLoading(1);
    ORBApi.updateWorkPaymentDetails(report_id, body)
      .then(() => {
        toast.success('Status atualizado !');
        loadData(current_page);
      })
      .catch((error) => {
        toast.error('Erro ao atualizar status.');
      })
      .finally(() => {
        setLoading(0);
        setStatusVisible(false);
      });
  };

  return (
    <>
      <h5>Deseja atualizar o status do arquivo?</h5>
      <div className="action-button-container">
        {loading ? (
          <Loading size="24px" />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => updateStatus()}
          >
            Confirmar
          </Button>
        )}
      </div>
    </>
  );
}

export function NotFoundWorksPanel(props) {
  /** {report, onDownload} */

  const [worksData, setWorksData] = useState({
    works: [],
    pages: 0,
    current_page: 0,
    total_items: 0,
  });
  const [downloading, setDownloading] = useState(false);
  const [rebuilding, setRebuilding] = useState(false);

  const downloadExcel = () => {
    setDownloading(true);
    ORBApi.downloadWorkPaymentReportNotFoundWorks(props.report.id)
      .then((data) => {
        const file = new Blob([data], { type: `application/excel` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `ORB-not-found-works.xls`;
        link.click();
      })
      .then(() => {
        props.onDownload(true);
      })
      .catch(() => {
        props.onDownload(false);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const onPageChangeHandler = (page) => {
    loadReports(page);
  };

  const rebuildNotFoundWorks = () => {
    setRebuilding(true);
    ORBApi.rebuildWorkPaymentReportNotFoundWorks(props.report.id)
      .then(() => {
        loadReports(1);
      })
      .finally(() => {
        setRebuilding(false);
      });
  };
  const loadReports = (page) => {
    ORBApi.loadWorkPaymentReportNotFoundWorks(props.report.id, page).then(
      (data) => {
        setWorksData(data);
      },
    );
  };
  useEffect(() => {
    loadReports(1);
  }, []);

  return (
    <>
      <div className="update-logo">
        {rebuilding ? (
          <Loading size="25px" className="" />
        ) : (
          <Tooltip title="Atualiza lista de obras não encontradas.">
            <UpdateRoundedIcon
              className="clickable"
              fontSize="large"
              color="secondary"
              onClick={rebuildNotFoundWorks}
            />
          </Tooltip>
        )}
      </div>
      <div className="excel-logo">
        {downloading ? (
          <Loading size="25px" className="download-loading" />
        ) : (
          <Tooltip title="Baixa a lista de obras não encontradas em Excel.">
            <img
              src={excelLogo}
              className="clickable"
              onClick={downloadExcel}
            />
          </Tooltip>
        )}
      </div>
      <TableContainer className="base-table">
        <Table>
          <TableHead className="header">
            <TableRow>
              <TableCell>Código ORB</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Autor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {worksData
              ? worksData.works.map((work) => {
                  return (
                    <TableRow key={work.orb_code}>
                      <TableCell>{work.orb_code}</TableCell>
                      <TableCell>{work.title}</TableCell>
                      <TableCell>{work.author}</TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={worksData.pages}
        page={worksData.current_page}
        onChange={(e, value) => {
          onPageChangeHandler(value);
        }}
      />
    </>
  );
}
