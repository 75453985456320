import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import CancelIcon from '@material-ui/icons/Cancel';
import Pagination from '@material-ui/lab/Pagination';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import UpdateIcon from '@material-ui/icons/Update';
import moment from 'moment';
import ORBApi from '../../../../services/ORBApi';

export default function PaymentFilesTable(props) {
  // {tableData, onWorksAlertClick}

  const [loading, setLoading] = useState(0);

  const clickHandler = (report_id, showExchangeRate) => {
    props.setLoading(1);
    let body = { show_exchange_rate: showExchangeRate };
    ORBApi.updateWorkPaymentDetails(report_id, body)
      .then(() => {
        toast.success('Arquivo de pagamento atualizado');
        props.loadData(props.tableData.current_page);
      })
      .catch((error) => {
        toast.error('Erro ao atualizar arquivo de pagamento');
      })
      .finally(() => {
        props.setLoading(0);
      });
  };

  const buildRateTag = (report) => {
    return (
      <>
        {report.status === 'Loaded' &&
        props.hasRole(['PAYMENT_FILE_WORKS_WRITE']) ? (
          <MonetizationOnIcon
            color="secondary"
            className="clickable"
            onClick={() => {
              props.onRateClick(report);
            }}
          />
        ) : null}
        {report.rates.map((rate) => {
          if (rate !== 'BRL') {
            return (
              <div key={rate.currency}>
                {rate.currency}={rate.rate}
              </div>
            );
          }
        })}
      </>
    );
  };
  return (
    <>
      <TableContainer className="table-container">
        <Table
          style={{ borderRadius: '3%' }}
          size="small"
          className="basic-table "
        >
          <TableHead className="basic-table-head">
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Fonte</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Câmbio</TableCell>
              <TableCell align="center">Mostrar taxas de câmbio</TableCell>
              <TableCell align="center">Remover</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData &&
            props.hasRole([
              'PAYMENT_FILE_WORKS_WRITE',
              'PAYMENT_FILE_WORKS_READ',
            ])
              ? props.tableData.work_payment_files.map((report, index) => {
                  return (
                    <TableRow className="row" key={report.id}>
                      <TableCell style={{ width: '10%' }}>
                        {moment(report.month).format('MM/YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '40%' }}>
                        {report.name}
                        {report.has_not_found_works ? (
                          <div
                            className="alert-notfound-works clickable"
                            onClick={() => {
                              props.onWorksAlertClick(report);
                            }}
                          >
                            <Tooltip title="Possui obras não cadastradas">
                              <ErrorRoundedIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </Tooltip>
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>
                        {report.source}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>
                        {report.status}
                      </TableCell>
                      <TableCell style={{ width: '5%', textAlign: 'center' }}>
                        {buildRateTag(report)}
                      </TableCell>
                      <TableCell style={{ width: '20%', textAlign: 'center' }}>
                        {report.show_exchange_rate ? (
                          <Checkbox
                            checked={true}
                            onChange={(e) => {
                              clickHandler(
                                report.id,
                                !report.show_exchange_rate,
                              );
                            }}
                          />
                        ) : (
                          <Checkbox
                            checked={false}
                            onChange={(e) => {
                              clickHandler(
                                report.id,
                                !report.show_exchange_rate,
                              );
                            }}
                          />
                        )}
                      </TableCell>
                      {props.hasRole(['PAYMENT_FILE_WORKS_WRITE']) ? (
                        <>
                          <TableCell
                            className="clickable"
                            style={{ width: '10%', textAlign: 'center' }}
                            onClick={() => {
                              props.onDeleteClick(report);
                            }}
                          >
                            {report.status !== 'Distributed' ? (
                              <CancelIcon color="secondary" />
                            ) : null}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      {report.status === 'Loaded' ? (
                        <TableCell>
                          <span
                            onClick={() => props.onUpdateStatusClick(report)}
                            className="update-icon"
                          >
                            <UpdateIcon fontSize="small" />
                            Ready
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.tableData.pages}
        page={props.tableData.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
}
