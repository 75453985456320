import React, { useState, useEffect } from 'react';
import Loading from '../../../../components/common/Loading';
import { Button } from '@material-ui/core';
import ORBApi from '../../../../services/ORBApi';

const DeleteConfirmPanel = ({ id, onDelete, onLoad }) => {
  const [isLoading, setIsLoading] = useState(false);

  const doDeleteFile = (id) => {
    setIsLoading(true);
    ORBApi.deleteAgent(id)
      .then(() => {
        onLoad(1);
        onDelete(null, true);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(id);
  }, [id]);

  return (
    <>
      <h5 className="title">Deseja realmente remover este Agente?</h5>
      <div className="deletion-subtitle"></div>
      {isLoading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="action-button-container">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                doDeleteFile(id);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onDelete(null, true);
              }}
            >
              Cancelar
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DeleteConfirmPanel;
